import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import editIcon from '/src/assets/edit.svg';
import deleteIcon from '/src/assets/delete.svg';
import { InteractionData } from '../../services/types';
import InitiativeDataContext from '../../context/InitiativeDataContext';
import { generatePageNumbers } from '../../utils/helpers';

interface InteractionTableProps {
    data: InteractionData[];
    initiativeId: string | undefined;
    handleSelectRows: (selectedRows: number[]) => void;
}

function InitiativeInteractionTable({ data, initiativeId, handleSelectRows }: InteractionTableProps) {
    const { initiatives } = useContext(InitiativeDataContext);
    const [selectedRows, setSelectedRows] = useState<number[]>([]);
    const [interactionData, setInteractionData] = useState<InteractionData[]>([]);
    const [currentPage, setCurrentPage] = useState(1);

    const rowsPerPage = 10;
    let initiativeName = '';
    let initId: number | null = null;

    const navigate = useNavigate();

    useEffect(() => {
        if (Array.isArray(data)) {
            const filteredData = data.filter(item => item.initiative_id === Number(initiativeId));
            setInteractionData(filteredData);
        } else {
            console.error('interactionData is not an array:', data);
        }
    }, [data]);

    const handleCheckboxChange = (id: number) => {
        const index = selectedRows.indexOf(id);
        let updatedSelectedRows: number[];
        if (index === -1) {
            updatedSelectedRows = [...selectedRows, id];
        } else {
            updatedSelectedRows = selectedRows.filter(rowId => rowId !== id);
        }
        setSelectedRows(updatedSelectedRows);
    };
    
    useEffect(() => {
        handleSelectRows(selectedRows);
    }, [selectedRows]);

    const handleSelectAll = () => {
        const allIds = interactionData.map(row => row.id);
        setSelectedRows(allIds);
        handleSelectRows(allIds);
    };

    const handleDeselectAll = () => {
        setSelectedRows([]);
        handleSelectRows([]);
    };

    const handleEditClick = (id: number) => {
        navigate(`/commerce-interactions/edit/${id}`, { state: { initiativeName, initId } });
    };

    const handleDeleteClick = async (id: number) => {
        navigate(`/commerce-interactions/delete/${id}`, { state: {id, initId } })
    };

    const handlePageClick = (page: number) => {
        setCurrentPage(page);
    };

    // Pagination logic
    const totalPages = Math.ceil(interactionData.length / rowsPerPage);
    const pageNumbersToShow = generatePageNumbers(totalPages, currentPage);

    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = Math.min(startIndex + rowsPerPage, interactionData.length);
    const paginatedData = interactionData.slice(startIndex, endIndex);

    return (
        <div>
            <div className="overflow-x-auto border border-solid border-color-tableGray">
                <table className="min-w-full">
                    <thead className="bg-color-cai-lightGray text-color-cai-main">
                        <tr>
                            <th className="border-b border-color-tableGray text-xs font-bold px-4 py-2 text-left">
                                <input type="checkbox" onChange={selectedRows.length === data.length ? handleDeselectAll : handleSelectAll} />
                            </th>
                            <th className="border-b border-color-tableGray text-xs font-bold px-4 py-2 text-left">Business Name</th>
                            <th className="border-b border-color-tableGray text-xs font-bold px-4 py-2 text-left">Business UBI</th>
                            <th className="border-b border-color-tableGray text-xs font-bold px-4 py-2 text-left">Interaction Type</th>
                            <th className="border-b border-color-tableGray text-xs font-bold px-4 py-2 text-left">Assistance Type</th>
                            <th className="border-b border-color-tableGray text-xs font-bold px-4 py-2 text-left">Interaction Date</th>
                            <th className="border-b border-color-tableGray text-xs font-bold px-4 py-2 text-left"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedData.map((row) => {

                            initId = Number(row.initiative_id);
                            const initiative = initiatives.find(init => init.id === initId);
                            initiativeName = initiative ? initiative.name : 'N/A';

                            const date = new Date(row.interaction_date);
                            // Manually format the date as mm/dd/yyyy
                            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
                            const day = String(date.getDate() + 1).padStart(2, '0');
                            const year = date.getFullYear();

                            const formattedDate = `${month}/${day}/${year}`;

                            return (
                                <tr key={row.id} className="border-b border-solid border-color-tableGray last:border-none h-10">
                                    <td className="px-4 py-2 text-color-cai-gray text-sm text-left">
                                        <input type="checkbox" onChange={() => handleCheckboxChange(row.id)} checked={selectedRows.includes(row.id)} />
                                    </td>
                                    <td className="px-4 py-2 text-color-cai-gray text-sm text-left">
                                        {row.business_legal_name}
                                    </td>
                                    <td className="px-4 py-2 text-color-cai-gray text-sm text-left">
                                        {row.business_ubi}
                                    </td>
                                    <td className="px-4 py-2 text-color-cai-gray text-sm text-left">
                                        {row.interaction_type.display_name}
                                    </td>
                                    <td className="px-4 py-2 text-color-cai-gray text-sm text-left">
                                        {row.assistance_types.length > 0 && (
                                            row.assistance_types.map((type: any) => type.name).join(', ')
                                        )}
                                    </td>
                                    <td className="px-4 py-2 text-color-cai-gray text-sm text-left">
                                        {formattedDate}
                                    </td>
                                    <td className="flex w-24 px-4 py-2 text-color-cai-gray text-sm text-left items-center">
                                        <button onClick={(e) => { e.stopPropagation(); handleEditClick(row.id) }} className="mr-3 flex items-center">
                                            <img className="border border-solid border-tableGray h-6 w-6 p-1 rounded-md" src={editIcon} alt="Edit" />
                                        </button>
                                        <button onClick={(e) => { e.stopPropagation(); handleDeleteClick(row.id) }} className="flex items-center">
                                            <img className="border border-solid border-tableGray h-6 w-6 p-1 rounded-md" src={deleteIcon} alt="Delete" />
                                        </button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            {pageNumbersToShow.length > 1 && 
            <div className="flex justify-center mt-4">
                <div className="border border-solid border-tableGray rounded-md flex">
                    <button
                        className="text-color-cai-gray text-sm border-r border-solid border-tableGray px-2 py-1"
                        onClick={() => handlePageClick(Math.max(currentPage - 1, 1))}
                        disabled={currentPage === 1}
                    >
                        « Previous
                    </button>
                    {pageNumbersToShow.map((pageNumber, index) => (
                        <button
                            key={index}
                            className={`text-color-cai-gray text-sm border-r border-solid border-tableGray px-3 py-1 ${pageNumber === currentPage ? 'bg-gray-300' : ''}`}
                            onClick={() => handlePageClick(typeof pageNumber === 'number' ? pageNumber : currentPage)}
                        >
                            {pageNumber}
                        </button>
                    ))}
                    <button
                        className="text-color-cai-gray text-sm px-2 py-1"
                        onClick={() => handlePageClick(Math.min(currentPage + 1, totalPages))}
                        disabled={currentPage === totalPages}
                    >
                        Next »
                    </button>
                </div>
            </div>
            }
        </div>
    );
}

export default InitiativeInteractionTable;