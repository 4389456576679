import { useState, useEffect, ChangeEvent, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import leftArrow from '../../assets/left-arrow.svg';
import Expand from '../../components/Expand';
import { calculateFiscalYear } from '../../utils/helpers';
import { useIframeContext } from '../../context/IframeContext';
import Select from 'react-select';
import { customStyles } from '../../utils/customFormStyles';
import { getIndustryTypeOptions, getPartnerLocations, getSubIndustryOptions, getTypeOptions, monthOptions, serviceAreaOptions, statusOptions, transformLocationsToOptions, yearOptions } from '../../utils/formOptions';
import AdoDataContext from '../../context/AdoDataContext';
// import FormErrorModal from '../../components/FormErrorModal';
import TypesContext from '../../context/TypesContext';
import { useAdminContext } from '../../context/AdminContext';
import { IndustryType, LocationType, PartnerLocation } from '../../services/types';
import { useOrganizationContext } from '../../context/OrganizationContext';


function AddInitiative() {
    const { isIframe } = useIframeContext();
    const { isAdmin } = useAdminContext();
    const { partnerName } = useOrganizationContext();
    const { partnerData, adoNames } = useContext(AdoDataContext);
    const { typesData } = useContext(TypesContext);
    
    const [adoOptions, setAdoOptions] = useState<{ label: string; value: string }[]>([]);
    const [ado, setAdo] = useState<string>('');
    const [year, setYear] = useState<string>('');
    // QUARTER IS HARDCODED RIGHT NOW
    const quarter = 'Q4';
    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [isDefault, setIsDefault] = useState<boolean>(false);
    const [isAdminManaged, setIsAdminManaged] = useState<boolean>(false);
    const [initiativeStatus, setInitiativeStatus] = useState<string>('');
    const [initiativeType, setInitiativeType] = useState<string>('');
    const [industry, setIndustry] = useState<string>("");
    const [subIndustry, setSubIndustry] = useState<string>("");
    const [subIndustryOptions, setSubIndustryOptions] = useState<{ value: string; label: string; }[]>([]);
    const [serviceArea, setServiceArea] = useState<string>("");
    const [adoArea, setAdoArea] = useState<string[]>([]);
    const [adoAreaOptions, setAdoAreaOptions] = useState<{ value: string, label: string }[]>([]);
    const [pogInitiative, setPogInitiative] = useState<boolean>(false);
    const [pogBusinessName, setPogBusinessName] = useState<string>('');
    const [pogUbi, setPogUbi] = useState<string>('');
    const [pogMonths, setPogMonths] = useState<boolean>(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    // const [showModal, setShowModal] = useState(false);
    // const [formErrorMsg, setFormErrorMsg] = useState('');

    const navigate = useNavigate();
    const currFisYear = calculateFiscalYear(year);

    // Find the partner data matching the selected ADO name
    const partner = partnerData.find(partner => partner.name.trim().toLowerCase() === ado.trim().toLowerCase());
    console.log('partner:', partner)

    // Create select options for initiatives and industries
    const initiativeTypeOptions = getTypeOptions(typesData, 'initiative_types', initiativeType);
    const industryTypesData = getIndustryTypeOptions(typesData, 'industry_types', industry);
    // Add the "All Industries" option to the beginning of the array
    const industryTypeOptions = [
        { value: null, label: "All Industries" },
        ...industryTypesData
    ];

    // Helper function to find locations by names
    const getLocationsByNames = (names: string[], partnerLocations: PartnerLocation[]): LocationType[] => {
        const locations: LocationType[] = [];
        
        for (const partnerLocation of partnerLocations) {
            for (const location of partnerLocation.locations) {
                if (names.includes(location.name)) {
                    locations.push(location);
                }
            }
        }
        
        return locations;
    };

    // Set default year to current Fiscal Year and set ado options
    useEffect(() => {
        setAdoOptions(adoNames);
        setYear(currFisYear.toString());

        if (!isAdmin) {
            setAdo(partnerName);
        }
    }, []);

    useEffect(() => {
        if (industry) {
            const selectedIndustry = typesData.industry_types.find((type: IndustryType) => type.code === industry);
            const options = getSubIndustryOptions(selectedIndustry);
            setSubIndustryOptions(options);
        } else {
            setSubIndustryOptions([]);
        }
    }, [industry, typesData]);

    useEffect(() => {
        if (partner) {
            const partnerId = partner.id;
            const locations = getPartnerLocations(partnerId, typesData);
            const options = transformLocationsToOptions(locations);
            setAdoAreaOptions(options);
        } else {
            setAdoAreaOptions([]);
        }
    }, [partner, typesData]);


    // Handler for navigating back to Initiatives page
    const navigateBack = () => {
        navigate('/commerce-initiatives');
    };

    // Change handlers for form fields
    const handleInputChange = (setter: React.Dispatch<React.SetStateAction<string>>) => (event: ChangeEvent<HTMLInputElement>) => {
        setter(event.target.value);
    };

    // Toggle handler for the "Default Initiative" switch
    const handleDefaultInitiativeToggle = () => {
        setIsDefault(prevState => {
            const newDefault = !prevState;
            // Also set isAdminManaged to true if newDefault is true
            if (newDefault) {
                setIsAdminManaged(true);
            }
            return newDefault;
        });
    };

    // Toggle handler for the "Admin Managed" switch
    const handleAdminManagedToggle = () => setIsAdminManaged(prevState => !prevState);

    const handleInitiativeToggleChange = () => setPogInitiative(prevState => !prevState);

    const handleTextAreaChange = (setter: React.Dispatch<React.SetStateAction<string>>) => (event: ChangeEvent<HTMLTextAreaElement>) => {
        setter(event.target.value);
    };

    // Change handler factory for react-select components
    const createSelectChangeHandler = (setter: React.Dispatch<React.SetStateAction<string>>) => (selectedOption: any) => {
        setter(selectedOption ? selectedOption.value : '');
    };

    const createMultiSelectChangeHandler = (setter: React.Dispatch<React.SetStateAction<string[]>>) => (selectedOption: any) => {
        const selection = selectedOption ? selectedOption.map((option: any) => option.value) : [];
        setter(selection);
    };

    const handleMonthChange = (selectedOption: any) => {
        setPogMonths(selectedOption ? selectedOption.value : '');
    }

    // Save and submit form
    const handleSave = async () => {

        setIsSubmitted(true);

        if (!partner) {
            // setFormErrorMsg(`Partner ID for ${ado} not found.`);
            // setShowModal(true);
            return;
        }

        const mappedServiceArea = serviceAreaOptions.find(area => area.value === serviceArea)?.label;
        
        const selectedAdoAreaNames = adoAreaOptions
            .filter(option => adoArea.includes(option.value))
            .map(option => option.label);

        // Find the matching location objects from the locations object
        const selectedLocations = getLocationsByNames(selectedAdoAreaNames, typesData.partner_locations);

        const transformedLocations = selectedLocations.map(location => ({
            loc_id: location.id,
        }));

        console.log('transformed locations:', transformedLocations);

        // Find the matching initiative type object
        const matchedInitiativeType = typesData.initiative_types.find(
            (type) => type.id === Number(initiativeType)
        );

        const partnerId = partner.id;

        const allIndustry = industry === null; // True if "All Industries" is selected
        
        // TODO may need to handle external_id, set as null for new initiatives
        const newInitiative = {
            external_id: "",
            year: year,
            quarter: quarter,
            name: name,
            description: description,
            status: initiativeStatus,
            initiative_type_id: matchedInitiativeType?.id,
            all_industry: allIndustry,
            industry_code: industry,
            sub_industry_code: subIndustry,
            all_area: mappedServiceArea === 'Full ADO Area' ? true : false,
            locations: transformedLocations,
            pog_initiative: pogInitiative ? true : false,
            pog_business_name: pogBusinessName,
            pog_business_ubi: pogUbi,
            pog_in_business_12_mo: pogMonths ? true : false,
            is_default: isDefault,
            admin_managed: isAdminManaged
        };

        console.log('payload:', newInitiative);
        
        if (year && quarter && name && description && initiativeStatus && initiativeType) {
            try {
                const response = await fetch(`https://backend-cai-commerce.vercel.app/api/addinitiative?partnerId=${partnerId}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(newInitiative),
                });
        
                const responseData = await response.json();
        
                if (!response.ok) {
                    throw new Error(responseData.error || 'Failed to add initiative');
                }
        
                console.log('Data successfully sent to backend:', responseData);
        
                // Reset the form after successful save
                setAdo('');
                setYear('');
                setName('');
                setDescription('');
                setInitiativeStatus('');
                setInitiativeType('');
                setPogInitiative(false);
                setPogBusinessName('');
                setPogUbi('');
                setPogMonths(false);
        
                // Return to Initiatives dashboard
                navigateBack();
            } catch (error) {
                // Log the caught error to Sentry
                Sentry.captureException(error);
                console.error('Error sending data to backend:', error);
            }
        }
    };

    const handleCancel = () => {
        // Return to Initiatives dashboard
        navigateBack();
    }
    

    return (
        <div className="m-2 p-4">
            {/* Back to Initiatives button */}
            <div className='flex justify-between'>
                <button onClick={navigateBack} className="flex items-center mb-4 font-semibold text-sm">
                    <img src={leftArrow} alt='left arrow' className='h-3 w-4 mr-3'></img>
                    Back to Initiatives
                </button>
                {isIframe && 
                    <div className="flex ml-auto mb-4">
                        <Expand/>
                    </div>
                }
            </div>

            {/* Initiative Details */}
            <div className="mb-8 border border-solid border-color-tableGray rounded-md">
                <div className='border-b border-solid border-color-tableGray mb-5'>
                    <h2 className="text-sm font-semibold mt-3 mb-3 ml-7">Add Initiative</h2>
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-1 ml-3'>
                    {/* ADO Name (Admin Only) */}
                    { isAdmin &&
                        <div className="ff-container">
                            <label htmlFor="selectAdo" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                                ADO:
                            </label>
                            <div className="relative w-full">
                                <div className='flex flex-col w-full'>
                                    <Select
                                        id="selectAdo"
                                        value={adoOptions.find(option => option.value === ado)}
                                        onChange={createSelectChangeHandler(setAdo)}
                                        options={adoOptions}
                                        className="w-full"
                                        placeholder="Select ADO"
                                        styles={customStyles(isSubmitted, ado)}
                                    />
                                    <p className="text-red-500 text-sm">{`${isSubmitted && !ado ? 'ADO is required.' : ''}`}</p>
                                </div>
                            </div>
                        </div>
                    }
                    { isAdmin && 
                    <div className="ff-container">
                        <label htmlFor="inputDefaultInitiative" className="mb-2 sm:mb-0 sm:mr-4 lg:mr-10 text-sm font-semibold w-25">
                            Default Initiative:
                        </label>
                        <button
                            id="inputDefaultInitiative"
                            type="button"
                            onClick={handleDefaultInitiativeToggle}
                            className={`w-14 h-7 rounded-full border border-gray-300 relative overflow-hidden transition-all duration-300 ${isDefault ? 'bg-blue-500' : 'bg-gray-300'} focus:ring-2 focus:ring-blue-500 focus:outline-none`}
                        >
                            <span
                                className={`absolute left-0 top-[.05px] bg-white w-7 h-7 rounded-full transition-all duration-300 transform ${isDefault ? 'translate-x-7' : 'translate-x-0'}`}
                            />
                        </button>
                        
                        <label htmlFor="inputAdminManaged" className="mb-2 sm:mb-0 sm:mr-4 lg:mr-10 mt-4 sm:mt-0 ml-0 sm:ml-16 text-sm font-semibold w-25">
                            Admin Managed:
                        </label>
                        <button
                            id="inputAdminManaged"
                            type="button"
                            onClick={handleAdminManagedToggle}
                            className={`w-14 h-7 rounded-full border border-gray-300 relative overflow-hidden transition-all duration-300 ${isAdminManaged ? 'bg-blue-500' : 'bg-gray-300'} focus:ring-2 focus:ring-blue-500 focus:outline-none`}
                        >
                            <span
                                className={`absolute left-0 top-[.05px] bg-white w-7 h-7 rounded-full transition-all duration-300 transform ${isAdminManaged ? 'translate-x-7' : 'translate-x-0'}`}
                            />
                        </button>
                    </div>
                    }
                    {/* Initiative Name */}
                    <div className="ff-container">
                        <label htmlFor="inputInitiativeName" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                            Initiative Name:
                        </label>
                        <div className='flex flex-col w-full'>
                            <input
                                id="inputInitiativeName"
                                type="text"
                                value={name}
                                placeholder='Add Initiative Name'
                                onChange={handleInputChange(setName)}
                                className={`text-sm pl-4 w-full pr-4 py-2 border border-solid ${isSubmitted && !name ? 'border-red-500' : 'border-tableGray'} rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none`}
                            />
                            <p className="text-red-500 text-sm">{`${isSubmitted && !name ? 'Initiative Name is required.' : ''}`}</p>
                        </div>
                    </div>
                    {/* Status */}
                    <div className="ff-container">
                        <label htmlFor="selectStatus" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                            Status:
                        </label>
                        <div className="relative w-full">
                            <div className='flex flex-col w-full'>
                                <Select
                                    id="selectStatus"
                                    value={statusOptions.find(option => option.value === initiativeStatus)}
                                    onChange={createSelectChangeHandler(setInitiativeStatus)}
                                    options={statusOptions}
                                    placeholder="Select Status"
                                    styles={customStyles(isSubmitted, initiativeStatus)}
                                    isClearable
                                />
                                <p className="text-red-500 text-sm">{`${isSubmitted && !initiativeStatus ? 'Status is required.' : ''}`}</p>
                            </div>
                        </div>
                    </div>
                    {/* Description */}
                    <div className="flex flex-col sm:flex-row items-start sm:items-start my-4 mx-4 sm:mr-20">
                        <label htmlFor="inputDescription" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                            Description:
                        </label>
                        <div className='flex flex-col w-full'>
                            <textarea
                                id="inputDescription"
                                value={description}
                                placeholder='Add Description'
                                onChange={handleTextAreaChange(setDescription)}
                                className={`text-sm pl-4 w-full pr-4 py-2 border border-solid ${isSubmitted && !description ? 'border-red-500' : 'border-tableGray'} rounded-[4px] focus:outline-none focus:border-blue-500 h-28 appearance-none`}
                            />
                            <p className="text-red-500 text-sm">{`${isSubmitted && !description ? 'Description is required.' : ''}`}</p>
                        </div>
                    </div>

                    {/* Select Initiative Type */}
                    <div className="flex flex-col sm:flex-row items-start sm:items-start my-4 mx-4 sm:mr-20">
                        <label htmlFor="selectInitiativeType" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                            Initiative Type:
                        </label>
                        <div className="relative w-full">
                            <div className='flex flex-col w-full'>
                                <Select
                                    id="selectType"
                                    value={initiativeTypeOptions.find((option: any) => option.value === initiativeType)}
                                    onChange={createSelectChangeHandler(setInitiativeType)}
                                    options={initiativeTypeOptions}
                                    placeholder="Select Type"
                                    styles={customStyles(isSubmitted, initiativeType)}
                                    isClearable
                                />
                                <p className="text-red-500 text-sm">{`${isSubmitted && !initiativeType ? 'Initiative Type is required.' : ''}`}</p>
                            </div>
                        </div>
                    </div>
                    {/* Select Year */}
                    <div className="ff-container">
                        <label htmlFor="selectYear" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                            Year:
                        </label>
                        <div className="relative w-full">
                            <div className='flex flex-col w-full'>
                                <Select
                                    id="selectYear"
                                    value={yearOptions.find(option => option.value === year)}
                                    onChange={createSelectChangeHandler(setYear)}
                                    options={yearOptions}
                                    placeholder="Select Year"
                                    styles={customStyles(isSubmitted, year)}
                                    isClearable
                                />
                                <p className="text-red-500 text-sm">{`${isSubmitted && !year ? 'Year is required.' : ''}`}</p>
                            </div>
                        </div>
                    </div>
                    <br></br>
                    <div className="ff-container">
                        <label htmlFor="selectIndustry" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                            Industry:
                        </label>
                        <div className="relative w-full">
                            <div className='flex flex-col w-full'>
                                <Select
                                    id="selectIndustryType"
                                    value={industryTypeOptions.find((option: any) => option.value === industry)}
                                    onChange={createSelectChangeHandler(setIndustry)}
                                    options={industryTypeOptions}
                                    placeholder="Select Industry"
                                    styles={customStyles()}
                                    isClearable
                                />
                                {/* <p className="text-red-500 text-sm">{`${isSubmitted && !industry ? 'Industry is required.' : ''}`}</p> */}
                            </div>
                        </div>
                    </div>
                    {industry !== null && 
                    <div className="ff-container">
                        <label htmlFor="selectSubIndustry" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                            Sub-Industry:
                        </label>
                        <div className="relative w-full">
                            <div className='flex flex-col w-full'>
                                <Select
                                    id="selectSubIndustryType"
                                    value={subIndustryOptions.find(option => option.value === subIndustry)}
                                    onChange={createSelectChangeHandler(setSubIndustry)}
                                    options={subIndustryOptions}
                                    placeholder="Select Sub-Industry"
                                    styles={customStyles()}
                                    isClearable
                                />
                                {/* <p className="text-red-500 text-sm">{`${isSubmitted && !subIndustry ? 'Sub-industry is required.' : ''}`}</p> */}
                            </div>
                        </div>
                    </div>
                    }
                    {industry === null && <br></br>}
                    <div className="ff-container">
                        <label htmlFor="selectServiceArea" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                            Service Area:
                        </label>
                        <div className="relative w-full">
                            <div className='flex flex-col w-full'>
                                <Select
                                    id="selectServiceArea"
                                    value={serviceAreaOptions.find(option => option.value === serviceArea)}
                                    onChange={createSelectChangeHandler(setServiceArea)}
                                    options={serviceAreaOptions}
                                    placeholder="Select Service Area"
                                    styles={customStyles()}
                                    isClearable
                                />
                                {/* <p className="text-red-500 text-sm">{`${isSubmitted && !serviceArea ? 'Service Area is required.' : ''}`}</p> */}
                            </div>
                        </div>
                    </div>
                    {/* Area within ADO */}
                    {serviceArea == 'Selected Area within ADO' ? <div className="ff-container">
                        <label htmlFor="selectWithinADO" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                            Area within ADO:
                        </label>
                        <div className="relative w-full">
                            <div className='flex flex-col w-full'>
                                <Select
                                    id="selectAdoArea"
                                    value={adoAreaOptions.filter((option: any) => adoArea.includes(option.value))}
                                    onChange={createMultiSelectChangeHandler(setAdoArea)}
                                    options={adoAreaOptions}
                                    placeholder="Select Area"
                                    styles={customStyles()}
                                    isClearable
                                    isMulti
                                />
                                {/* <p className="text-red-500 text-sm">{`${isSubmitted && !adoArea ? 'Area within ADO is required.' : ''}`}</p> */}
                            </div>
                        </div>
                    </div> : <br></br>}
                    
                    {/* Priorities of Government Initiative */}
                    <div className="ff-container">
                        <label htmlFor="inputPog" className="text-sm mb-2 sm:mb-0 sm:mr-4 lg:mr-10 font-semibold w-25">
                            Priorities of Government Initiative:
                        </label>
                        <button
                            id="inputPog"
                            type="button"
                            onClick={handleInitiativeToggleChange}
                            className={`w-14 h-7 rounded-full border border-gray-300 focus:outline-none relative overflow-hidden transition-all duration-300 ${pogInitiative ? 'bg-blue-500' : 'bg-gray-300'} focus:ring-2 focus:ring-blue-500 focus:outline-none`}
                        >
                            <span
                                className={`absolute left-0 top-[.05px] bg-white w-7 h-7 rounded-full transition-all duration-300 transform ${pogInitiative ? 'translate-x-7' : 'translate-x-0'}`}
                            />
                        </button>
                    </div>
                    {/* Business Name if Pog initiative */}
                    {pogInitiative && 
                    <div className="ff-container">
                        <label htmlFor="inputBusinessName" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                            Business Name:
                        </label>
                        <input
                            id="inputBusinessName"
                            type="text"
                            value={pogBusinessName}
                            placeholder='Add Business Name'
                            onChange={handleInputChange(setPogBusinessName)}
                            className="text-sm pl-4 w-full pr-4 py-2 border border-solid border-tableGray rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none"
                        />
                    </div>}
                    {/* Business UBI if Pog initiative */}
                    {pogInitiative && 
                    <div className="ff-container">
                        <label htmlFor="inputBusinessUbi" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                            Business UBI:
                        </label>
                        <input
                            id="inputBusinessUbi"
                            type="text"
                            value={pogUbi}
                            placeholder='Add Business UBI'
                            onChange={handleInputChange(setPogUbi)}
                            className="text-sm pl-4 w-full pr-4 py-2 border border-solid border-tableGray rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none"
                        />
                    </div>}
                    {/* Operation for last 12 months? */}
                    {pogInitiative && 
                    <div className="ff-container">
                        <label htmlFor="selectOperationLength" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                            In operation for last 12 months:
                        </label>
                        <div className="relative w-full">
                            <Select
                                id="selectMonths"
                                value={monthOptions.find(option => option.value === pogMonths)}
                                onChange={handleMonthChange}
                                options={monthOptions}
                                placeholder="Select Yes/No"
                                styles={customStyles()}
                                isClearable
                            />
                            {/* <img src={arrowUp} alt='Arrow up' className='absolute h-3 w-3 top-2 right-2 pointer-events-none'></img>
                            <img src={arrowDown} alt='Arrow down' className='absolute h-3 w-3 top-[18px] right-2 pointer-events-none'></img> */}
                        </div>
                    </div>}
                </div>
                {/* <FormErrorModal
                    isOpen={showModal}
                    onClose={() => setShowModal(false)}
                    message={formErrorMsg}
                /> */}
                <div className='border-t border-solid border-color-tableGray mt-5 mb-2'>
                    <button
                        id="save-target"
                        onClick={handleSave}
                        className="ml-7 mt-5 mb-3 w-36 h-9 text-center text-white bg-color-cai-dashboard border border-solid border-color-cai-dashboard rounded-[4px] focus:outline-none focus:border-blue-500  hover:bg-blue-400 hover:border-blue-500"
                    > Save
                    </button>
                    <button
                        id="cancel-target"
                        onClick={handleCancel}
                        className="ml-7 mt-5 mb-3 w-36 h-9 text-center text-color-cai-dashboard bg-white border border-solid border-color-cai-dashboard rounded-[4px] focus:outline-none focus:border-red-500  hover:bg-red-400 hover:border-red-500 hover:text-white"
                    > Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AddInitiative;