import { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { AdminProviderProps, TargetsData, TargetsDataContextType } from '../services/types';
import * as Sentry from '@sentry/react';



const TargetsDataContext = createContext<TargetsDataContextType>({
    targets: [],
    fetchTargets: () => {},
});

export const TargetsDataProvider = ({ children }: AdminProviderProps) => {
    const [targets, setTargets] = useState<TargetsData[]>([]);
    const apiUrl = `https://backend-cai-commerce.vercel.app/api/gettargets`;

    const fetchTargets = async () => {
        try {
            const response = await axios.get(apiUrl);
            if (response.status === 200) {
                const data = response.data.data;
                setTargets(data);
            } else {
                // Log the error response to Sentry
                Sentry.captureMessage(`Error fetching Targets: ${response.data.message}`, 'warning');
                console.error('Error fetching Targets:', response.data);
            }
        } catch (error) {
            // Log the caught error to Sentry
            Sentry.captureException(error);
            console.error('Error fetching Targets:', error);
        }
    };

    useEffect(() => {
        fetchTargets();
    }, []);

    return (
        <TargetsDataContext.Provider value={{ targets, fetchTargets }}>
            {children}
        </TargetsDataContext.Provider>
    );
};

export default TargetsDataContext;