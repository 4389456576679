import { useState, useRef, ChangeEvent, DragEvent } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Papa, { ParseResult } from 'papaparse';
import * as XLSX from 'xlsx';
import googleSheetsIcon from '../../assets/google-sheets-icon.svg';
// import leftArrow from '../../assets/left-arrow.svg';
import bulbIcon from '../../assets/bulb-icon.svg';
import trashIcon from '../../assets/trash.svg';
import FormErrorModal from '../FormErrorModal';
import { useIframeContext } from '../../context/IframeContext';
import Expand from '../Expand';

function UploadInteractions() {
    const navigate = useNavigate();
    const location = useLocation();
    const { isIframe } = useIframeContext();
    const [file, setFile] = useState<File | null>(null);
    const [uploadComplete, setUploadComplete] = useState<boolean>(false);
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const [parsedData, setParsedData] = useState<any[]>([]); // State to store parsed data
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [showModal, setShowModal] = useState(false);
    const [formErrorMsg, setFormErrorMsg] = useState('');

    const { initiativeId, initiative, rowData } = location.state;

    const navigateBack = () => {
        console.log('rowdata:', rowData);
        // navigate(-3);
        navigate(`/commerce-initiatives/detail/${initiativeId}`);
    };

    const handleSave = () => {
        // If no file, alert user to select a valid file
        if (!file) {
            return;
        }
        // Navigate to ValidateDataFrame component passing the parsed data as props
        navigate('/upload-interactions/validate', { state: { data: parsedData, file: file, initiativeId, initiative } });

    };

    const handleCancel = () => {
        navigateBack();
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const selectedFile = event.target.files[0];
            const fileExtension = selectedFile.name.split('.').pop()?.toLowerCase();
            if (isValidFileType(selectedFile)) {
                setFile(selectedFile);
                setUploadComplete(false);
                setUploadProgress(0);

                // Handle xlsx or xls files
                if (fileExtension === 'xlsx' || fileExtension === 'xls') {
                    const reader = new FileReader();

                    reader.onload = (e) => {
                        const data = new Uint8Array(e.target?.result as ArrayBuffer);
                        const workbook = XLSX.read(data, { type: 'array' });

                        // Assuming the first sheet is used
                        const sheetName = workbook.SheetNames[0];
                        const sheet = workbook.Sheets[sheetName];

                        // Convert to CSV
                        const csvData = XLSX.utils.sheet_to_csv(sheet);

                        // Parse CSV data using PapaParse
                        Papa.parse(csvData, {
                            header: true,
                            skipEmptyLines: true,
                            complete: function (results: ParseResult<any>) {
                                console.log('parsed CSV data from xlsx:', results.data);
                                const mappedData = mapKeys(results.data);
                                setParsedData(mappedData); // Store parsed data in state
                                simulateUpload();
                            },
                        });
                    };

                    reader.readAsArrayBuffer(selectedFile); // Read file as an array buffer
                } else {
                    // Handle CSV files
                    Papa.parse(selectedFile, {
                        header: true,
                        skipEmptyLines: true,
                        complete: function (results: ParseResult<any>) {
                            console.log('parsed CSV data:', results.data);
                            const mappedData = mapKeys(results.data);
                            setParsedData(mappedData); // Store parsed data in state
                            simulateUpload();
                        },
                    });
                }
            } else {
                setFormErrorMsg('Please drop a valid CSV file.');
                setShowModal(true);
            }
        }
    };

    const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.dataTransfer.files) {
            const droppedFile = event.dataTransfer.files[0];
            if (isValidFileType(droppedFile)) {
                setFile(droppedFile);
                setUploadComplete(false);
                setUploadProgress(0);
                simulateUpload();
            } else {
                setFormErrorMsg('Please drop a valid CSV file.');
                setShowModal(true);
            }
        }
    };

    const handleSelectClick = () => {
        fileInputRef.current?.click();
    };

    const isValidFileType = (file: File) => {
        const allowedExtensions = ['csv', 'xls', 'xlsx'];
        const fileExtension = file.name.split('.').pop()?.toLowerCase();
        return fileExtension && allowedExtensions.includes(fileExtension);
    };

    const handleRemoveFile = () => {
        setFile(null);
        setUploadComplete(false);
        setUploadProgress(0);
    };

    const simulateUpload = () => {
        let progress = 0;
        const interval = setInterval(() => {
            progress += 10;
            if (progress >= 100) {
                clearInterval(interval);
                setUploadComplete(true);
                setUploadProgress(100);
            } else {
                setUploadProgress(progress);
            }
        }, 50);
    };

    const mapKeys = (data: any[]): any[] => {
        // Define the mapping of old keys to new keys
        const keyMap: Record<string, string> = {
            "Staff Name": "staff_name",
            "Business Trade Name": "business_trade_name",
            "Business Legal Name": "business_legal_name",
            "UBI": "business_ubi",
            "Type of Interaction": "interaction_type",
            "People Employed by Business": "people_employed",
            "Gross Revenue Estimate": "gross_revenue",
            "Business Contact Type": "contact_type",
            "Business Contact First Name": "first_name",
            "Business Contact Last Name": "last_name",
            "Business Contact Phone": "phone",
            "Business Contact Email": "email",
            "Business Classifications": "business_classification",
            "Type of Assistance Provided": "assistance",
            "Date": "date",
            "Notes": "note",
            "Longitude": "longitude",
            "Latitude": "latitude",
            "Address": "address",
        };

        // Map keys in each object of the array
        const transformedData = data.map(item => {
            const transformedItem: any = {};
            Object.keys(item).forEach(key => {
                if (keyMap[key] === "assistance" || keyMap[key] === 'business_classification') {
                    // Transform the field into the desired format with curly braces
                    transformedItem[keyMap[key]] = item[key]
                    ? `{${item[key].split(',').map((v: string) => `"${v.trim()}"`).join(',')}}`
                    : "{}";
                } else if (keyMap[key]) {
                    transformedItem[keyMap[key]] = item[key];
                } else {
                    transformedItem[key] = item[key];
                }
            });
            return transformedItem;
        });

        return transformedData;
    };

    const downloadCSV = () => {
        // Define the CSV data
        const csvData = `Business Trade Name,Business Legal Name,UBI,Type of Interaction,People Employed by Business,Gross Revenue Estimate,Business Contact Type,Business Contact First Name,Business Contact Last Name,Business Contact Phone,Business Contact Email,Date,Notes,Address,Latitude,Longitude,Business Classifications,Type of Assistance Provided,Staff Name
Acme Corp,Acme Corporation,123456789,Business Retention and Expansion,30,200000,Manager,Alice,Johnson,(555) 123-4567,alice.johnson@example.com,1/10/2024,Assisted with expansion plans.,"100 Main St, Anytown, WA",47.6062,-122.3321,BIPOC-owned Business,Workforce Development,John Smith
Future Innovations,Future Innovations LLC,987654321,Training,20,150000,Business Owner,Mark,Lee,(555) 987-6543,mark.lee@example.com,2/15/2024,Provided training for new software implementation.,"200 Future Rd, Tech City, WA",47.62,-122.3493,Woman-owned Business,Site Selection,Emma Brown
Green Earth Solutions,Green Earth Solutions Inc.,192837465,Startup Support,10,50000,Manager,Linda,Carter,(555) 345-6789,linda.carter@example.com,3/20/2024,Connected with venture capital opportunities.,"300 Green St, Greenfield, WA",47.608,-122.335,Washington Small Business,Access to Capital,Sophia White
Bright Horizons,Bright Horizons LLC,564738291,Recruitment,40,800000,Business Development,Robert,Brown,(555) 678-9012,robert.brown@example.com,4/25/2024,Assisted with recruitment for new positions.,"400 Horizon Blvd, Brightown, WA",47.6201,-122.3412,Veteran-owned Business,"Workforce Development,Site Recommendation",Lily Green
        `;
    
        // Create a Blob from the CSV data
        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        
        // Create a link element and trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.download = 'interaction-upload-template.csv';
        document.body.appendChild(link);
        link.click();
        
        // Cleanup
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    return (
        <div className="p-4 m-2">
            {/* Back to Initiatives button */}
            {/* <button onClick={navigateBack} className="flex items-center mb-8 font-semibold text-color-cai-gray">
                <img src={leftArrow} alt='left arrow' className='h-3 w-4 mr-3'></img>
                Back to Initiatives
            </button> */}

            <div className="mb-8 pb-2 border border-solid border-color-tableGray rounded-md w-full">
                <div className="flex items-center my-3 pb-5 border-b border-solid border-color-tableGray">
                    <h2 className="font-semibold text-xl ml-7 w-1/3 text-color-cai-gray">Upload Interactions</h2>
                    {isIframe && 
                        <div className="flex ml-auto mr-4 mb-4">
                            <Expand/>
                        </div>
                    }
                </div>
                <h3 className='ml-4 sm:ml-16 my-5 w-full font-semibold text-color-cai-gray'>Select a file</h3>

                <div
                    className={`flex flex-col items-center justify-center border ${uploadComplete ? 'border-solid' : 'border-dashed'} border-color-tableGray rounded-md sm:w-3/5 h-72 sm:ml-16 sm:mr-0 mx-4 mb-5`}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                >
                    {!file ? (
                        <>
                            <img src={googleSheetsIcon} alt='Google Sheets Icon' className='mb-4'></img>
                            <p className='text-color-cai-gray mx-2 text-center'>
                                <span
                                    className="text-blue-500 cursor-pointer hover:text-blue-600"
                                    onClick={handleSelectClick}
                                >
                                    Select a CSV or Excel file
                                </span> or drag and drop to import
                            </p>
                            <p className='text-color-cai-gray'>( Max file size limit: 30 MB ) </p>
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                accept=".csv, .xls, .xlsx"
                                onChange={handleFileChange}
                            />
                        </>
                    ) : (
                        <>
                            <img src={googleSheetsIcon} alt='Google Sheets Icon' className='mb-4'></img>
                            <p className='text-color-cai-gray text-left text-sm'>{file.name}</p>
                            <div className='flex sm:ml-8 sm:w-1/2'>
                                <div className='flex flex-col w-full px-4'>
                                    {uploadComplete && <p className="mt-2 text-color-cai-gray text-xs text-right">CSV Uploaded Successfully</p>}
                                    {!uploadComplete && <p className="mt-2 text-color-cai-gray text-xs text-right">Uploading...</p>}
                                    <div className="bg-green-500 h-2 rounded-full" style={{ width: `${uploadProgress}%` }}></div>
                                    {/* {!uploadComplete && <p className="mt-2 text-green-600">{uploadProgress}% uploaded</p>} */}
                                    
                                </div>
                                <button
                                    className="px-2 mt-2 h-6 text-xs border border-solid bg-white rounded-md hover:bg-color-cai-lightGray"
                                    onClick={handleRemoveFile}
                                >
                                    {uploadComplete ? 
                                        <div className='flex w-16 mr-1 items-center'>
                                            <img src={trashIcon} alt='trash icon' className='mr-1'></img>Remove
                                        </div> : 'Cancel'}
                                </button>
                            </div>
                        </>
                    )}
                </div>
                <FormErrorModal
                    isOpen={showModal}
                    onClose={() => setShowModal(false)}
                    message={formErrorMsg}
                />
                <div className='flex items-center justify-center border border-solid border-color-tableGray bg-color-cai-lightGray rounded-md h-24 sm:w-3/5 sm:ml-16 sm:mr-0 mx-4 mb-5'>
                    <img src={bulbIcon} alt='bulb icon' className='mx-3'></img>
                    <p className='mx-5'>If you're not sure about the format, you can {' '}
                        <span 
                            className="text-blue-500 cursor-pointer hover:text-blue-600"
                            onClick={downloadCSV}
                        > download the sample template
                        </span>
                        {' '} and upload here.
                    </p>
                </div>
                <div className="flex items-center border-t border-solid border-color-tableGray">
                    <button
                        id="save-target"
                        onClick={handleSave}
                        className={`ml-7 mt-5 mb-3 w-36 h-8 text-center text-white bg-color-cai-dashboard border border-solid border-color-cai-dashboard rounded-md focus:outline-none focus:border-blue-400 hover:bg-blue-400 hover:border-blue-600 ${!file ? 'cursor-not-allowed opacity-50' : 'hover:bg-blue-400 hover:border-blue-600'}`}
                    >
                        Next
                    </button>
                    <button
                        id="cancel-target"
                        onClick={handleCancel}
                        className="ml-7 mt-5 mb-3 w-36 h-8 text-center text-color-cai-dashboard bg-white border border-solid border-color-cai-dashboard rounded-md focus:outline-none focus:border-red-400 hover:bg-red-400 hover:border-red-500 hover:text-white"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
}

export default UploadInteractions;