import { useEffect, useState } from "react";

export const useFetchDefaultInitiative = (partnerId: number | null) => {
    const [defaultInitiative, setDefaultInitiative] = useState<any[]>([]);

    useEffect(() => {
        const fetchDefaultInitiative = async () => {
            if (!partnerId) return;

            try {
                const response = await fetch(
                    `https://backend-cai-commerce.vercel.app/api/getdefaultinitiative?partnerId=${partnerId}`,
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                );

                const responseData = await response.json();

                if (!response.ok) {
                    throw new Error(responseData.error || 'Failed to fetch initiative');
                }

                setDefaultInitiative(responseData.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchDefaultInitiative();
    }, [partnerId]);

    return { defaultInitiative };
};