import { createContext, useContext, useState, useEffect, useRef } from 'react';
import { OrganizationContextType, AdminProviderProps } from '../services/types';
import AdoDataContext from './AdoDataContext';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';

const defaultOrganizationContext: OrganizationContextType = {
    partnerId: null,
    setPartnerId: () => {},
    partnerName: "",
    setPartnerName: () => {},
};

const OrganizationContext = createContext<OrganizationContextType>(defaultOrganizationContext);

export const OrganizationProvider = ({ children }: AdminProviderProps) => {
    const [partnerId, setPartnerId] = useState<number | null>(null);
    const [partnerName, setPartnerName] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true); // Add loading state
    const { partnerData } = useContext(AdoDataContext);
    const { user, isAuthenticated, isLoading } = useAuth0();
    const tokenHandled = useRef<boolean>(false);
    const pendingToken = useRef<string | null>(null);

    const isIframe = window.self !== window.top;
    const location = useLocation();

    const handleToken = async (token: string) => {
        try {
            const response = await fetch(`https://backend-cai-commerce.vercel.app/api/gettoken`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token }),
            });

            const responseData = await response.json();

            if (!response.ok) {
                throw new Error(responseData.error || 'Failed to retrieve token');
            }

            const { commerce_ado_code } = responseData;
            if (commerce_ado_code && partnerData.length > 0) {
                if (commerce_ado_code === 'All_ADOs') {
                    setPartnerId(15);
                    setPartnerName('Admin');
                } else if (commerce_ado_code === 'cai_admin') {
                    setPartnerId(1);
                    setPartnerName('CAI-Admin');
                } else {
                    const matchedPartner = partnerData.find(
                        partner => partner.short_name.trim() === commerce_ado_code.trim()
                    );

                    if (matchedPartner) {
                        setPartnerId(matchedPartner.id);
                        setPartnerName(matchedPartner.name);
                        tokenHandled.current = true;
                    }
                }
            }
        } catch (error) {
            // Log the caught error to Sentry
            Sentry.captureException(error);
            console.error('Error sending data to backend:', error);
        } finally {
            setLoading(false); // Set loading to false when done
        }
    };

    const receiveMessage = (event: MessageEvent) => {
        event.preventDefault();
        if (event.data.message === "update" && !tokenHandled.current) {
            pendingToken.current = event.data.params.token;
            if (partnerData.length > 0 && pendingToken.current) {
                handleToken(pendingToken.current);
                pendingToken.current = null;
            }
        }
    };

    useEffect(() => {
        // We need to first check if the partner id is passed through url params (in the context of Iframed-Edit page)
        const queryParams = new URLSearchParams(location.search);
        const queryPartnerId = queryParams.get('partnerId');

        if (queryPartnerId) {
            setPartnerId(parseInt(queryPartnerId, 10));
            setLoading(false);
            return;
        }

        if (isIframe) {
            window.addEventListener("message", receiveMessage, false);
            return () => {
                window.removeEventListener("message", receiveMessage);
            };
        } else if (!isLoading && isAuthenticated && partnerData.length > 0 && user?.commerce_ado_code) {
            if (user.commerce_ado_code === 'All_ADOs') {
                setPartnerId(15);
                setPartnerName('Admin');
            } else if (user.commerce_ado_code === 'cai_admin') {
                setPartnerId(1);
                setPartnerName('CAI-Admin');
            } else {
                const matchedPartner = partnerData.find(
                    partner => partner.short_name.trim() === user.commerce_ado_code.trim()
                );

                if (matchedPartner) {
                    setPartnerId(matchedPartner.id);
                    setPartnerName(matchedPartner.name);
                }
            }
            setLoading(false); // Set loading to false when done
        }
    }, [isLoading, isAuthenticated, user, partnerData, isIframe, location.search]);

    useEffect(() => {
        if (pendingToken.current && partnerData.length > 0) {
            handleToken(pendingToken.current);
            pendingToken.current = null;
        }
    }, [partnerData]);

    return (
        <OrganizationContext.Provider value={{ partnerId, setPartnerId, partnerName, setPartnerName }}>
            {!loading ? children : <div className="loading-container">
        <div className="loading-spinner"></div>
      </div>} {/* Delay rendering until loading is false */}
        </OrganizationContext.Provider>
    );
};

export const useOrganizationContext = () => useContext(OrganizationContext);
