import { Link } from 'react-router-dom';
import UploadInteractionsBtn from './Interactions/UploadInteractionsBtn';
import { useOrganizationContext } from '../context/OrganizationContext';
import { useFetchDefaultInitiative } from '../hooks/useFetchDefaultInitiative';

interface CardProps {
    header: string;
    text: string;
    svgUrl: string;
    linkTo: string;
    height: string;
}

function LandingCard({ header, text, svgUrl, linkTo, height }: CardProps) {
    const { partnerId } = useOrganizationContext();
    const { defaultInitiative } = useFetchDefaultInitiative(partnerId);

    const [initiativeData] = defaultInitiative; // Destructure the default initiative (first element)
    const initiativeId = initiativeData?.id;
    const initiative = initiativeData?.name;

    return (
        <Link to={linkTo}>
            <div className={`bg-white border border-solid border-lightGray p-4 rounded shadow-sm flex flex-col max-w-sm min-w-[368px] ${height} text-left`}>
                <div className="flex mb-3 items-center">
                    <img src={svgUrl} alt={`${header} icon`} className="w-10 h-10 text-blue-500" />
                    <h3 className="text-color-cai-gray text-xl font-semibold ml-1">{header}</h3>
                    {header === 'Interactions' && partnerId && initiativeData && (
                        <div
                            className='flex items-center justify-center ml-auto'
                            onClick={(e) => {
                                e.preventDefault(); // Prevent the link navigation
                            }}
                        >
                            <UploadInteractionsBtn id={initiativeId} initiative={initiative} initiativeData={initiativeData} />
                        </div>
                    )}
                </div>
                <hr></hr>
                <p className="text-color-cai-gray text-sm mt-2 w-full">{text}</p>
            </div>
        </Link>
    );
}

export default LandingCard;