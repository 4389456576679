import { useContext, useEffect, useState } from "react";
import SearchInput from "../../components/SearchInput";
import ReportingDropdown from "../../components/BreDropdown";
import BreSort from "../../components/BreSort";
import FiscalYear from "../../components/FiscalYear";
import InteractionType from "../../components/Interactions/InteractionType";
import { calculateAvailableYears, calculateFiscalYear } from "../../utils/helpers";
import AssistanceType from "../../components/Interactions/AssistanceType";
import BulkActionBtn from "../../components/Interactions/BulkActionBtn";
import AddInteractionButton from "../../components/Interactions/AddInteractionButton";
import InteractionTable from "../../components/Interactions/InteractionTable";
import InteractionDataContext from "../../context/InteractionDataContext";
import useDeepCompareEffect from "../../hooks/useDeepCompareEffect";
import useFilteredData from "../../hooks/useFilteredData";
import InitiativeDataContext from "../../context/InitiativeDataContext";
import InitiativeSort from "../../components/Interactions/InitiativeSort";
import Expand from "../../components/Expand";
import { useIframeContext } from "../../context/IframeContext";
import { useOrganizationContext } from "../../context/OrganizationContext";
import { useFetchDefaultInitiative } from "../../hooks/useFetchDefaultInitiative";
import UploadInteractionsBtn from "../../components/Interactions/UploadInteractionsBtn";
import SelectADO from "../../components/SelectADO";
import { useCAIAdminContext } from "../../context/CAIAdminContext";


function Interactions() {
    const { interactions } = useContext(InteractionDataContext);
    const { initiatives } = useContext(InitiativeDataContext);
    const { isIframe } = useIframeContext();
    const { isCAIAdmin } = useCAIAdminContext();
    const { partnerName, partnerId } = useOrganizationContext();

    // Setting default initiative fields if present
    const { defaultInitiative } = useFetchDefaultInitiative(partnerId);
    const [defaultInitiativeData] = defaultInitiative; // Destructure the default initiative (first element)
    const defaultInitiativeId = defaultInitiativeData?.id;
    const defaultInitiativeName = defaultInitiativeData?.name;

    const [initiative, setInitiative] = useState('');
    const [ado, setAdo] = useState<string>('');
    const [year, setYear] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [sortType, setSortType] = useState<string>('');
    const [interactionType, setInteractionType] = useState<string>('');
    const [assistanceType, setAssistanceType] = useState<string>('');
    const [selectedRows, setSelectedRows] = useState<number[]>([]);
    const [loading, setLoading] = useState(true);

    const availableYears = calculateAvailableYears();
    const currFisYear = calculateFiscalYear(year); 

    // Load filters from localStorage when the component mounts
    useEffect(() => {
        const savedFilters = JSON.parse(localStorage.getItem('interactionFilters') || '{}');
        setInitiative(savedFilters.initiative || '');
        setYear(currFisYear.toString());
        setSearchTerm('');
        setSortType(savedFilters.sortType || '');
        setInteractionType(savedFilters.interactionType || '');
        setAssistanceType(savedFilters.assistanceType || '');
        setLoading(false);
    }, []);

    useDeepCompareEffect(() => {
        // fetchInteractions();
        // fetchInitiatives();
        setAdo(partnerName);
        console.log('re-render');
    }, [interactions])

    const { filteredInteractions } = useFilteredData(undefined, undefined, initiatives, interactions, searchTerm, sortType, year, ado, interactionType, initiative, assistanceType);

    const handleSort = (type: string) => setSortType(type);
    const filterByYear = (year: string) => setYear(year);
    const filterByAdo = (partner_id: string) => setAdo(partner_id);
    const handleSearch = (searchTerm: string) => setSearchTerm(searchTerm);
    const filterByInteractionType = (interactionType: string) => setInteractionType(interactionType);
    const filterByInitiative = (initiative: string) => setInitiative(initiative);
    const filterByAssistanceType = (assistanceType: string) => setAssistanceType(assistanceType);

    const handleBulkAction = () => {
        // Perform bulk action on selected rows
        console.log("Performing bulk action on selected rows:", selectedRows);
    };

    return ( 
        <div className="px-4 py-0 sm:py-4 md:py-4 lg:py-4">
            <ReportingDropdown/>
            <div className="mx-2 my-4 flex flex-wrap relative">
                <SearchInput onSearch={handleSearch}/>
                <BreSort handleSort={handleSort} currSortType={sortType} column="Business Name" dateType="(Descending: Newest First)"/>
                <FiscalYear filterByYear={filterByYear} availableYears={availableYears} currYear={year}/>
                { isCAIAdmin && // Render SelectADO only if in admin view
                    <SelectADO filterByAdo={filterByAdo} currAdo={ado} />
                }
                <InitiativeSort initiatives={initiatives} filterByInitiative={filterByInitiative} currInitiative={initiative}/>
                <InteractionType filterByInteractionType={filterByInteractionType} currInteractionType={interactionType}/>
                <AssistanceType filterByAssistanceType={filterByAssistanceType} currAssistanceType={assistanceType}/>
                <BulkActionBtn disabled={selectedRows.length === 0} handleBulkAction={handleBulkAction} selectedRows={selectedRows}/>
                {defaultInitiativeData && (
                        <div
                            className='flex items-center justify-center'
                            onClick={(e) => {
                                e.preventDefault(); // Prevent the link navigation
                            }}
                        >
                            <UploadInteractionsBtn id={defaultInitiativeId} initiative={defaultInitiativeName} initiativeData={defaultInitiativeData} />
                        </div>
                    )}
                {!isCAIAdmin && <AddInteractionButton/>}
                {isIframe && 
                    <div className="flex mb-1 absolute md:relative top-0 right-0 md:top-auto md:right-auto md:ml-auto transform md:transform-none">
                        <Expand/>
                    </div>
                }
            </div>
            {isCAIAdmin && <p className="text-sm ml-2 text-color-cai-red">As CAI Admin, add interactions through the Initiative page. This page temporarily only supports edit/delete.</p>}
            {!loading && year && filteredInteractions.length > 0 && 
            <div className="mx-2 my-4">
                <InteractionTable 
                    data={filteredInteractions} 
                    handleSelectRows={setSelectedRows}
                    initiative={initiative}
                    ado={ado}
                    year={year}
                    searchTerm={searchTerm}
                    sortType={sortType}
                    interactionType={interactionType}
                    assistanceType={assistanceType}
                />
            </div>
            }
        </div>
    );
}

export default Interactions;