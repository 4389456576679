import { useState, useEffect } from "react";
import { InitiativeData, InteractionData, OutcomeData, TargetsData } from "../services/types";
import { sanitizeString } from "../utils/helpers";

interface Totals {
    bre: number;
    recruitment: number;
    training: number;
    startup: number;
    export: number;
    marketing_plan_required: number;
    marketing_plan_received: number;
}

const useFilteredData = (
    targets?: TargetsData[],
    outcomes?: OutcomeData[],
    initiatives?: InitiativeData[],
    interactions?: InteractionData[],
    searchTerm: string = '',
    sortType: string = 'alphabetical',
    year: string = '',
    ado: string = '',
    interactionType: string = '',
    selectedInitiative: string = '',
    assistanceType: string = ''
) => {
    const [filteredTargets, setFilteredTargets] = useState<TargetsData[]>([]);
    const [filteredInitiatives, setFilteredInitiatives] = useState<InitiativeData[]>([]);
    const [filteredOutcomes, setFilteredOutcomes] = useState<OutcomeData[]>([]);
    const [filteredInteractions, setFilteredInteractions] = useState<InteractionData[]>([]);
    const [totalTargets, setTotalTargets] = useState<Totals>({
        bre: 0,
        recruitment: 0,
        training: 0,
        startup: 0,
        export: 0,
        marketing_plan_required: 0,
        marketing_plan_received: 0
    });
    const [totalInteractions, setTotalInteractions] = useState<Totals>({
        bre: 0,
        recruitment: 0,
        training: 0,
        startup: 0,
        export: 0,
        marketing_plan_required: 0,
        marketing_plan_received: 0
    })
    
    useEffect(() => {

        ///////////////
        // TARGET FILTERS:
        // Filter and calculate totals for targetsData
        ///////////////
        let filteredTargetsResult = targets ? [...targets] : [];

        if (searchTerm) {
            filteredTargetsResult = filteredTargetsResult.filter(target => {
                const targetAdo = target.partner_name ? sanitizeString(target.partner_name) : '';
                
                return targetAdo.includes(sanitizeString(searchTerm));
            });
        }

        if (ado) {
            const sanitizedAdo = sanitizeString(ado);
        
            if (sanitizedAdo !== 'admin' && sanitizeString(ado) !== 'cai-admin') {
                // Filter by partner_name if ado is not 'admin'
                filteredTargetsResult = filteredTargetsResult.filter(item => {
                    const targetAdo = item.partner_name ? sanitizeString(item.partner_name) : '';
                    return targetAdo === sanitizedAdo;
                });
            }
            // If ado is 'admin', do nothing; all targets are included
        }

        if (year) {
            filteredTargetsResult = filteredTargetsResult.filter(item =>
                item.year === year
            );
        }

        if (sortType === 'date') {
            filteredTargetsResult.sort((a, b) => b.id - a.id);
        } else {
            filteredTargetsResult.sort((a, b) => a.partner_name.localeCompare(b.partner_name));
        }

        const totalsTargets = filteredTargetsResult.reduce((acc, item) => {
            acc.bre += item.bre || 0;
            acc.recruitment += item.recruitment || 0;
            acc.training += item.training || 0;
            acc.startup += item.startup || 0;
            acc.export += item.export || 0;
            if (item.marketing_plan_required) {
                acc.marketing_plan_required += 1;
            }
            if (item.marketing_plan_received) {
                acc.marketing_plan_received += 1;
            }
            return acc;
        }, {
            bre: 0,
            recruitment: 0,
            training: 0,
            startup: 0,
            export: 0,
            marketing_plan_required: 0,
            marketing_plan_received: 0
        });

        setFilteredTargets(filteredTargetsResult);
        setTotalTargets(totalsTargets);

        ///////////////
        // INITIATIVE FILTERS:
        // Filter and calculate totals for initiatives
        ///////////////
        let filteredInitiativesResult = initiatives ? [...initiatives] : [];

        if (ado && initiatives) {
            if (sanitizeString(ado) !== 'admin' && sanitizeString(ado) !== 'cai-admin') {
                // Filter by partner_name if ado is not 'Admin'
                filteredInitiativesResult = filteredInitiativesResult.filter(item => {
                    const initiativeAdo = item.partner_name ? sanitizeString(item.partner_name) : '';
                    return initiativeAdo === sanitizeString(ado);
                });
            }
            // If ado is 'Admin', do nothing; all initiatives are included
        }

        if (searchTerm) {
            filteredInitiativesResult = filteredInitiativesResult.filter(initiative => {
                const initiativeName = initiative.name ? sanitizeString(initiative.name) : '';
                
                return initiativeName.includes(sanitizeString(searchTerm));
            });
        }

        if (sortType === 'date') {
            filteredInitiativesResult.sort((a, b) => b.id - a.id);
        } else {
            filteredInitiativesResult.sort((a, b) => {
                const nameA = a.name || ''; 
                const nameB = b.name || ''; 
                return nameA.localeCompare(nameB);
            });
        } 

        if (year) {
            filteredInitiativesResult = filteredInitiativesResult.filter(item =>
                item.year === year
            );
        }
        console.log('filteredInitiativesResult:', filteredInitiativesResult);
        setFilteredInitiatives(filteredInitiativesResult);

        ///////////////
        // OUTCOME FILTERS:
        // Filter and calculate totals for outcomes
        ///////////////
        let filteredOutcomesResult = outcomes ? [...outcomes] : [];

        if (ado && initiatives) {
            if (sanitizeString(ado) !== 'admin' && sanitizeString(ado) !== 'cai-admin') {
                // Find initiative_id corresponding to the selected ado
                const initiativeIds = initiatives
                    .filter(init => sanitizeString(init.partner_name) === sanitizeString(ado))
                    .map(init => init.id);
        
                filteredOutcomesResult = filteredOutcomesResult
                    .filter(item => initiativeIds.includes(Number(item.initiative_id)));
            }
            // If ado is 'Admin', do nothing; all outcomes are included
        }

        if (year) {
            filteredOutcomesResult = filteredOutcomesResult.filter(item =>
                item.year === year
            );
        }

        if (searchTerm && initiatives) {
            filteredOutcomesResult = filteredOutcomesResult.filter(outcome => {
                const initiative = initiatives.find(init => init.id === Number(outcome.initiative_id));
                const adoName = initiative ? initiative.partner_id : '';
                const initiativeName = initiative ? sanitizeString(initiative.name) : '';
                
                return (
                    adoName.toLowerCase().includes(sanitizeString(searchTerm)) ||
                    initiativeName.includes(sanitizeString(searchTerm))
                );
            });
        }
        console.log('filteredOutcomesResult:', filteredOutcomesResult);
        setFilteredOutcomes(filteredOutcomesResult);

        ///////////////
        // INTERACTION FILTERS:
        // Filter and calculate totals for interactions data
        ///////////////
        let filteredInteractionsResult = interactions ? [...interactions] : [];

        if (searchTerm && initiatives) {
            filteredInteractionsResult = filteredInteractionsResult.filter(interaction => {
                const initiative = initiatives.find(init => init.id === interaction.initiative_id);
                const businessName = interaction ? interaction.business_trade_name : '';
                const initiativeName = initiative ? sanitizeString(initiative.name) : '';
                
                return (
                    businessName.toLowerCase().includes(sanitizeString(searchTerm)) ||
                    initiativeName.includes(sanitizeString(searchTerm))
                );
            });
        }

        if (ado && initiatives) {
            if (sanitizeString(ado) !== 'admin' && sanitizeString(ado) !== 'cai-admin') {
                // Find initiative_id corresponding to the selected ado
                const initiativeIds = initiatives
                    .filter(init => sanitizeString(init.partner_name) === sanitizeString(ado))
                    .map(init => init.id.toString());
        
                filteredInteractionsResult = filteredInteractionsResult
                    .filter(item => initiativeIds.includes(item.initiative_id.toString()));
            }
            // If ado is 'Admin', do nothing; all interactions are included
        }

        if (year) {
            // Convert the year to a number for comparison
            const fiscalYear = Number(year);
            
            // Calculate the start and end date of the fiscal year
            const fiscalYearStart = new Date(`${fiscalYear - 1}-07-01`); // Start of fiscal year (July 1st of previous year)
            const fiscalYearEnd = new Date(`${fiscalYear}-06-30`); // End of fiscal year (June 30th of current year)
            
            // Filter interactions by fiscal year
            filteredInteractionsResult = filteredInteractionsResult.filter(item => {
                const interactionDate = new Date(item.interaction_date);
                
                // Check if the interaction date is within the fiscal year range
                return interactionDate >= fiscalYearStart && interactionDate <= fiscalYearEnd;
            });
        }

        if (sortType === 'date') {
            filteredInteractionsResult.sort((a, b) => {
                // Convert date strings to Date objects
                const dateA = new Date(a.interaction_date);
                const dateB = new Date(b.interaction_date);
        
                // Compare dates
                return dateB.getTime() - dateA.getTime();
            });
        } else {
            filteredInteractionsResult.sort((a, b) => {
                if (!a.business_legal_name) return 1;
                if (!b.business_legal_name) return -1;
                return a.business_legal_name.localeCompare(b.business_legal_name);
            });
        } 

        if (selectedInitiative) {
            filteredInteractionsResult = filteredInteractionsResult.filter(item => item.initiative_id == Number(selectedInitiative));
        }

        if (interactionType) {
            filteredInteractionsResult = filteredInteractionsResult.filter(item => item.interaction_type.id === Number(interactionType));
        }

        if (assistanceType) {
            console.log('assistance type:', assistanceType);
            filteredInteractionsResult = filteredInteractionsResult.filter(item =>
                item.assistance_types.some(assistance => assistance.id === assistanceType)
            );
        }

        const totalsInteractions = filteredInteractionsResult.reduce((acc, item) => {

            switch (item.interaction_type.id) {
                case 1:
                    acc.bre += 1;
                    break;
                case 2:
                    acc.recruitment += 1;
                    break;
                case 3:
                    acc.startup += 1;
                    break;
                case 4:
                    acc.export += 1;
                    break;
                case 5:
                    acc.training += 1;
                    break;
                default:
                    break;
            }

        //     // Increment count for the interaction type
        //     acc[item.interaction_type] += 1;

            return acc;
        }, {
            bre: 0,
            recruitment: 0,
            training: 0,
            startup: 0,
            export: 0,
            marketing_plan_required: 0,
            marketing_plan_received: 0
        });

        console.log('filteredInteractionsResult:', filteredInteractionsResult);
        setFilteredInteractions(filteredInteractionsResult);
        setTotalInteractions(totalsInteractions);

    }, [targets, outcomes, initiatives, interactions, searchTerm, sortType, year, ado, interactionType, selectedInitiative, assistanceType]);

    return { filteredTargets, totalTargets, filteredInitiatives, filteredOutcomes, filteredInteractions, totalInteractions };
};

export default useFilteredData;
