import { useContext, useRef } from "react";
import { Chart } from "chart.js/auto";
import useDeepCompareEffect from "../../hooks/useDeepCompareEffect";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import TypesContext from "../../context/TypesContext";
import { IndustryType } from "../../services/types";
import { noDataPlugin } from "../../utils/chartPlugins";

interface Industry {
    code: string;
    name: string;
    category: string;
    value: string;
}

interface InteractionSummaryData {
    total_interactions: string;
    industries: Industry[];
}

interface ActivitiesBySectorChartProps {
    interactionSummary: InteractionSummaryData | null;
}

function ActivitiesBySectorChart({ interactionSummary }: ActivitiesBySectorChartProps) {
    // Register the plugin to all charts:
    Chart.register(ChartDataLabels, noDataPlugin);
    const chartRef = useRef<HTMLCanvasElement>(null);
    const chartInstance = useRef<Chart<"bar"> | null>(null);

    const { typesData } = useContext(TypesContext);
    const industryTypes = typesData.industry_types;

    useDeepCompareEffect(() => {
        if (!interactionSummary) return;

        const { industries } = interactionSummary;

        // Function to get unique categories
        const getUniqueCategories = (data: IndustryType[]): string[] => {
            const categories = data.map(industry => industry.category);
            return Array.from(new Set(categories));
        };

        // Set unique categories based on industry_types
        const uniqueCategories = getUniqueCategories(industryTypes);

        // Initialize sectorTotals with unique categories
        const sectorTotals: { [key: string]: number } = {};
        uniqueCategories.forEach(category => {
            sectorTotals[category] = 0;
        });

        // Calculate totals for each category
        industries.forEach(industry => {
            const category = industry.category;
            if (sectorTotals.hasOwnProperty(category)) {
                sectorTotals[category] += parseInt(industry.value, 10); // Use += to accumulate values
            }
        });

        // Prepare data for Chart.js
        const labels = Object.keys(sectorTotals);
        const data = Object.values(sectorTotals);

        // Chart.js configuration
        const ctx = chartRef.current?.getContext("2d");
        if (ctx) {

            // Destroy existing chart instance if it exists
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }

            chartInstance.current = new Chart(ctx, {
                type: "bar",
                data: {
                    labels: labels,
                    datasets: [
                        {
                            label: "Activities by Sector",
                            data: data,
                            backgroundColor: '#626262',
                            borderRadius: 3,
                        },
                    ],
                },
                options: {
                    responsive: true,
                    indexAxis: 'y',
                    plugins: {
                        legend: {
                            display: false,
                        },
                        datalabels: {
                            anchor: 'end',
                            align: 'start',
                            // formatter:(value) => value ? `${value.toFixed(0)}%` : '',
                            font: {
                                size: 10,
                            },
                            offset: -16,
                            formatter: (value) => value !== 0 ? value : null // Hide label if value is 0
                        },
                    },
                    scales: {
                        x: {
                            beginAtZero: true,
                            ticks: {
                                precision: 0,
                                font: {
                                    size: 10
                                },
                                padding: 10
                            },
                            grid: {
                                tickBorderDash: [2, 4]
                            },
                            border: {
                                display: false,
                                dash: [2,4]
                            }
                        },
                        y: {
                            grid: {
                                display: false,
                                drawTicks: true,
                            },
                            border: {
                                display: false
                            },
                            ticks: {
                                autoSkip: false,  // Prevent labels from being skipped
                                minRotation: 0,  // Ensure labels are parallel to y-axis
                                maxRotation: 0,  // Ensure labels are parallel to y-axis
                                font: {
                                    size: 10
                                },
                            },
                        },
                    },
                },
                plugins: [noDataPlugin]
            });
        }
    }, [interactionSummary]);

    return ( 
        <div className="text-color-cai-gray text-sm font-bold w-full h-84 min-w-lg max-w-lg md:w-1/2 border border-solid border-lightGray rounded-md appearance-none text-left truncate">
            <h2 className="text-color-cai-gray text-base font-bold my-2 px-3 ">Activities by Sector</h2>
            <div className="flex items-center border-t">
            </div>
            <canvas ref={chartRef} className="max-h-72 max-w-96 mx-8 mt-12"/>
        </div>
     );
}

export default ActivitiesBySectorChart;