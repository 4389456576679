// import { useState } from "react";
import LandingCard from "../../components/LandingCard";
import targetsSvg from "../../assets/target.svg";
import initiativesSvg from "../../assets/initiatives.svg";
import interactionsSvg from "../../assets/interactions.svg";
import summarySvg from "../../assets/summary.svg";
// import surveySvg from "../../assets/survey.svg";
import ReportingDropdown from "../../components/BreDropdown";
import { useAdminContext } from "../../context/AdminContext";
import Expand from "../../components/Expand";
import { useIframeContext } from "../../context/IframeContext";
import { useOrganizationContext } from "../../context/OrganizationContext";
import LogoutButton from "../../components/Auth/Logout";
import { useCAIAdminContext } from "../../context/CAIAdminContext";

function Landing () {
    const { isCAIAdmin } = useCAIAdminContext();
    const { isAdmin } = useAdminContext();
    const { partnerId, partnerName } = useOrganizationContext();
    const { isIframe } = useIframeContext();

    console.log('org id:', partnerId, 'org name:', partnerName);
    
    return (
        <div className="px-4 py-0 sm:py-4 md:py-4 lg:py-4">
            <ReportingDropdown/>
            {isIframe &&
                <div className="flex justify-between items-center">
                    <h2 className="font-inter font-semibold text-xl text-left text-color-cai-main ml-2 my-4">ADO Reporting</h2>  
                    <Expand/>
                </div>
            }
            <div className="flex justify-between items-start">
                <p className="text-left text-sm font-inter text-color-cai-main ml-2 text-wrap w-full sm:w-[45%]">Our application is a comprehensive tool for businesses to track and report their interactions with clients, partners, and vendors. It streamlines the process of managing these interactions and provides valuable insights for better decision making.</p>
                {!isIframe && 
                    <div className="flex justify-end items-center mr-10">
                        <LogoutButton/>
                    </div>
                }
            </div>

            {/* Conditional rendering based on viewType */}
            {isAdmin || isCAIAdmin ? (
                <div className="flex flex-col flex-wrap xl:flex-row gap-4 p-2 w-full">
                    <LandingCard
                        header="Targets"
                        text="Create and manage program achievement targets for  business engagement activities. Align with partner organizations by setting measurable goals and tracking progress to ensure successful outcomes."
                        svgUrl={targetsSvg}
                        linkTo="/commerce-targets"
                        height="h-48"
                    />
                    {/* Show Interactions if CAI Admin */}
                    {isCAIAdmin && (
                        <LandingCard
                            header="Interactions"
                            text="Report, manage, and organize your business interactions. Add interactions individually or upload them in bulk, with the ability to edit and structure your existing interaction records for better visibility and management."
                            svgUrl={interactionsSvg}
                            linkTo="/commerce-interactions"
                            height="h-48"
                        />
                    )}
                    <LandingCard
                        header="Initiatives"
                        text="Create and manage initiatives to categorize and group your business interaction activity. Add or upload interactions to specific initiatives, track progress, and view outcome reports to monitor business engagement activities more effectively."
                        svgUrl={initiativesSvg}
                        linkTo="/commerce-initiatives"
                        height="h-48"
                    />
                    <LandingCard
                        header="Dashboard & Reporting"
                        text="View progress of your business engagement achievement, access summarized details on interaction activities and outcome reports. Download outcome reports to assess impact from engagement activities."
                        svgUrl={summarySvg}
                        linkTo="/commerce-summary"
                        height="h-48"
                    />
                    {/* <LandingCard
                        header="Surveys"
                        text="Indicators related to creation, retention, transfer, and accumulation of wealth within a community."
                        svgUrl={surveySvg}
                        linkTo="/commerce-surveys"
                        height="h-48"
                    /> */}
                </div>
            ) : (
                <div className="flex flex-col flex-wrap xl:flex-row gap-4 p-2 w-full">
                    <LandingCard
                        header="Interactions"
                        text="Report, manage, and organize your business interactions. Add interactions individually or upload them in bulk, with the ability to edit and structure your existing interaction records for better visibility and management."
                        svgUrl={interactionsSvg}
                        linkTo="/commerce-interactions"
                        height="h-48"
                    />
                    <LandingCard
                        header="Initiatives"
                        text="Create and manage initiatives to categorize and group your business interaction activity. Add or upload interactions to specific initiatives, track progress, and view outcome reports to monitor business engagement activities more effectively."
                        svgUrl={initiativesSvg}
                        linkTo="/commerce-initiatives"
                        height="h-48"
                    />
                    <LandingCard
                        header="Dashboard & Reporting"
                        text="View progress of your business engagement achievement, access summarized details on interaction activities and outcome reports. Download outcome reports to assess impact from engagement activities."
                        svgUrl={summarySvg}
                        linkTo="/commerce-summary"
                        height="h-48"
                    />
                    {/* <LandingCard
                        header="Surveys"
                        text="Indicators related to creation, retention, transfer, and accumulation of wealth within a community."
                        svgUrl={surveySvg}
                        linkTo="/commerce-surveys"
                        height="h-40"
                    /> */}
                    {/* Add ADO specific cards or components here */}
                </div>
            )}
        </div>
    );
}

export default Landing ;