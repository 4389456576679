import { useContext, useEffect, useState } from 'react';
import SearchInput from "../../components/SearchInput";
import ReportingDropdown from "../../components/BreDropdown";
import BreSort from "../../components/BreSort";
import SelectADO from "../../components/SelectADO";
import FiscalYear from "../../components/FiscalYear";
import NewTargetButton from "../../components/Targets/NewTargetButton";
import TargetsTable from '../../components/Targets/TargetsTable';
import { calculateAvailableYears, calculateFiscalYear } from '../../utils/helpers';
import TargetsDataContext from '../../context/TargetDataContext';
import useFilteredData from '../../hooks/useFilteredData';
import Expand from '../../components/Expand';
import { useIframeContext } from '../../context/IframeContext';

function Targets() {
    const { isIframe } = useIframeContext();
    const [ado, setAdo] = useState<string>('');
    const [year, setYear] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [sortType, setSortType] = useState<string>('');
    const { targets } = useContext(TargetsDataContext);
    const availableYears = calculateAvailableYears();
    const currFisYear = calculateFiscalYear(year); 

    // Load filters from localStorage when the component mounts
    useEffect(() => {
        const savedFilters = JSON.parse(localStorage.getItem('targetFilters') || '{}');
        setYear(savedFilters.year || currFisYear.toString());
        setSearchTerm('');
        setAdo(savedFilters.ado || '');
        setSortType(savedFilters.sortType || '');
    }, []);

    const { filteredTargets } = useFilteredData(targets, undefined, undefined, undefined, searchTerm, sortType, year, ado);

    const handleSort = (type: string) => setSortType(type);
    const filterByYear = (year: string) => setYear(year);
    const filterByAdo = (partner_id: string) => setAdo(partner_id);
    const handleSearch = (searchTerm: string) => setSearchTerm(searchTerm);
    
    return ( 
        <div className="px-4 py-0 sm:py-4 md:py-4 lg:py-4">
            <ReportingDropdown/>
            <div className="mx-2 my-4 flex flex-wrap relative">
                <SearchInput onSearch={handleSearch}/>
                <BreSort handleSort={handleSort} currSortType={sortType} column='ADO' dateType='(Most Recent)'/>
                <SelectADO currAdo={ado} filterByAdo={filterByAdo}/>
                <FiscalYear filterByYear={filterByYear} availableYears={availableYears} currYear={year}/>
                <NewTargetButton/>
                {isIframe && 
                <div className="flex mb-1 absolute md:relative top-0 right-0 md:top-auto md:right-auto md:ml-auto transform md:transform-none">
                    <Expand/>
                </div>
                }
            </div>
            {filteredTargets.length > 0 && 
            <div className="mx-2 my-4">
                <TargetsTable 
                    data={filteredTargets}
                    ado={ado}
                    year={year}
                    searchTerm={searchTerm}
                    sortType={sortType}
                />    
            </div>
            }
        </div>
    );
}

export default Targets;
