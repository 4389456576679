import { useNavigate } from "react-router-dom";

function AddInitiativeButton() {
    const navigate = useNavigate();
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        navigate('/commerce-initiatives/addinitiative');
        console.log('button clicked:', event);
    };

    return ( 
        <span className="flex items-center sm:mt-0 mt-2">
        <button
            id="add-initiative"
            onClick={handleClick}
            className="w-36 px-4 text-white font-bold text-sm bg-color-cai-dashboard border border-solid border-color-cai-dashboard rounded-md focus:outline-none focus:border-blue-500 h-8 appearance-none text-center"
        > + Add Initiative
        </button>
        </span>
    );
}

export default AddInitiativeButton;