import { useState, useEffect } from 'react';
import '../App.css'
import '../index.css'
import Select from 'react-select';

interface FiscalYearProps {
    filterByYear: (year: string) => void;
    availableYears: string[];
    currYear: string;
}

function FiscalYear({ filterByYear, availableYears, currYear }: FiscalYearProps) {
    const [fisYear, setFisYear] = useState(currYear || '');
    const yearOptions = availableYears.map(year => ({value: year, label: year}));

    useEffect(() => {
        setFisYear(currYear);
    }, [currYear]);

    const handleSelectChange = (selectedOption: { value: string; label: string } | null) => {
        const selectedYear = selectedOption ? selectedOption.value : '';
        console.log('selected year:', selectedYear);

        setFisYear(selectedYear);
        filterByYear(selectedYear);
    };


    return ( 
        <div className='sm:mt-0 mt-2'>
            <Select
                options={yearOptions}
                value={yearOptions.find(option => option.value === fisYear)}
                onChange={handleSelectChange}
                placeholder="Fiscal Year"
                isClearable={false}
                isSearchable={false}
                styles={{
                    control: (base) => ({
                        ...base,
                        height: '2rem',
                        minHeight: '2rem',
                        width: '8rem',
                        borderColor: '#E5E7EB',
                        borderRadius: '4px',
                        marginRight: '1rem',
                        boxShadow: 'none',
                        cursor: 'pointer',
                    }),
                    valueContainer: (base) => ({
                        ...base,
                        padding: '0 0.5rem',
                        minHeight: '2rem',
                        height: '2rem',
                    }),
                    placeholder: (base) => ({
                        ...base,
                        color: '#3A3C41', // 'cai-color-dashboard'
                        fontSize: '0.875rem',
                        fontWeight: 'bold',
                    }),
                    singleValue: (base) => ({
                        ...base,
                        color: '#3A3C41',
                        fontSize: '0.875rem',
                        fontWeight: 'bold'
                    }),
                    dropdownIndicator: (base) => ({
                        ...base,
                        padding: '0',
                    }),
                    clearIndicator: (base) => ({
                        ...base,
                        padding: '0',
                    }),
                    indicatorsContainer: (base) => ({
                        ...base,
                        padding: '0 0.25rem',
                    }),
                    // indicatorSeparator: (base) => ({
                    //     ...base,
                    //     display: 'none'
                    // }),
                    option: (base, state) => ({
                        ...base,
                        fontSize: '0.875rem',
                        backgroundColor: state.isSelected ? '#626262' : state.isFocused ? '#CCCCCC' : 'white',
                        borderRadius: '4px',
                        borderBottom: '1px solid #E2E8F0',
                        color: state.isSelected ? 'white' : '#3A3C41',
                        '&:hover': {
                            backgroundColor: '#CCCCCC', // cai-lightGray
                        },
                    }),
                    menu: (base) => ({
                        ...base,
                        position: 'absolute',
                        left: '0',
                        top: '1.5rem',
                        width: '8rem',
                        zIndex: 9999,
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                    }),
                    menuList: (base) => ({
                        ...base,
                        padding: '4px',
                        maxHeight: '200px', // Adjust height as needed
                        overflowY: 'auto',
                        '::-webkit-scrollbar': {
                            width: '8px',
                        },
                        '::-webkit-scrollbar-track': {
                            background: '#ffffff',
                            borderRadius: '4px',
                        },
                        '::-webkit-scrollbar-thumb': {
                            background: '#E8E8E8',
                            borderRadius: '4px',
                            height: '50px',
                            padding: '10px'
                        },
                    }),
                }}
            />
        </div>
    );
}

export default FiscalYear;


// Legacy span element //
        
        // <span className="flex items-center h-12 relative">
        //     {fisYear && (<button
        //         className="absolute right-auto top-10 p-1 text-xs text-gray-400 outline-none focus:outline-none"
        //         onClick={clearSelection}
        //     >
        //         Clear Selection
        //     </button>)}
            
        //     <select
        //         id="fiscal-year"
        //         // value={'test'}
        //         value={fisYear}
        //         onChange={handleSelectChange}
        //         className="text-color-cai-gray text-sm font-bold px-3 w-32 border border-solid border-lightGray rounded-[4px] focus:outline-none focus:border-blue-500 h-8 appearance-none text-left hover:cursor-pointer"
        //     >
        //         <option value="" disabled className="text-color-cai-gray text-sm ">Fiscal Year</option>
        //         {availableYears.map(year => (
        //                 <option className='custom-option' key={year} value={year}>{year}</option>
        //             ))}
        //     </select>
        //     <img src={carrot} alt="Dropdown Carrot" className="relative h-4 w-4 top-0 right-7 pointer-events-none" />
        // </span>