import { useState, useEffect, useContext } from 'react';
import Select from 'react-select';
import { getTypeOptions } from '../../utils/formOptions';
import TypesContext from '../../context/TypesContext';

interface AssistanceTypeProps {
    filterByAssistanceType: (assistanceType: string) => void;
    currAssistanceType: string;
}

function AssistanceType({ filterByAssistanceType, currAssistanceType }: AssistanceTypeProps) {
    const { typesData } = useContext(TypesContext);
    const [assistance, setAssistance] = useState(currAssistanceType || '');
    const assistanceTypeOptions = getTypeOptions(typesData, 'assistance_types', assistance);
    
    useEffect(() => {
        setAssistance(currAssistanceType);
    }, [currAssistanceType]);

    const handleSelectChange = (selectedOption: { value: string; label: string } | null) => {
        const selectedAssistanceType = selectedOption ? selectedOption.value : '';
        setAssistance(selectedAssistanceType);
        filterByAssistanceType(selectedAssistanceType);
    };

    return ( 
        <div className="mt-2 sm:mt-0">
            <Select
                options={assistanceTypeOptions}
                value={assistanceTypeOptions.find((option: any) => option.value === assistance)}
                onChange={handleSelectChange}
                placeholder="Assistance Type"
                isClearable
                styles={{
                    control: (base) => ({
                        ...base,
                        height: '2rem',
                        minHeight: '2rem',
                        width: '10.5rem',
                        borderColor: '#E5E7EB',
                        borderRadius: '4px',
                        boxShadow: 'none',
                        marginRight: '1rem',
                        cursor: 'pointer',
                    }),
                    valueContainer: (base) => ({
                        ...base,
                        padding: '0 0.5rem',
                        minHeight: '2rem',
                        height: '2rem',
                    }),
                    placeholder: (base) => ({
                        ...base,
                        color: '#3A3C41',
                        fontSize: '0.875rem',
                        fontWeight: 'bold',
                    }),
                    singleValue: (base) => ({
                        ...base,
                        color: '#3A3C41',
                        fontSize: '0.875rem',
                    }),
                    dropdownIndicator: (base) => ({
                        ...base,
                        padding: '0',
                    }),
                    clearIndicator: (base) => ({
                        ...base,
                        padding: '0',
                    }),
                    indicatorsContainer: (base) => ({
                        ...base,
                        padding: '0 0.25rem',
                    }),
                    option: (base, state) => ({
                        ...base,
                        fontSize: '0.875rem',
                        backgroundColor: state.isSelected ? '#626262' : state.isFocused ? '#CCCCCC' : 'white',
                        borderRadius: '4px',
                        borderBottom: '1px solid #E2E8F0',
                        color: state.isSelected ? 'white' : '#3A3C41',
                        '&:hover': {
                            backgroundColor: '#CCCCCC',
                        },
                    }),
                    menu: (base) => ({
                        ...base,
                        width: '10.5rem',
                        position: 'absolute',
                        left: '0',
                        top: '1.5rem',
                        zIndex: 9999,
                        boxShadow: '0 8px 16px 0 rgba(0, 0, 0, 0.15)',
                    }),
                    menuList: (base) => ({
                        ...base,
                        padding: '4px',
                        maxHeight: '200px', // Adjust height as needed
                        overflowY: 'auto',
                        '::-webkit-scrollbar': {
                            width: '8px',
                        },
                        '::-webkit-scrollbar-track': {
                            background: '#ffffff',
                            borderRadius: '4px',
                        },
                        '::-webkit-scrollbar-thumb': {
                            background: '#E8E8E8',
                            borderRadius: '4px',
                        },
                    }),
                }}
            />
        </div>
    );
}

export default AssistanceType;

// Legacy select element

// <span className="relative flex items-center h-12">
        //     {assistance && (<button
        //         className="absolute right-auto top-10 p-1 text-xs text-gray-400 outline-none focus:outline-none"
        //         onClick={clearSelection}
        //     >
        //         Clear Selection
        //     </button>)}
        //     <select
        //         id="assistanceType"
        //         // value={'test'}
        //         value={assistance}
        //         onChange={handleSelectChange}
        //         className="text-color-cai-gray text-sm font-bold pl-3 pr-6 w-40 border border-solid border-lightGray rounded-md focus:outline-none focus:border-blue-500 h-8 appearance-none text-left hover:cursor-pointer truncate"
        //     >
        //         <option value="" disabled className="text-color-cai-gray text-sm">Assistance Type</option>
        //         {/* Hardcode year for now, refactor to add dynamic year from db/api */}
        //         <option value="access_cap">Access to Capital</option>
        //         <option value="expansion_rel">Expansion/Relocation</option>
        //         <option value="export_assistance">Export Assistance</option>
        //         <option value="flex_fund">Flex Fund</option>
        //         <option value="infrastructure">Infrastructure</option>
        //         <option value="oria">ORIA</option>
        //         <option value="site_selection">Site Selection</option>
        //         <option value="sbdc">SBDC</option>
        //         <option value="supply_chain">Supply Chain Issues</option>
        //         <option value="workforce">Workforce Development</option>
        //         <option value="other">Other</option>
        //     </select>
        //     <img src={carrot} alt="Dropdown Carrot" className="relative h-4 w-4 top-0 right-7 pointer-events-none" />
        // </span>