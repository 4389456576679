import targetSvg from '../../assets/summary-target.svg';
import { addCommas } from "../../utils/helpers";

interface SummaryCardProps {
    title: string;
    number: number;
    target: number
};

const SummaryCard = ({ title, number, target }: SummaryCardProps) => {
    // Calculate progress percentage
    const percentage = target !== 0 ? (number / target) * 100 : 0;

    return (
        <div className="text-color-cai-gray text-sm font-bold w-full min-w-[11.5rem] max-w-[11.5rem] md:w-1/2 h-28 px-3 border border-solid border-lightGray rounded-md appearance-none text-left truncate">
            <h2 className="text-color-cai-gray text-base font-bold mt-2">{title}</h2>
            <div className="flex items-center">
                <h2 className="text-color-cai-gray text-2xl mr-2 font-bold">{addCommas(number)}</h2>
                <img src={targetSvg} alt='target icon' className="ml-1 w-5 h-5 text-blue-500" />
                <p className="text-color-cai-gray text-base font-normal">{addCommas(target)}</p>
            </div>
            <div className="flex items-center mt-2 gap-2">
                <div className="relative flex-grow h-2 bg-color-cai-lightGray rounded-md">
                    <div
                        className="h-full rounded-md"
                        style={{
                            width: `${Math.min(percentage, 100)}%`,
                            backgroundImage: 'linear-gradient(270deg, #0F7C0F 0%, #1FAB1F 100.52%)',
                        }}
                    ></div>
                </div>
                <p className="text-sm text-color-cai-gray font-normal">{`${percentage.toFixed(0)}%`}</p>
            </div>
        </div>
    );
};

export default SummaryCard;
