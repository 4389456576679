import { useLocation, useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import Expand from '../../components/Expand';
import { useContext, useState } from 'react';
import deleteSvg from '../../assets/delete-white.svg';
import { useIframeContext } from '../../context/IframeContext';
import TargetsDataContext from '../../context/TargetDataContext';


function DeleteTarget() {
    const { isIframe } = useIframeContext();
    const { fetchTargets } = useContext(TargetsDataContext);
    const [deleteStatus, setDeleteStatus] = useState('');
    const [showImage, setShowImage] = useState(false);
    const [showContinueButton, setShowContinueButton] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const { id, partnerId } = location.state;


    // Handler for navigating back to Targets page
    const navigateBack = () => {
        navigate(-1);
    };

    const handleClose = () => {
        fetchTargets();
        navigate('/commerce-targets');
    }

    const handleNext = async () => {
        console.log('target and partner id:', id, partnerId);

        try {
            const response = await fetch(`https://backend-cai-commerce.vercel.app/api/deletetarget?partnerId=${partnerId}&targetId=${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
    
            if (!response.ok) {
                const responseData = await response.json();
                throw new Error(responseData.error || 'Failed to delete Target');
            }
            console.log(`Target with ID ${id} successfully deleted`);
            setDeleteStatus('Target deleted successfully');
            setShowImage(true);
            setShowContinueButton(true);
        } catch (error) {
            // Log the caught error to Sentry
            Sentry.captureException(error);
            console.error('Error deleting data from backend:', error);
        }
    };
    

    return (
        <div className="m-2 p-4">
            {/* Expand button */}
            {isIframe && 
            <div className='flex justify-end'>
                <div className="flex ml-auto mb-4">
                    <Expand/>
                </div>
            </div>
            }

            {/* Delete Target */}
            <div className="mb-8 border border-solid border-color-tableGray rounded-md">
                <div className='border-b border-solid border-color-tableGray mb-5'>
                    <h2 className="font-semibold mt-3 mb-3 ml-7">Delete Target</h2>
                </div>
                <div className='flex ml-4 sm:ml-8 h-20 items-center'>
                    {showImage && 
                        <img src={deleteSvg} alt="Deleted Successfully" className="p-2 mr-4 h-9 w-10 border border-red-500 bg-red-500 rounded-full" />
                    }
                    <p className='font-semibold'>
                        {deleteStatus || 'Are you sure that you want to permanently delete the target?'}
                    </p>
                </div>
                <div className='border-t border-solid border-color-tableGray mt-5 mb-2'>
                    {!showImage && (
                        <button
                            id="next"
                            onClick={handleNext}
                            className="ml-7 mt-5 mb-3 w-36 h-9 text-center text-white bg-color-cai-dashboard border border-solid border-color-cai-dashboard rounded-md focus:outline-none focus:border-blue-400 hover:bg-blue-400 hover:border-blue-500"
                        >
                            Next
                        </button>
                    )}
                    {!showImage && (
                        <button
                            id="cancel"
                            onClick={navigateBack}
                            className="ml-7 mt-5 mb-3 w-36 h-9 text-center text-color-cai-dashboard bg-white border border-solid border-color-cai-dashboard rounded-md focus:outline-none focus:border-red-500 hover:bg-red-400 hover:border-red-500 hover:text-white"
                        >
                            Cancel
                        </button>
                    )}
                    {showContinueButton && (
                        <button
                            id="close"
                            onClick={handleClose}
                            className="ml-7 mt-5 mb-3 w-36 h-9 text-center text-white bg-color-cai-dashboard border border-solid border-color-cai-dashboard rounded-md focus:outline-none focus:border-blue-400 hover:bg-blue-400 hover:border-blue-500"
                        >
                            Close
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DeleteTarget;