import { useContext, useEffect, useMemo, useState } from "react";
import { calculateAvailableYears, calculateFiscalYear, sanitizeString } from "../../utils/helpers";
import SearchInput from "../../components/SearchInput";
import ReportingDropdown from "../../components/BreDropdown";
import SelectADO from "../../components/SelectADO";
import FiscalYear from "../../components/FiscalYear";
import OutcomesContext from "../../context/OutcomeDataContext";
import SummaryOutcomesTable from "../../components/Summary/SummaryOutcomeTable";
import InitiativeDataContext from "../../context/InitiativeDataContext";
import SummaryCard from "../../components/Summary/SummaryCard";
import useFilteredData from "../../hooks/useFilteredData";
import InteractionDataContext from "../../context/InteractionDataContext";
import completeSvg from '../../assets/circle-check-filled.svg';
import ActivitiesBySectorChart from "../../components/Summary/ActivitiesBySectorChart";
import InteractionsByCategoryChart from "../../components/Summary/InteractionsByCategoryChart";
import Expand from "../../components/Expand";
import { useAdminContext } from "../../context/AdminContext";
import DownloadButton from "../../components/Summary/DownloadButton";
import { useIframeContext } from "../../context/IframeContext";
import TargetsDataContext from "../../context/TargetDataContext";
import { useOrganizationContext } from "../../context/OrganizationContext";
import { useFetchInteractionSummaryData } from "../../hooks/useFetchInteractionSummaryData";
import AdoDataContext from "../../context/AdoDataContext";
import { InteractionSummaryData } from "../../services/types";
import { useCAIAdminContext } from "../../context/CAIAdminContext";

function Surveys() {
    const { isCAIAdmin } = useCAIAdminContext();
    const { isAdmin } = useAdminContext();
    const { isIframe } = useIframeContext();
    const { partnerName } = useOrganizationContext();

    const { partnerData } = useContext(AdoDataContext);
    const { initiatives } = useContext(InitiativeDataContext);
    const { outcomes } = useContext(OutcomesContext);
    const { interactions } = useContext(InteractionDataContext);
    const { targets } = useContext(TargetsDataContext);

    const [ado, setAdo] = useState<string>('');
    const [partnerId, setPartnerId] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [sortType, setSortType] = useState<string>('');
    const [year, setYear] = useState<string>('');

    const { filteredOutcomes, totalTargets, filteredInteractions, filteredInitiatives } = useFilteredData(targets, outcomes, initiatives, interactions, searchTerm, sortType, year, ado);
    

    const availableYears = calculateAvailableYears();
    const currFisYear = calculateFiscalYear(year); 

    // console.log('re-rendering...')

    useEffect(() => {
        setSortType('alphabetical');
        setYear(currFisYear.toString());
    }, []);

    useEffect(() => {
        if (!isAdmin) {
            setAdo(partnerName);  // Set ado if not admin
        }
    }, [isAdmin, isCAIAdmin, partnerName]);

    useEffect(() => {
        if (ado) {
            const partner = partnerData.find((p) => sanitizeString(p.name) === sanitizeString(ado));
            if (partner) {
                setPartnerId(partner.id.toString());
            } else {
                setPartnerId(''); // Reset partnerId if ado doesn't match any name
            }
        } else {
            setPartnerId(''); // Reset partnerId if ado is empty or undefined
        }
    }, [ado, partnerData]);

    const { interactionSummary } = useFetchInteractionSummaryData(year, partnerId);
    const totalInteractions = useMemo(() => calculateInteractionTotals(interactionSummary), [interactionSummary]);

    // const handleSort = (type: string) => setSortType(type);
    const filterByYear = (year: string) => setYear(year);
    const filterByAdo = (partner_id: string) => setAdo(partner_id);
    const handleSearch = (searchTerm: string) => setSearchTerm(searchTerm);


    function calculateInteractionTotals(interactionSummary: InteractionSummaryData | null) {
        const initialTotals = {
            bre: 0,
            recruitment: 0,
            training: 0,
            startup: 0,
            export: 0,
            marketing_plan_required: 0,
            marketing_plan_received: 0
        };
    
        if (!interactionSummary) return initialTotals;
    
        return interactionSummary.types.reduce((acc, type) => {
            switch (type.id) {
                case 1:
                    acc.bre = parseInt(type.value);
                    break;
                case 2:
                    acc.recruitment = parseInt(type.value);
                    break;
                case 3:
                    acc.startup = parseInt(type.value);
                    break;
                case 4:
                    acc.export = parseInt(type.value);
                    break;
                case 5:
                    acc.training = parseInt(type.value);
                    break;
                // Add cases for other interaction types as needed
                default:
                    break;
            }
            return acc;
        }, initialTotals);
    }


    return ( 
        <div className="m-2 p-2">
            <ReportingDropdown/>
            <div className="mx-2 my-4 flex flex-wrap">
                {(isAdmin || isCAIAdmin) && <SelectADO currAdo={ado} filterByAdo={filterByAdo}/>}
                <FiscalYear filterByYear={filterByYear} availableYears={availableYears} currYear={year}/>
                {isIframe && 
                <div className="flex ml-auto mb-1">
                    <Expand/>
                </div>
                }
            </div>
            <div className="flex flex-wrap xl:flex-nowrap gap-4 mx-2 my-4">
                <div className="flex flex-col gap-4">
                    <div className="flex gap-4">
                        <SummaryCard title="BRE" number={totalInteractions.bre} target={totalTargets.bre} />
                        <SummaryCard title="Recruitment" number={totalInteractions.recruitment} target={totalTargets.recruitment} />
                    </div>
                    <div className="flex gap-4">
                        <SummaryCard title="Training" number={totalInteractions.training} target={totalTargets.training} />
                        <SummaryCard title="Startup Support" number={totalInteractions.startup} target={totalTargets.startup} />
                    </div>
                    <div className="flex gap-4">
                        <SummaryCard title="Export Assistance" number={totalInteractions.export} target={totalTargets.export} />
                        <div className="text-color-cai-gray text-sm font-bold w-full max-w-[11.5rem] md:w-1/2 h-28 px-3 border border-solid border-lightGray rounded-md appearance-none text-left truncate">
                            <h2 className="text-color-cai-gray text-base font-bold my-2">Marketing Plan</h2>
                            
                            {isAdmin ? (
                                totalTargets.marketing_plan_received / totalTargets.marketing_plan_required === 1 ? (
                                    <div className="flex items-center">
                                        <img src={completeSvg} alt="Complete" className="w-5 h-5 mr-2 mt-1" />
                                        <p className="text-color-cai-gray text-sm font-normal mt-1">{`Completed`}</p>
                                    </div>
                                ) : (
                                    <p className="text-color-cai-gray text-sm font-normal text-wrap m-3 md:my-1 md:mx-0">
                                        {`${totalTargets.marketing_plan_received} of ${totalTargets.marketing_plan_required} have been provided`}
                                    </p>
                                )
                            ) : (
                                totalTargets.marketing_plan_required && totalTargets.marketing_plan_received ? (
                                    <div className="flex items-center">
                                        <img src={completeSvg} alt="Complete" className="w-5 h-5 mr-2 mt-1" />
                                        <p className="text-color-cai-gray text-sm font-normal mt-1">{`Completed`}</p>
                                    </div>
                                ) : (
                                    <p className="text-color-cai-gray text-sm font-normal text-wrap m-3 md:my-1 md:mx-0">{`Incomplete`}</p>
                                )
                            )}
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap xl:flex-nowrap gap-4 w-full">
                    <InteractionsByCategoryChart interactionSummary={interactionSummary}/>
                    <ActivitiesBySectorChart interactionSummary={interactionSummary}/>
                </div>
            </div>
            {/* { filteredInitiatives.length > 0 && filteredOutcomes.length > 0 && */}
                    <>
                <div className="mx-2 mb-8 pb-8 border border-solid border-color-tableGray rounded-md w-full">
                    <div className="flex items-center my-3">
                        <h2 className="font-semibold ml-4 w-full">{isAdmin || isCAIAdmin ? 'Outcomes' : 'Initiatives'}</h2>
                        {(isAdmin || isCAIAdmin) && <SearchInput onSearch={handleSearch}/>}
                        <DownloadButton initiativeData={filteredInitiatives} outcomeData={filteredOutcomes} interactionData={filteredInteractions}/>
                    </div>
                    
                    <SummaryOutcomesTable initiativeData={filteredInitiatives} outcomeData={filteredOutcomes}/>   
                </div>
            </>
            {/* } */}
        </div>
    );
}

export default Surveys;