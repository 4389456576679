interface FormErrorModalProps {
    isOpen: boolean;
    onClose: () => void;
    message: string;
}

function FormErrorModal({ isOpen, onClose, message }: FormErrorModalProps) {
    if (!isOpen) return null;

    return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none ">
      <div className="relative w-auto max-w-md mx-auto my-6 border border-solid border-color-cai-lightGray rounded-lg shadow-lg">
        {/* Content */}
        <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
            <button
            className="absolute top-2 right-2 text-gray-500 text-lg hover:text-gray-700"
            onClick={onClose}
            >
            &times;
            </button>
            <div className="p-4 min-h-32 flex flex-col items-left">
                <p className="text-red-400 text-md font-semibold mt-4">Error</p>
                <p className="text-color-cai-gray text-lg">
                {message}
                </p>
            </div>

            <div className="flex items-center justify-end p-2 border-t border-solid rounded-b border-blueGray-200">
                <button
                className="my-3 w-32 h-9 text-center text-color-cai-dashboard bg-white border border-solid border-color-cai-dashboard rounded-md focus:outline-none focus:border-red-500  hover:bg-red-400 hover:border-red-500 hover:text-white"
                type="button"
                onClick={onClose}
                >
                Close
                </button>
            </div>
        </div>
      </div>
    </div>
  );
};

export default FormErrorModal;