import { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import AdoDataContext from '../context/AdoDataContext';

interface SelectADOProps {
    currAdo: string;
    filterByAdo: (ado: string) => void;
}

const ADONameMapping: { [id: string]: string } = {
    "Adams County": "Adams County Development Council",
    "Chelan-Douglas Regional Port Authority": "Chelan - Douglas",
    "Clallam County": "Clallam County Economic Development Council",
    "Columbia River Economic Development Authority": "Columbia River Economic Development Authority",
    "Cowlitz Economic Development Council": "Cowlitz Economic Development Council",
    "Economic Alliance Snohomish County": "Economic Alliance Snohomish County",
    "Economic Development Alliance of Skagit County": "Economic Development Alliance of Skagit County",
    "Economic Development Board for Tacoma-Pierce County": "Economic Development Board for Tacoma-Pierce County",
    "Economic Development Council of Mason County": "Economic Development Council of Mason County",
    "Economic Development Team Jefferson": "Economic Development Council Team Jefferson",
    "Grant County Economic Development Council": "Grant County Economic Development Council",
    "Greater Grays Harbor, Inc.": "Greater Grays Harbor, Inc.",
    "Greater Seattle Chamber of Commerce": "Greater Seattle Chamber of Commerce",
    "Greater Spokane Incorporated": "Greater Spokane Incorporated",
    "Island County Economic Development Council": "Island County Economic Development Council",
    "Kitsap Economic Development Alliance": "Kitsap Economic Development Alliance",
    "Kittitas County Chamber of Commerce": "Kittitas County Chamber of Commerce",
    "Klickitat County Public Economic Development Authority": "Klickitat County Public Economic Development Authority",
    "Lewis Economic Development Council": "Economic Alliance for Lewis County",
    "Lincoln County Economic Development Council": "Lincoln County Economic Development Council",
    "Pacific County Economic Development Council": "Pacific County Economic Development Council",
    "Pend Oreille Economic Development Council": "Pend Oreille Economic Development Council",
    "Port of Bellingham": "Port of Bellingham",
    "Port of Columbia": "Port of Columbia",
    "Port of Douglas": "Chelan - Douglas",
    "Port of Walla Walla": "Port of Walla Walla",
    "Port of Whitman": "Port of Whitman",
    "SE WA Economic Development Association": "SE WA Economic Development Association (SEWEDA)",
    "San Juan County Economic Development Council": "San Juan County Economic Development Council",
    "Skamania County Economic Development Council": "Skamania County Economic Development Council",
    "The Economic Alliance": "The Economic Alliance",
    "Thurston County": "Thurston Economic Development Council",
    "Tri County Economic Development District": "Tri County Economic Development District",
    "Tri-City Development Council": "Tri-City Development Council (TRIDEC)",
    "Wahkiakum Chamber of Commerce": "Wahkiakum Chamber of Commerce",
    "Yakima County Development Association": "Yakima County Development Association"

};

function SelectADO({ currAdo, filterByAdo }: SelectADOProps) {
    const { adoNames } = useContext(AdoDataContext);
    const [ado, setAdo] = useState<string>(currAdo || '');

    useEffect(() => {
        setAdo(currAdo);
    }, [currAdo]);

    const handleSelectChange = (selectedOption: { value: string; label: string } | null) => {
        const selectedAdo = selectedOption ? selectedOption.value : '';
        setAdo(selectedAdo);
        filterByAdo(selectedAdo);


        console.log("ado names: ", adoNames);

        // Send message to expand
        window.parent.postMessage({ message: "location", params: { location: ADONameMapping[selectedAdo], type: "ado"} }, "*");
        console.log('expand message sent');
    };

    return (
        <div className='sm:mt-0 mt-2'>
            <Select
                options={adoNames}
                value={adoNames.find(option => option.value === ado)}
                onChange={handleSelectChange}
                placeholder="Select ADO"
                isClearable
                styles={{
                    control: (base) => ({
                        ...base,
                        borderColor: '#E5E7EB',
                        boxShadow: 'none',
                        height: '2rem',
                        minHeight: '2rem',
                        width: '10rem',
                        borderRadius: '4px',
                        marginRight: '1rem',
                    }),
                    valueContainer: (base) => ({
                        ...base,
                        fontSize: '0.875rem',
                        padding: '0 0.5rem',
                        minHeight: '2rem',
                        height: '2rem',
                    }),
                    placeholder: (base) => ({
                        ...base,
                        color: '#3A3C41',
                        fontSize: '0.875rem',
                        fontWeight: 'bold',
                    }),
                    singleValue: (base) => ({
                        ...base,
                        color: '#3A3C41',
                        fontSize: '0.875rem',
                        fontWeight: 'bold'
                    }),
                    dropdownIndicator: (base) => ({
                        ...base,
                        padding: '0',
                    }),
                    clearIndicator: (base) => ({
                        ...base,
                        padding: '0',
                    }),
                    indicatorsContainer: (base) => ({
                        ...base,
                        padding: '0 0.25rem',
                    }),
                    // indicatorSeparator: (base) => ({
                    //     ...base,
                    //     display: 'none'
                    // }),
                    option: (base, state) => ({
                        ...base,
                        fontSize: '0.875rem',
                        backgroundColor: state.isSelected ? '#626262' : state.isFocused ? '#CCCCCC' : 'white',
                        borderRadius: '4px',
                        borderBottom: '1px solid #E2E8F0',
                        color: state.isSelected ? 'white' : '#3A3C41',
                        '&:hover': {
                            backgroundColor: '#CCCCCC',
                        },
                    }),
                    menu: (base) => ({
                        ...base,
                        width: '10rem',
                        position: 'absolute',
                        left: '0',
                        top: '1.5rem',
                        zIndex: 9999,
                        boxShadow: '0 8px 16px 0 rgba(0, 0, 0, 0.15)',
                        
                    }),
                    menuList: (base) => ({
                        ...base,
                        padding: '4px',
                        maxHeight: '200px', // Adjust height as needed
                        overflowY: 'auto',
                        '::-webkit-scrollbar': {
                            width: '8px',
                        },
                        '::-webkit-scrollbar-track': {
                            background: '#ffffff',
                            borderRadius: '4px',
                        },
                        '::-webkit-scrollbar-thumb': {
                            background: '#E8E8E8',
                            borderRadius: '4px',
                            height: '50px',
                            padding: '10px'
                        },
                    }),
                }}
            />
        </div>
    );
}

export default SelectADO;


// Legacy select element
// <span className="flex items-center h-12 relative">
//     {ado && (<button
//         className="absolute right-auto top-10 p-1 text-xs text-gray-400 outline-none focus:outline-none"
//         onClick={clearSelection}
//     >
//         Clear Selection
//     </button>)}
//     <select
//         id="selectAdo"
//         value={ado}
//         onChange={handleSelectChange}
//         className="text-color-cai-gray text-sm font-bold w-36 pl-3 pr-6 border border-solid border-lightGray rounded-md focus:outline-none focus:border-blue-500 h-8 appearance-none text-left hover:cursor-pointer truncate"
//     >
//         <option value="" disabled className="text-color-cai-gray">
//             Select ADO
//         </option>
//         {adoOptions.map((adoName) => (
//             <option key={adoName} value={adoName}>
//                 {adoName}
//             </option>
//         ))}
//     </select>
//     <img src={carrot} alt="Dropdown Carrot" className="relative h-4 w-4 top-0 right-7 pointer-events-none" />
// </span>