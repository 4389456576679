import { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { AdminProviderProps, InteractionData, InteractionDataContextType } from '../services/types';
import * as Sentry from '@sentry/react';



const InteractionDataContext = createContext<InteractionDataContextType>({
    interactions: [],
    fetchInteractions: () => {},
});

export const InteractionDataProvider = ({ children }: AdminProviderProps) => {
    const [interactions, setInteractions] = useState<InteractionData[]>([]);
    const apiUrl = `https://backend-cai-commerce.vercel.app/api/getinteractions`;

    const fetchInteractions = async () => {
        try {
            const response = await axios.get(apiUrl);
            if (response.status === 200) {
                const data = response.data.data;
                setInteractions(data);
            } else {
                // Log the error response to Sentry
                Sentry.captureMessage(`Error fetching interactions: ${response.data.message}`, 'warning');
                console.error('Error fetching interactions:', response.data);
            }
        } catch (error) {
            // Log the caught error to Sentry
            Sentry.captureException(error);
            console.error('Error fetching Interactions:', error);
        }
    };

    useEffect(() => {
        fetchInteractions();
    }, []);

    return (
        <InteractionDataContext.Provider value={{ interactions, fetchInteractions }}>
            {children}
        </InteractionDataContext.Provider>
    );
};

export default InteractionDataContext;