// import { useContext } from "react";
import { IndustryType, LocationType, PartnerLocation, TypesAttributes, TypesData } from "../services/types";
import { calculateAvailableYears } from "./helpers";

// Initiatives Form Options
const availableYears = calculateAvailableYears();
export const yearOptions = availableYears.map(year => ({ value: year, label: year.toString() }));

export const statusOptions = [
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' }
];

// Assuming status can be 'active' or 'inactive'
export const getTypeOptions = (
    typesData: TypesData,
    key: string,
    currentTypeValue: string | string[] | undefined
) => {
    const data = typesData[key as keyof TypesData];
    if (!data) {
        console.warn(`Key "${key}" not found in typesData.`);
        return [];
    }
    // Get active options
    const activeOptions = data
        .filter((type: TypesAttributes) => type.status === 'active')
        .map((type: TypesAttributes) => ({
            value: type.id,
            label: type.display_name,
        }));

    // Function to find and add an inactive type if it exists
    const addInactiveTypeOption = (value: string) => {
        const currentType = data.find((type: TypesAttributes) => type.id === Number(value));
        if (currentType && currentType.status === 'inactive') {
            activeOptions.push({
                value: currentType.id,
                label: currentType.display_name,
            });
        }
    };

    // Handle the case where currentTypeValue is a string
    if (typeof currentTypeValue === 'string') {
        addInactiveTypeOption(currentTypeValue);
    }

    // Handle the case where currentTypeValue is an array of strings
    if (Array.isArray(currentTypeValue)) {
        currentTypeValue.forEach(addInactiveTypeOption);
    }

    return activeOptions;
};

export const getIndustryTypeOptions = (
    typesData: TypesData,
    key: string,
    currentTypeValue: string | string[] | undefined
) => {
    const data = typesData[key as keyof TypesData];
    if (!data) {
        console.warn(`Key "${key}" not found in typesData.`);
        return [];
    }

    // Map the options using the 'code' as the value and 'name' as the label
    const options = data.map((type: IndustryType) => ({
        value: type.code, // Use 'code' instead of 'id'
        label: type.name,
    }));

    // If currentTypeValue is a string, ensure it's included in the options
    if (typeof currentTypeValue === 'string') {
        const currentType = data.find((type: IndustryType) => Number(type.code) === Number(currentTypeValue));
        if (currentType && !options.some((option: any) => option.value === currentTypeValue)) {
            options.push({
                value: currentType.code,
                label: currentType.name,
            });
        }
    }

    // If currentTypeValue is an array, ensure each value is included in the options
    if (Array.isArray(currentTypeValue)) {
        currentTypeValue.forEach(value => {
            const currentType = data.find((type: IndustryType) => type.code === value);
            if (currentType && !options.some((option: any) => option.value === value)) {
                options.push({
                    value: currentType.code,
                    label: currentType.name,
                });
            }
        });
    }

    return options;
};


export const getSubIndustryOptions = (industry?: IndustryType) => {
    if (!industry) {
        return [];
    }
    return industry.sub_industries.map(subIndustry => ({
        value: subIndustry.code,
        label: subIndustry.name,
    }));
};

export const getPartnerLocations = (partnerId: number, typesData: TypesData): LocationType[] => {
    const partner = typesData.partner_locations.find((p: PartnerLocation) => p.partner_id === partnerId);
    return partner ? partner.locations : [];
};

// Transform locations into the format expected by the Select component
export const transformLocationsToOptions = (locations: LocationType[]): { value: string, label: string }[] => {
    return locations.map(location => ({
        value: location.id,
        label: location.name
    }));
};

export const monthOptions = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' }
];


export const IndustryOptions = [
    { value: 'construction', label: 'Construction' },
    { value: 'resources', label: 'Resources' },
    { value: 'manufacturing', label: 'Manufacturing' },
    { value: 'wholesale_utilities', label: 'Wholesale, Utilities' },
    { value: 'retail', label: 'Retail' },
    { value: 'finance_insurance_re', label: 'Finance, Insurance, R/E' },
    { value: 'it_prof_services', label: 'IT & Prof. Services' },
    { value: 'health_other_services', label: 'Health & Other Services' },
    { value: 'consumer_services', label: 'Consumer Services' },
    { value: 'education', label: 'Education' },
    { value: 'government', label: 'Government' },
];

export const SubIndustryOptions = [
    { value: 'agriculture', label: 'Agriculture, Forestry, Fishing and Hunting' },
    { value: 'mining', label: 'Mining, Quarrying, and Oil and Gas Extraction' },
    { value: 'utilities', label: 'Utilities' },
    { value: 'construction', label: 'Construction' },
    { value: 'manufacturing', label: 'Manufacturing' },
    { value: 'wholesale', label: 'Wholesale Trade' },
    { value: 'retail', label: 'Retail Trade' },
    { value: 'transportation', label: 'Transportation and Warehousing' },
    { value: 'information', label: 'Information' },
    { value: 'finance_insurance', label: 'Finance and Insurance' },
    { value: 'real_estate', label: 'Real Estate and Rental and Leasing' },
    { value: 'professional_services', label: 'Professional, Scientific, and Technical Services' },
    { value: 'management', label: 'Management of Companies and Enterprises' },
    { value: 'administrative_services', label: 'Administrative and Support and Waste Management and Remediation Services' },
    { value: 'educational_services', label: 'Educational Services' },
    { value: 'healthcare', label: 'Health Care and Social Assistance' },
    { value: 'arts_recreation', label: 'Arts, Entertainment, and Recreation' },
    { value: 'accommodation_food', label: 'Accommodation and Food Services' },
    { value: 'other_services', label: 'Other Services (except Public Administration)' },
    { value: 'public_administration', label: 'Public Administration' },
];

export const serviceAreaOptions = [
    { value: 'Full ADO Area', label: 'Full ADO Area'},
    { value: 'Selected Area within ADO', label: 'Selected Area within ADO'},
];

export const adoAreaOptions = [
    { value: 'placeholder 1', label: 'placeholder 1'},
    { value: 'placeholder 2', label: 'placeholder 2'},
    { value: 'placeholder 3', label: 'placeholder 3'},
]


// Interactions Form Options
export const businessClassifications = Object.entries({
    bipoc: 'BIPOC-owned Business',
    woman: 'Woman-owned Business',
    vet: 'Veteran-owned Business',
    tribal: 'Tribal-owned Business',
    wa_small: 'Washington Small Business',
    wa_owmbe: 'Washington OWMBE Certified',
    nonprofit: 'Nonprofit Organization'
}).map(([key, value]) => ({ value: key, label: value }));

export const contactTypeOptions = Object.entries({
    owner: 'Business Owner',
    management: 'Management',
    busdev: 'Business Development',
    staff_other: 'Staff/Other'
}).map(([key, value]) => ({ value: key, label: value }));

export const interactionTypeOptions = Object.entries({
    bre: 'Business Retention and Expansion',
    recruitment: 'Recruitment',
    startup: 'Startup Support',
    export: 'Export Assistance',
    training: 'Training'
}).map(([key, value]) => ({ value: key, label: value }));

export const assistanceTypeOptions = Object.entries({
    access_capital: 'Access to Capital',
    broadband: 'Broadband',
    expan_reloc: 'Expansion/Relocation',
    export: 'Export Assistance',
    gov: "Governor's Office for Regulatory Innovation & Assistance",
    infrastructure: 'Infrastructure',
    site_rec: 'Site Recommendation',
    site_select: 'Site Selection',
    small_bus: 'State Small Business Credit Initiative',
    supply: 'Supply Chain Issues',
    apex: 'Washington APEX Accelerator',
    wash_small: 'Washington Small Business Development Center',
    workforce: 'Workforce Development'
}).map(([key, value]) => ({ value: key, label: value }));