import { useState, ChangeEvent, useContext, forwardRef, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import leftArrow from '../../assets/left-arrow.svg';
import Expand from '../../components/Expand';
import InitiativeDataContext from '../../context/InitiativeDataContext';
import useDeepCompareEffect from '../../hooks/useDeepCompareEffect';
import DatePicker from 'react-datepicker';
import calendarIcon from '../../assets/calendar.svg';
import { useIframeContext } from '../../context/IframeContext';
import validator from 'validator';
import Select from 'react-select';
import { customStyles } from '../../utils/customFormStyles';
import FormErrorModal from '../../components/FormErrorModal';
import { getTypeOptions } from '../../utils/formOptions';
import { Loader } from '@googlemaps/js-api-loader';
import SurveyModal from '../../components/Interactions/SurveyModal';
import TypesContext from '../../context/TypesContext';
import AdoDataContext from '../../context/AdoDataContext';
import { useOrganizationContext } from '../../context/OrganizationContext';
import InteractionDataContext from '../../context/InteractionDataContext';
import { formatPhoneNumber, sanitizeString } from '../../utils/helpers';
import { useCAIAdminContext } from '../../context/CAIAdminContext';
import { PartnerData } from '../../services/types';

// enable typing for window.google
declare global {
    interface Window {
        google: any;
    }
}

interface CustomCalendarProps {
    value?: string | null; // Assuming value is a string (formatted date) or null
    onClick?: () => void; // onClick handler for the button
}

function AddInteraction() {
    const { isIframe } = useIframeContext();
    const { isCAIAdmin } = useCAIAdminContext();
    const { partnerData } = useContext(AdoDataContext);
    const { partnerId, partnerName } = useOrganizationContext();
    const ado = partnerName;
    const { typesData } = useContext(TypesContext);
    const { initiatives } = useContext(InitiativeDataContext);
    const { fetchInteractions } = useContext(InteractionDataContext);

    const [partner, setPartner] = useState<PartnerData>();
    const [initiativeList, setInitiativeList] = useState<string[]>([]);
    const [initiative, setInitiative] = useState("");
    const [initiativeId, setInitiativeId] = useState<number | null>(null);
    const [staff, setStaff] = useState("");
    const [interactionType, setInteractionType] = useState("");
    const [assistanceType, setAssistanceType] = useState<string[]>([]);
    const [interactionDate, setInteractionDate] = useState<Date | null>(null);
    const [note, setNote] = useState("");
    const [business, setBusiness] = useState("");
    const [businessLegal, setBusinessLegal] = useState("");
    const [ubi, setUbi] = useState("");
    const [hasUBI, setHasUBI] = useState(true);
    const [busClass, setBusClass] = useState<string[]>([]);
    const [employee, setEmployee] = useState("");
    const [revenue, setRevenue] = useState("");
    // const [selectedPlace, setSelectedPlace] = useState<SelectedPlaceType | null>(null);
    const [address, setAddress] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [first, setFirst] = useState("");
    const [last, setLast] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [contactType, setContactType] = useState("");
    const [hasContact, setHasContact] = useState(true);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showSurveyModal, setShowSurveyModal] = useState(false);
    const [formErrorMsg, setFormErrorMsg] = useState('');

    const location = useLocation();
    const id = location.state?.id || null;
    
    const autocompleteRef = useRef<HTMLInputElement>(null);

    // Create select options for initiatives and industries
    const interactionTypeOptions = getTypeOptions(typesData, 'interaction_types', interactionType);
    const contactTypeOptions = getTypeOptions(typesData, 'contact_types', contactType);
    const classificationTypeOptions = getTypeOptions(typesData, 'classification_types', busClass);
    const assistanceTypeOptions = getTypeOptions(typesData, 'assistance_types', assistanceType);

    const initiativeOptions = initiativeList.map((initiativeOption) => ({
        value: initiativeOption,
        label: initiativeOption,
    }));

    // Legacy partner object
    // Find the partner data matching the selected ADO name
    // const partner = partnerData.find(partner => partner.name.trim().toLowerCase() === ado.trim().toLowerCase());

    // Set partnerId logic based on initiative or ADO
    useEffect(() => {
        if (isCAIAdmin && initiative) {
        // If CAIAdmin is true and initiative is selected, find partnerId from initiative
        const selectedInitiative = initiatives.find(init => init.name === initiative);
        if (selectedInitiative) {
            const partner = partnerData.find(partner => partner.id === Number(selectedInitiative.partner_id));
            setPartner(partner);
        }
        } else if (!isCAIAdmin && ado) {
            // If not CAIAdmin, use the partnerData logic
            const partner = partnerData.find(partner => sanitizeString(partner.name) === sanitizeString(ado));
            if (partner) {
                setPartner(partner);
            }
        }
    }, [isCAIAdmin, initiative, ado, partnerData, initiatives, setPartner]);

    const navigate = useNavigate();

    // Handler for navigating back to Interactions page
    const navigateBack = () => {
        fetchInteractions();
        navigate(-1);
    };

    useDeepCompareEffect(() => {
        setInitiativeList(initiativeNames);
        // We need to pre-select an initiative if an id is passed from the initiative detail page.
        if (id) {
            setInitiativeId(id);

            const passedInitiative = initiatives.find(initiative => initiative.id === Number(id));

            if (passedInitiative) {
                setInitiative(passedInitiative.name);
            }
        }
    }, [initiatives])

    const currentInitiatives = isCAIAdmin
    ? initiatives // Show all initiatives if isCAIAdmin is true
    : initiatives.filter(initiative => sanitizeString(initiative.partner_name) === sanitizeString(ado));

    // Ensure currentInitiatives is an array and handle potential undefined names
    const initiativeNames: string[] = (currentInitiatives || [])
    .map(initiative => initiative.name)
    .filter(name => typeof name === 'string' && name.trim() !== '')
    .sort((a, b) => a.localeCompare(b));

    // load script for google maps autocomplete widget and set address state to selected location
    useEffect(() => {
        
        const loader = new Loader({
            apiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
            version: 'weekly',
            libraries: ['places'], // Include 'places' library for geocoding and places autocomplete
        });

        loader.load().then(() => {
            if (autocompleteRef.current && window.google && window.google.maps) {
                const autocomplete = new window.google.maps.places.Autocomplete(autocompleteRef.current, {
                    types: ['geocode'], // Specify 'geocode' to get all possible addresses
                    componentRestrictions: { country: 'us' } // Restrict results to a specific country if needed
                });

                autocomplete.addListener('place_changed', () => {
                    const place = autocomplete.getPlace();
                    if (place.formatted_address) {
                        // setSelectedPlace(place);
                        setAddress(place.formatted_address);

                        const lat = place.geometry.location.lat();
                        const lng = place.geometry.location.lng();

                        setLatitude(lat.toString());
                        setLongitude(lng.toString());

                        console.log('Latitude:', lat);
                        console.log('Longitude:', lng);
                    }
                });
            }
        });

    }, []);

    const handleSubmit = () => {
        setShowSurveyModal(true);
    };

    // Change handlers for form fields
    const handleInputChange = (setter: React.Dispatch<React.SetStateAction<string>>) => (event: ChangeEvent<HTMLInputElement>) => {
        setter(event.target.value);
    };

    const handleUbiToggle = () => setHasUBI(prevState => !prevState);
    const handleContactToggle = () => setHasContact(prevState => !prevState);

    // Custom calendar reference component for React Datepicker see docs: (https://reactdatepicker.com/#example-custom-input)
    const CustomCalendar = forwardRef<HTMLButtonElement, CustomCalendarProps>(({ value, onClick }, ref) => (
        <button className={`w-40 pl-4 pr-4 py-2 h-10 text-left border border-solid ${isSubmitted && !value ? 'border-red-500' : 'border-tableGray'} rounded-md focus:outline-none focus:border-blue-500 appearance-none`} onClick={onClick} ref={ref}>
        {value || 'Select Date'}
        </button>
    ));

    const handleInteractionDateChange = (date: Date | null) => {
        setInteractionDate(date);
    }

    const handleInitiativeSelectChange = (selectedOption: any) => {
        const selectedInitiative = selectedOption ? selectedOption.value : '';
        setInitiative(selectedInitiative);
    
        // Find the initiative object that matches both the selected name and the partner id
        const selectedInitiativeObject = initiatives.find(initiative => 
            initiative.name === selectedInitiative && 
            Number(initiative.partner_id) === partnerId
        );
    
        // If found, update the initiative_id in newInteraction
        if (selectedInitiativeObject) {
            const initId = selectedInitiativeObject.id;
            setInitiativeId(initId);
        }
    };

    const handleTextAreaChange = (setter: React.Dispatch<React.SetStateAction<string>>) => (event: ChangeEvent<HTMLTextAreaElement>) => {
        setter(event.target.value);
    };

    // Change handler factory for react-select components
    const createSelectChangeHandler = (setter: React.Dispatch<React.SetStateAction<string>>) => (selectedOption: any) => {
        setter(selectedOption ? selectedOption.value : '');
    };

    const createMultiSelectChangeHandler = (setter: React.Dispatch<React.SetStateAction<string[]>>) => (selectedOption: any) => {
        const selection = selectedOption ? selectedOption.map((option: any) => option.value) : [];
        setter(selection);
    };

    const validateForm = () => {
        
        const formattedPhone = formatPhoneNumber(phone);  // Format the phone number first

        const validations = [
            { 
                field: formattedPhone, 
                regex: /^\(\d{3}\) \d{3}-\d{4}$/, 
                message: 'Please enter a valid phone number.' 
            },
            { field: email, validator: validator.isEmail, message: 'Please enter a valid email address.' },
            { field: employee, validator: validator.isNumeric, message: 'Please enter a valid number for Number of Employees.' },
            { 
                field: ubi, 
                regex: /^[\d-]+$/,  // Supports numbers and dashes
                message: 'Please enter a valid UBI.' 
            },
            { field: revenue, validator: validator.isNumeric, message: 'Please enter a valid number for Gross Revenue.' },
            { field: latitude, validator: validator.isNumeric, message: 'Please enter a valid number for Latitude.' },
            { field: longitude, validator: validator.isNumeric, message: 'Please enter a valid number for Longitude.' },
        ];
    
        for (let { field, regex, validator, message } of validations) {
            if (field) {
                if (regex && typeof regex === 'object' && !regex.test(field)) {
                    setFormErrorMsg(message);
                    setShowModal(true);
                    return false;
                } else if (validator && typeof validator === 'function' && !validator(field.toString())) {
                    setFormErrorMsg(message);
                    setShowModal(true);
                    return false;
                }
            }
        }
        // form is valid
        return true; 
    };

    const handleSave = async () => {
        if (!validateForm()) {
            console.log('validation error, missing required form fields');
            return;
        }

        if (!partner) {
            setFormErrorMsg(`Partner ID for ${ado} not found.`);
            setShowModal(true);
            return;
        }

        setIsSubmitted(true);

        let formattedDate;

        if (interactionDate) {
            const date = new Date(interactionDate);

            // Manually format the date without converting to UTC
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
            const day = String(date.getDate()).padStart(2, '0');
            formattedDate = `${year}-${month}-${day}`;
        }

        // Format phone number
        const formattedPhone = formatPhoneNumber(phone);

        // Map selected keys to their corresponding values
        const formattedClassifications = busClass.map(id => ({ id }));
        const formattedAssistances = assistanceType.map(id => ({ id }));

        const partnerId = partner.id;

        const newInteraction = {
            interaction_date: formattedDate,
            staff_name: staff,
            interaction_type_id: Number(interactionType),
            business_trade_name: business,
            business_legal_name: businessLegal,
            people_employed: Number(employee),
            gross_revenue: Number(revenue),
            business_ubi: ubi,
            first_name: first,
            last_name: last,
            phone: formattedPhone,
            email: email,
            contact_type_id: Number(contactType),
            note: note,
            latitude: Number(latitude),
            longitude: Number(longitude),
            address: address,
            contact_available: hasContact,
            ubi_available: hasUBI,
            industry_code: null,
            sub_industry_code: null,
            assistance_types: formattedAssistances,
            classification_types: formattedClassifications
        };

        const payload = { "interactions": [newInteraction]};
        console.log('payload:', payload);

        if (interactionType && assistanceType && interactionDate && business && (!hasUBI || (hasUBI && ubi))) {
            // Proceed with form submission
            try {
                const response = await fetch(`https://backend-cai-commerce.vercel.app/api/addinteraction?partnerId=${partnerId}&initiativeId=${initiativeId}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                });
        
                const responseData = await response.json();
        
                if (!response.ok) {
                    throw new Error(responseData.error || 'Failed to add target');
                }
        
                console.log('Data successfully sent to backend:', responseData);
        
                // Return to Interactions dashboard
                navigateBack();
            } catch (error) {
                // Log the caught error to Sentry
                Sentry.captureException(error);
                console.error('Error sending data to backend:', error);
            }
        }
    };

    const handleCancel = () => {
        // Return to Interactions dashboard
        navigateBack();
    }
    

    return (
    <div className="m-2 p-4">
        {/* Back to Interactions button */}
        <div className='flex justify-between'>
            <button onClick={navigateBack} className="flex items-center mb-4 font-semibold text-sm">
                <img src={leftArrow} alt='left arrow' className='h-3 w-4 mr-3'></img>
                Back to Interactions
            </button>
            {isIframe && 
                <div className="flex ml-auto mb-4">
                    <Expand/>
                </div>
            }
        </div>

        {/* Add Interaction Details */}
        <div className="mb-8 border border-solid border-color-tableGray rounded-md">
            <div className='border-b border-solid border-color-tableGray mb-5'>
                <h2 className="text-sm font-semibold my-3 ml-7">Add Interaction Details</h2>
            </div>
            
            <div className='grid grid-cols-1 md:grid-cols-2 gap-1 ml-3'>
                <div className="ff-container">
                    <label htmlFor="selectInitiative" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                        Initiative:
                    </label>
                    <div className="relative w-full">
                        <div className='flex flex-col w-full'>
                            <Select
                                id="selectInitiative"
                                value={initiativeOptions.find(option => option.value === initiative)}
                                onChange={handleInitiativeSelectChange}
                                options={initiativeOptions}
                                placeholder="Select Initiative"
                                styles={customStyles(isSubmitted, initiative)}
                                isClearable
                            />
                            <p className="text-red-500 text-sm">{`${isSubmitted && !initiative ? 'Initiative is required.' : ''}`}</p>
                        </div>
                    </div>
                </div>
                <div className="ff-container">
                    <label htmlFor="inputStaffName" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                        Staff Name:
                    </label>
                    <div className="flex flex-col w-full">
                        <input
                            id="inputStaffName"
                            type="text"
                            value={staff}
                            required
                            placeholder='Enter Staff Name'
                            onChange={handleInputChange(setStaff)}
                            className={`text-sm pl-4 w-full pr-4 py-2 border border-solid ${isSubmitted && !staff ? 'border-red-500' : 'border-tableGray'} rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none`}
                        />
                        <p className="text-red-500 text-sm">{`${isSubmitted && !staff ? 'Staff Name is required.' : ''}`}</p>
                    </div>
                </div>
                <div className="ff-container">
                    <label htmlFor="selectInteractionType" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                        Interaction Type:
                    </label>
                    <div className="relative w-full">
                        <div className="flex flex-col w-full">
                            <Select
                                id="selectType"
                                value={interactionTypeOptions.find((option: any) => option.value === interactionType)}
                                onChange={createSelectChangeHandler(setInteractionType)}
                                options={interactionTypeOptions}
                                placeholder="Select Type"
                                styles={customStyles(isSubmitted, interactionType)}
                                isClearable
                            />
                            <p className="text-red-500 text-sm">{`${isSubmitted && !interactionType ? 'Interaction Type is required.' : ''}`}</p>
                        </div>
                    </div>
                </div>
                <div className="ff-container">
                    <label htmlFor="selectAssistanceType" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                        Assistance Type:
                    </label>
                    <div className="relative w-full">
                        <div className="flex flex-col w-full">
                            <Select
                                id="selectType"
                                value={assistanceTypeOptions.filter((option: any) => assistanceType.includes(option.value))}
                                onChange={createMultiSelectChangeHandler(setAssistanceType)}
                                options={assistanceTypeOptions}
                                placeholder="Select Type"
                                styles={customStyles(isSubmitted, assistanceType)}
                                isClearable
                                isMulti
                            />
                            <p className="text-red-500 text-sm">{`${isSubmitted && !assistanceType ? 'Assistance Type is required.' : ''}`}</p>
                        </div>
                    </div>
                </div>
                <div className="ff-container">
                    <label htmlFor="inputInteractionDate" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                        Interaction Date:
                    </label>
                    <div className='flex flex-col w-full'>
                        <div className="relative w-full text-sm">
                            <DatePicker
                                selected={interactionDate}
                                placeholderText='Choose Date'
                                dateFormat='MM/dd/yyyy'
                                onChange={handleInteractionDateChange}
                                customInput={<CustomCalendar/>}
                                required
                            />
                            <img src={calendarIcon} alt="calendar icon" className="absolute left-32 bottom-3 h-4 w-4 pointer-events-none" />
                        </div>
                        <p className="text-red-500 text-sm">{`${isSubmitted && !interactionDate ? 'Interaction Date is required.' : ''}`}</p>
                    </div>
                </div>
                <div className="flex flex-col sm:flex-row items-start sm:items-start my-4 mx-4 sm:mr-20">
                    <label htmlFor="inputNote" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                        Note:
                    </label>
                    <textarea
                        id="inputNote"
                        value={note}
                        placeholder='Add Note'
                        onChange={handleTextAreaChange(setNote)}
                        className={`text-sm pl-4 w-full pr-4 py-2 text-color-cai-dashboard border border-solid border-tableGray rounded-[4px] focus:outline-none focus:border-blue-500 h-28 appearance-none`}
                    />
                </div>
            </div>
        </div>

        {/* Add Business Details */}
        <div className="mb-8 border border-solid border-color-tableGray rounded-[4px]">
            <div className='border-b border-solid border-color-tableGray mb-5'>
                <h2 className="text-sm font-semibold my-3 ml-7">Add Business Details</h2>
            </div>
            
            <div className='grid grid-cols-1 md:grid-cols-2 gap-1 ml-3'>
                
                {/* Business Name */}
                <div className="ff-container">
                    <label htmlFor="inputBusiness" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                        Business Name:
                    </label>
                    <div className='flex flex-col w-full'>
                        <input
                            id="inputBusiness"
                            type="text"
                            value={business}
                            placeholder='Enter Business Name'
                            onChange={handleInputChange(setBusiness)}
                            className={`text-sm pl-4 w-full pr-4 py-2 border border-solid ${isSubmitted && !business ? 'border-red-500' : 'border-tableGray'} rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none`}
                        />
                        <p className="text-red-500 text-sm">{`${isSubmitted && !business ? 'Business Name is required.' : ''}`}</p>
                    </div>
                </div>
                {/* Business Legal Name */}
                <div className="ff-container">
                    <label htmlFor="inputBusinessLegal" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold text-balance w-48">
                        Business Legal Name:
                    </label>
                    <input
                        id="inputBusinessLegal"
                        type="text"
                        value={businessLegal}
                        placeholder='Enter Business Legal Name'
                        onChange={handleInputChange(setBusinessLegal)}
                        className="text-sm pl-4 w-full pr-4 py-2 border border-solid border-tableGray rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none"
                    />
                </div>
                {/* No UBI available */}
                <div className="ff-container h-14">
                    <label htmlFor="inputHasUbi" className="text-sm mb-2 sm:mb-0 sm:mr-6 font-semibold w-36">
                        No UBI Available:
                    </label>
                    <button
                        id="inputHasUbi"
                        type="button"
                        onClick={handleUbiToggle}
                        className={`w-14 h-7 rounded-full border border-gray-300 focus:outline-none relative overflow-hidden transition-all duration-300 ${!hasUBI ? 'bg-blue-500' : 'bg-gray-300'} focus:ring-2 focus:ring-blue-500 focus:outline-none`}
                    >
                        <span
                            className={`absolute left-0 top-0 bg-white w-7 h-7 rounded-full transition-all duration-300 transform ${!hasUBI ? 'translate-x-7' : 'translate-x-0'}`}
                        />
                    </button>
                </div>
                {!hasUBI &&
                    <br></br>
                }
                {/* UBI */}
                {hasUBI && 
                <div className="ff-container">
                    <label htmlFor="inputUBI" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                        Business UBI:
                    </label>
                    <div className="flex flex-col w-full">
                        <p className="text-sm text-color-cai-dashboard">
                            Don't know the Business UBI? You can look it up manually <a href="https://secure.dor.wa.gov/gteunauth/_/" className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">here</a>.
                        </p>
                        <input
                            id="inputUBI"
                            type="text"
                            value={ubi}
                            placeholder='Enter Business UBI'
                            onChange={handleInputChange(setUbi)}
                            className={`text-sm pl-4 w-full pr-4 py-2 border border-solid  rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none ${isSubmitted && hasUBI && !ubi ? 'border-red-500': 'border-tableGray'}`}
                        />
                        <p className="text-red-500 text-sm">{`${isSubmitted && hasUBI && !ubi ? 'UBI is required. Please Enter Business UBI.' : ''}`}</p>
                    </div>
                </div>
                }
                <div className="ff-container">
                    <label htmlFor="selectBusinessClass" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                        Business Classification:
                    </label>
                    <div className="relative w-full">
                        <div className="flex flex-col w-full">
                                <Select
                                    id="selectType"
                                    value={classificationTypeOptions.filter((option: any) => busClass.includes(option.value))}
                                    onChange={createMultiSelectChangeHandler(setBusClass)}
                                    options={classificationTypeOptions}
                                    placeholder="Select Type"
                                    styles={customStyles()}
                                    isClearable
                                    isMulti
                                />
                        </div>
                    </div>
                </div>
                <div className="ff-container">
                    <label htmlFor="inputEmployee" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                        No. of Employees:
                    </label>
                    <input
                        id="inputEmployee"
                        type="text"
                        value={employee}
                        placeholder='Enter No. of Employees'
                        onChange={handleInputChange(setEmployee)}
                        className="text-sm pl-4 w-full pr-4 py-2 border border-solid border-tableGray rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none"
                    />
                </div>
                <div className="ff-container">
                    <label htmlFor="inputRevenue" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                        Gross Revenue:
                    </label>
                    <input
                        id="inputRevenue"
                        type="text"
                        value={revenue}
                        placeholder='Enter Gross Revenue'
                        onChange={handleInputChange(setRevenue)}
                        className="text-sm pl-4 w-full pr-4 py-2 border border-solid border-tableGray rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none"
                    />
                </div>
                <br></br>
                <div className="ff-container">
                    <label htmlFor="inputAddress" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                        Address:
                    </label>
                    <div className='relative w-full flex flex-col'>
                        <input
                            id="inputAddress"
                            ref={autocompleteRef}
                            type="text"
                            value={address}
                            placeholder='Enter Address'
                            onChange={(e) => setAddress(e.target.value)}
                            className="text-sm w-full px-4 py-2 border border-solid border-tableGray rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none truncate"
                        />
                    </div>
                </div>
                <br></br>
                <div className="ff-container">
                    <label htmlFor="inputLatitude" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                        Latitude:
                    </label>
                    <input
                        id="inputLatitude"
                        type="text"
                        value={latitude}
                        placeholder='Enter Latitude'
                        onChange={handleInputChange(setLatitude)}
                        className="text-sm pl-4 w-full pr-4 py-2 border border-solid border-tableGray rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none"
                    />
                </div>
                <div className="ff-container">
                    <label htmlFor="inputLongitude" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                        Longitude:
                    </label>
                    <input
                        id="inputLongitude"
                        type="text"
                        value={longitude}
                        placeholder='Enter Longitude'
                        onChange={handleInputChange(setLongitude)}
                        className="text-sm pl-4 w-full pr-4 py-2 border border-solid border-tableGray rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none"
                    />
                </div>
            </div>
        </div>

        {/* Add Business Contact */}
        <div className="mb-8 border border-solid border-color-tableGray rounded-[4px]">
            <div className='flex justify-between items-center border-b border-solid border-color-tableGray mb-5'>
                <h2 className="text-sm font-semibold my-3 ml-7">Add Business Contact</h2>
                {/* Email survey link */}
                <button
                    type="submit"
                    onClick={handleSubmit}
                    id="send-survey"
                    className="m-3 p-2 w-36 h-8 text-color-cai-dashboard font-bold text-xs bg-color-white border border-solid border-color-cai-dashboard rounded-[4px] focus:outline-none focus:border-blue-500 appearance-none text-center"
                >
                Email Survey Link
                </button>
            </div>
            <SurveyModal
                isOpen={showSurveyModal}
                onClose={() => setShowSurveyModal(false)}
                surveyEmail={email}
                surveyBusiness={business}
                surveyBusinessLegal={businessLegal}
                surveyUbi={ubi}
                surveyName={first}
            />
            <div className='grid grid-cols-1 md:grid-cols-2 gap-1 ml-3'>
                {/* No Contact available */}
                <div className="ff-container">
                    <label htmlFor="inputContact" className="text-sm mb-2 sm:mb-0 sm:mr-6 font-semibold w-36">
                        No Contact:
                    </label>
                    <button
                        id="inputContact"
                        type="button"
                        onClick={handleContactToggle}
                        className={`w-14 h-7 rounded-full border border-gray-300 focus:outline-none relative overflow-hidden transition-all duration-300 ${!hasContact ? 'bg-blue-500' : 'bg-gray-300'} focus:ring-2 focus:ring-blue-500 focus:outline-none`}
                    >
                        <span
                            className={`absolute left-0 top-0 bg-white w-7 h-7 rounded-full transition-all duration-300 transform ${!hasContact ? 'translate-x-7' : 'translate-x-0'}`}
                        />
                    </button>
                </div>
                <br></br>
                 {/* First Name */}
                {hasContact &&    
                    <div className="ff-container">
                        <label htmlFor="inputFirst" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                            First Name:
                        </label>
                        <input
                            id="inputFirst"
                            type="text"
                            value={first}
                            placeholder='Enter First Name'
                            onChange={handleInputChange(setFirst)}
                            className="text-sm pl-4 w-full pr-4 py-2 border border-solid border-tableGray rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none"
                        />
                    </div>
                }
                
                {/* Last Name */}
                {hasContact && 
                    <div className="ff-container">
                        <label htmlFor="inputLast" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                            Last Name:
                        </label>
                        <input
                            id="inputLast"
                            type="text"
                            value={last}
                            placeholder='Enter Last Name'
                            onChange={handleInputChange(setLast)}
                            className="text-sm pl-4 w-full pr-4 py-2 border border-solid border-tableGray rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none"
                        />
                    </div>
                }
                
                {/* Contact Phone */} 
                {hasContact &&
                    <div className="ff-container">
                        <label htmlFor="inputPhone" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                            Contact Phone:
                        </label>
                        <input
                            id="inputPhone"
                            type="text"
                            value={phone}
                            placeholder='Enter Contact Phone'
                            onChange={handleInputChange(setPhone)}
                            className="text-sm pl-4 w-full pr-4 py-2 border border-solid border-tableGray rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none"
                        />
                    </div>
                }
                {/* Contact Email */}
                {hasContact &&
                    <div className="ff-container">
                        <label htmlFor="inputEmail" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                            Contact Email:
                        </label>
                        <input
                            id="inputEmail"
                            type="text"
                            value={email}
                            placeholder='Enter Contact Email'
                            onChange={handleInputChange(setEmail)}
                            className="text-sm pl-4 w-full pr-4 py-2 border border-solid border-tableGray rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none"
                        />
                    </div>
                }
                {/* Contact Type */}
                {hasContact &&
                    <div className="ff-container">
                        <label htmlFor="selectContactType" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                            Contact Type:
                        </label>
                        <div className="relative w-full">
                            <Select
                                id="selectType"
                                value={contactTypeOptions.find((option: any) => option.value === contactType)}
                                onChange={createSelectChangeHandler(setContactType)}
                                options={contactTypeOptions}
                                placeholder="Select Type"
                                styles={customStyles()}
                                isClearable
                            />
                        </div>
                    </div>
                }
            </div>
            <FormErrorModal
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                message={formErrorMsg}
            />
            <div className="flex items-center border-solid border-t border-color-tableGray">
                <button
                    id="save-interaction"
                    onClick={handleSave}
                    className='ml-7 mt-5 mb-3 w-36 h-8 text-center text-white bg-color-cai-dashboard border border-solid border-color-cai-dashboard rounded-[4px] focus:border-blue-500 focus:outline-none hover:bg-blue-400 hover:border-blue-600 cursor-pointer'
                    // disabled={!isFormValid}
                >
                    Add
                </button>
                <button
                    id="cancel-interaction"
                    onClick={handleCancel}
                    className="ml-7 mt-5 mb-3 w-36 h-8 text-center text-color-cai-dashboard bg-white border border-solid border-color-cai-dashboard rounded-[4px] focus:outline-none focus:border-red-400 hover:bg-red-400 hover:border-red-500 hover:text-white cursor-pointer"
                >
                    Cancel
                </button>
            </div>
        </div>  
    </div>
    );
};

export default AddInteraction;