import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
import editIcon from '/src/assets/edit.svg';
import deleteIcon from '/src/assets/delete.svg';
// @ts-ignore
import { addCommas } from '../../utils/helpers.js';
import { OutcomeData } from '../../services/types.js';

interface OutcomesTableProps {
    outcomeData: OutcomeData[]; // Define props interface
    partnerId?: string;
    initiativeId?: number;
}

// function OutcomesTable({ data }: OutcomesTableProps) {
function OutcomesTable({ outcomeData, partnerId, initiativeId }: OutcomesTableProps) {
    const [data, setData] = useState<OutcomeData[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (Array.isArray(outcomeData)) {
            setData(outcomeData);
        } else {
            console.error('outcomeData is not an array:', outcomeData);
        }
    }, [outcomeData]);

    const handleEditClick = (row: OutcomeData) => {
        navigate(`/commerce-outcomes/edit/${row.id}`, { state: { data: row, partnerId, initiativeId } });
    };

    const handleDeleteClick = async (id: number) => {
        navigate(`/commerce-outcomes/delete/${id}`, { state: {id, partnerId, initiativeId } });
    };

    return (
        <div>
            <div className="overflow-x-auto border border-solid border-color-tableGray">
                <table className="min-w-full">
                    <thead className="bg-color-cai-lightGray text-color-cai-main">
                        <tr>
                            <th className="border-b border-color-tableGray text-xs font-bold px-4 py-2 text-left">Year</th>
                            <th className="border-b border-color-tableGray text-xs font-bold px-4 py-2 text-left">Quarter</th>
                            <th className="border-b border-color-tableGray text-xs font-bold px-4 py-2 text-end text-nowrap">Jobs created</th>
                            <th className="border-b border-color-tableGray text-xs font-bold px-4 py-2 text-end text-nowrap">Jobs retained</th>
                            <th className="border-b border-color-tableGray text-xs font-bold px-4 py-2 text-end">Jobs created/retained with above avg wages</th>
                            <th className="border-b border-color-tableGray text-xs font-bold px-4 py-2 text-end text-nowrap">Public investment dollars</th>
                            <th className="border-b border-color-tableGray text-xs font-bold px-4 py-2 text-end">Direct foreign investment dollars</th>
                            <th className="border-b border-color-tableGray text-xs font-bold px-4 py-2 text-end text-nowrap">Private investment dollars</th>
                            <th className="border-b border-color-tableGray text-xs font-bold px-4 py-2 text-end text-nowrap">{'Exports($/year)'}</th>
                            <th className="border-b border-color-tableGray text-xs font-bold px-4 py-2 text-end text-nowrap">Startup businesses</th>
                            <th className="border-b border-color-tableGray text-xs font-bold px-4 py-2 text-end"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row) => (
                            <tr key={row.id} className="border-b border-solid border-color-tableGray last:border-none h-10">
                                <td className="px-4 py-2 min-w-24 text-color-cai-gray text-sm text-left font-medium">
                                    {row.year}
                                </td>
                                <td className="px-4 py-2 min-w-24 text-color-cai-gray text-sm text-left font-medium">
                                    {row.quarter}
                                </td>
                                <td className="px-4 py-2 min-w-28 text-color-cai-gray text-sm text-end font-medium">
                                    {row.jobs_created}
                                </td>
                                <td className="px-4 py-2 min-w-32 text-color-cai-gray text-sm text-end font-medium">
                                    {row.jobs_retained}
                                </td>
                                <td className="px-4 py-2 min-w-44 text-color-cai-gray text-sm text-end font-medium">
                                    {row.jobs_created_retained_with_above_avg_wages}
                                </td>
                                <td className="px-4 py-2 min-w-36 text-color-cai-gray text-sm text-end font-medium">
                                    {`$${addCommas(row.public_investment_dollars)}`}
                                </td>
                                <td className="px-4 py-2 min-w-36 text-color-cai-gray text-sm text-end font-medium">
                                    {`$${addCommas(row.direct_foreign_investment_dollars)}`}
                                </td>
                                <td className="px-4 py-2 min-w-36 text-color-cai-gray text-sm text-end font-medium">
                                    {`$${addCommas(row.private_investment_dollars)}`}
                                </td>
                                <td className="px-4 py-2 min-w-36 text-color-cai-gray text-sm text-end font-medium">
                                    {`$${addCommas(row.products_exported_dollars_year)}`}
                                </td>
                                <td className="px-4 py-2 min-w-24 text-color-cai-gray text-sm text-end font-medium">
                                    {row.startups_supported}
                                </td>
                                <td className="flex w-24 px-4 py-2 text-color-cai-gray text-sm text-end items-center justify-end">
                                    <button onClick={(e) => { e.stopPropagation(); handleEditClick(row) }} className="mr-3 flex items-center">
                                        <img className="border border-solid border-tableGray h-6 w-6 p-1 rounded-md" src={editIcon} alt="Edit" />
                                    </button>
                                    <button onClick={(e) => { e.stopPropagation(); handleDeleteClick(row.id) }}
                                        className="flex items-center">
                                        <img className="border border-solid border-tableGray h-6 w-6 p-1 rounded-md" src={deleteIcon} alt="Delete" />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default OutcomesTable;