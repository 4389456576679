import { useContext, useEffect, useState } from 'react';
import SearchInput from "../../components/SearchInput";
import ReportingDropdown from "../../components/BreDropdown";
import BreSort from "../../components/BreSort";
import SelectADO from "../../components/SelectADO";
import FiscalYear from "../../components/FiscalYear";
import InitiativeTable from "../../components/Initiatives/InitiativeTable";
import Expand from "../../components/Expand";
import InitiativeDataContext from "../../context/InitiativeDataContext";
import useFilteredData from "../../hooks/useFilteredData";
import { calculateAvailableYears, calculateFiscalYear } from "../../utils/helpers";
import AddInitiativeButton from '../../components/Initiatives/AddInitiativeButton';
import { useIframeContext } from '../../context/IframeContext';
import { useCAIAdminContext } from '../../context/CAIAdminContext';
import { useAdminContext } from '../../context/AdminContext';
import { useOrganizationContext } from '../../context/OrganizationContext';


function Initiatives() {
    const { isCAIAdmin } = useCAIAdminContext();
    const { isAdmin } = useAdminContext();
    const { isIframe } = useIframeContext();
    const { partnerName } = useOrganizationContext();
    const { initiatives, fetchInitiatives } = useContext(InitiativeDataContext);
    const [ado, setAdo] = useState('');
    const [year, setYear] = useState('');
    const [sortType, setSortType] = useState('');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const currFisYear = calculateFiscalYear(year); 
    const availableYears = calculateAvailableYears();
    const { filteredInitiatives } = useFilteredData(undefined, undefined, initiatives, undefined, searchTerm, sortType, year, ado);

    // Load filters from localStorage when the component mounts
    useEffect(() => {
        const savedFilters = JSON.parse(localStorage.getItem('initiativeFilters') || '{}');
        isAdmin ? setAdo(savedFilters.ado || '') : setAdo(partnerName);
        setYear(savedFilters.year || currFisYear.toString());
        setSearchTerm('');
        setSortType(savedFilters.sortType || '');
        fetchInitiatives();
    }, []);

    const handleSort = (type: string) => setSortType(type);
    const filterByYear = (year: string) => setYear(year);
    const filterByAdo = (partner_id: string) => setAdo(partner_id);
    const handleSearch = (searchTerm: string) => setSearchTerm(searchTerm);

    return ( 
        <div className="px-4 py-0 sm:py-4 md:py-4 lg:py-4">
            <ReportingDropdown/>
            <div className="mx-2 my-4 flex flex-wrap relative">
                <SearchInput onSearch={handleSearch}/>
                <BreSort handleSort={handleSort} currSortType={sortType} column='Initiative' dateType='(Recently Added)'/>
                {(isAdmin || isCAIAdmin) && // Render SelectADO only if in admin view
                    <SelectADO filterByAdo={filterByAdo} currAdo={ado} />
                }
                <FiscalYear filterByYear={filterByYear} availableYears={availableYears} currYear={year}/>
                <AddInitiativeButton />
                {isIframe && 
                    <div className="flex mb-1 absolute md:relative top-0 right-0 md:top-auto md:right-auto md:ml-auto transform md:transform-none">
                        <Expand/>
                    </div>
                }
            </div>
            <div className="mx-2">
                <InitiativeTable data={filteredInitiatives} year={year} ado={ado} sortType={sortType} />
            </div>
        </div>
    );
}

export default Initiatives;