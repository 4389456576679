import { useState, MouseEvent, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import carrotWhite from '../assets/carrot-white.svg';
import { useAdminContext } from '../context/AdminContext';

type ReportingType = 'targets' | 'initiatives' | 'summary' | 'interactions';

const reportingOptions: { [key: string]: string } = {
    targets: 'ADO Reporting: Targets',
    initiatives: 'ADO Reporting: Initiatives',
    summary: 'ADO Reporting: Dashboard & Reporting',
    interactions: 'ADO Reporting: Interactions'
  };

/**
 * ReportingDropdown Component
 *
 * Renders a button that displays the current reporting option.
 * When the button is clicked, it navigates to the main page ("/").
 * Clicking the carrot icon toggles the visibility of a custom dropdown menu with additional reporting options.
 * Selecting an option from the dropdown updates the state and navigates to the corresponding route.
 */
function ReportingDropdown() {
  const { isAdmin } = useAdminContext();
  const [reportingType, setReportingType] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // set the reporting type of the dropdown according to the current page
    const path = location.pathname.split('/');
    const reportingSegment = path.find(segment => segment.startsWith('commerce-'));
    if (reportingSegment) {
      const type = reportingSegment.replace('commerce-', '');
      if (type) {
        setReportingType(type as ReportingType);
      }
    }
  }, [location.pathname]);

  const handleOptionClick = (value: ReportingType) => {
    setReportingType(value);
    navigate(`/commerce-${value}`);
    setShowDropdown(false);
  };

  const handleButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    navigate('/');
  };

  const toggleDropdown = (event: MouseEvent<HTMLImageElement>) => {
    event.stopPropagation();
    setShowDropdown((prevShowDropdown) => !prevShowDropdown);
  };

  // Determine available options based on isAdmin
  const availableOptions: ReportingType[] = isAdmin 
    ? ['targets', 'initiatives', 'summary'] 
    : ['initiatives', 'interactions', 'summary'];

  return (
    <span className="sm:mt-0 mx-2 mb-4 mt-4 flex relative">
      <button
        onClick={handleButtonClick}
        className="w-full max-w-md px-4 py-2 text-white bg-color-cai-dashboard border border-solid border-color-cai-dashboard rounded-md focus:outline-none flex justify-between items-center"
      >
        {/* {reportingOptions[reportingType]} */}
        {reportingType ? reportingOptions[reportingType] : 'ADO Reporting'}
        <img
          src={carrotWhite}
          alt="Dropdown Carrot"
          onClick={toggleDropdown}
          className={`h-5 w-5 ml-2 cursor-pointer transition-transform duration-200 ${showDropdown ? 'transform rotate-180' : ''}`}
        />
      </button>
      {showDropdown && (
        <div
          className="absolute w-96 mt-2 py-2 text-white bg-color-cai-dashboard border border-solid border-color-cai-dashboard rounded-md z-10"
          style={{ top: '100%' }}
        >
          {availableOptions.map((value: ReportingType) => (
            <div
              key={value}
              onClick={() => handleOptionClick(value as ReportingType)}
              className="px-4 py-2 text-white hover:bg-blue-500 cursor-pointer"
            >
              {reportingOptions[value as ReportingType]}
            </div>
          ))}
        </div>
      )}
    </span>
  );
}

export default ReportingDropdown;