import { useAuth0 } from "@auth0/auth0-react";

const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <button className="mx-2 px-2 py-1 rounded-md text-color-cai-main font-semibold border border-solid border-gray-300 hover:bg-color-cai-lightGray focus:outline-none focus:border-blue-500" onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
      Log Out
    </button>
  );
};

export default LogoutButton;