import { useState, useEffect } from 'react';
import axios from 'axios';
import { InteractionSummaryData } from '../services/types';
import * as Sentry from '@sentry/react';

export const useFetchInteractionSummaryData = (year: string, partnerId: string) => {
    const [interactionSummary, setInteractionSummary] = useState<InteractionSummaryData | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
  
    useEffect(() => {
        // Only fetch the interaction summary data if the year (if applicable) is valid
        if (!year || !partnerId) {
            setLoading(false);
            return;
        }

        const fetchInteractionSummary = async () => {
            const apiUrl = 'https://backend-cai-commerce.vercel.app/api/getinteractionsummarydata';

            // Construct params object conditionally
            const params: Record<string, string> = { year };
            // Only add partnerId to params if it's not either of the Admin id's
            if (partnerId && partnerId !== '15' && partnerId !== '1') {
                params.partnerId = partnerId;
            }
            
            try {
            setLoading(true);
            const response = await axios.get(apiUrl, { params });
            if (response.status === 200) {
                setInteractionSummary(response.data.data);
            } else {
                // Log the error response to Sentry
                Sentry.captureMessage(`Error fetching interaction summary data: ${response.data.message}`, 'warning');
                setError('Failed to fetch interaction summary data.');
            }
            } catch (err) {
                // Log the caught error to Sentry
                Sentry.captureException(error);
                setError('An error occurred while fetching the data.');
            } finally {
                setLoading(false);
            }
        };
    
        fetchInteractionSummary();
    }, [year, partnerId]);
  
    return { interactionSummary, loading, error };
};
