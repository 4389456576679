import { useState, useEffect } from 'react';
import Select from 'react-select';

interface BreSortProps {
    handleSort: (sortType: string) => void;
    currSortType: string;
    column: string;
    dateType: string;
}

function BreSort({ handleSort, currSortType, column, dateType }: BreSortProps) {
    const [sortType, setSortType] = useState(currSortType || '');

    useEffect(() => {
        setSortType(currSortType);
    }, [currSortType]);

    // const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    //     const selectedSortType = event.target.value;
    //     setSortType(selectedSortType);
    //     handleSort(selectedSortType); // Call the sort function passed as a prop
    // };

    const handleSelectChange = (selectedOption: { value: string; label: string } | null) => {
        const selectedSortType = selectedOption ? selectedOption.value : '';
        setSortType(selectedSortType);
        handleSort(selectedSortType);
    };

    const sortOptions = [
        { value: 'alphabetical', label: `${column} (A-Z)` },
        { value: 'date', label: `Date ${dateType}` },
    ];

    return ( 
        <div className='sm:mt-0 mt-2'>
            <Select
                options={sortOptions}
                value={sortOptions.find(option => option.value === sortType)}
                onChange={handleSelectChange}
                placeholder="Sort"
                isClearable
                isSearchable={false}
                styles={{
                    control: (base) => ({
                        ...base,
                        height: '2rem',
                        minHeight: '2rem',
                        width: '8rem',
                        borderColor: '#E5E7EB',
                        borderRadius: '4px',
                        marginRight: '1rem',
                        boxShadow: 'none',
                        cursor: 'pointer',
                    }),
                    valueContainer: (base) => ({
                        ...base,
                        padding: '0 0.5rem',
                        minHeight: '2rem',
                        height: '2rem',
                    }),
                    placeholder: (base) => ({
                        ...base,
                        color: '#3A3C41',
                        fontSize: '0.875rem',
                        fontWeight: 'bold',
                    }),
                    singleValue: (base) => ({
                        ...base,
                        color: '#3A3C41',
                        fontSize: '0.875rem',
                        fontWeight: 'bold',
                    }),
                    dropdownIndicator: (base) => ({
                        ...base,
                        padding: '0',
                    }),
                    clearIndicator: (base) => ({
                        ...base,
                        padding: '0',
                    }),
                    indicatorsContainer: (base) => ({
                        ...base,
                        padding: '0 0.25rem',
                    }),
                    option: (base, state) => ({
                        ...base,
                        fontSize: '0.875rem',
                        backgroundColor: state.isSelected ? '#626262' : state.isFocused ? '#CCCCCC' : 'white',
                        borderRadius: '4px',
                        borderBottom: '1px solid #E2E8F0',
                        color: state.isSelected ? 'white' : '#3A3C41',
                        '&:hover': {
                            backgroundColor: '#CCCCCC',
                        },
                    }),
                    menu: (base) => ({
                        ...base,
                        position: 'absolute',
                        left: '0',
                        top: '1.5rem',
                        width: '8rem',
                        zIndex: 9999,
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                    }),
                    menuList: (base) => ({
                        ...base,
                        padding: '4px',
                    }),
                }}
            />
        </div>
    );
}

export default BreSort;


// Legacy select element
 // <span className="relative flex items-center h-12">
//     {sortType && (<button
//         className="absolute right-auto top-10 p-1 text-xs text-gray-400 outline-none focus:outline-none"
//         onClick={clearSelection}
//     >
//         Clear Selection
//     </button>)}
//     <select
//         id="sortType"
//         value={sortType}
//         onChange={handleSelectChange}
//         className="text-color-cai-gray text-sm font-bold pr-6 pl-3 w-24 border border-solid border-lightGray rounded-md focus:outline-none focus:border-blue-500 h-8 appearance-none text-left hover:cursor-pointer truncate"
//     >
//         <option value="" disabled className="text-color-cai-gray">Sort</option>
//         <option value="alphabetical">{column} (A-Z)</option>
//         <option value="date">Date {dateType}</option>
//     </select>
//     <img src={carrot} alt="Dropdown Carrot" className="relative h-4 w-4 top-0 right-7 pointer-events-none" />
// </span>