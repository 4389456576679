// Create a context to manage isAdmin state - This is the view for Commerce Admin (All ADO's)
import { createContext, useContext, useEffect, useState } from 'react';
import { AdminContextType, AdminProviderProps } from '../services/types';
import { useOrganizationContext } from './OrganizationContext';

// Define a context with initial values matching AdminContextType
const defaultAdminContext: AdminContextType = {
    isAdmin: false,
    setIsAdmin: () => {}
};

// Define a context
const AdminContext = createContext<AdminContextType>(defaultAdminContext);

// Define a provider for the context
export const AdminProvider = ({ children }: AdminProviderProps) => {
    const [isAdmin, setIsAdmin] = useState(false); // Initialize isAdmin state
    const { partnerId } = useOrganizationContext(); // Access partnerId from OrganizationContext

    useEffect(() => {
        // Update isAdmin based on the partnerId
        if (partnerId === 15) {
            setIsAdmin(true);
        } else {
            setIsAdmin(false);
        }
    }, [partnerId]); // Re-run effect when partnerId changes

    return (
        <AdminContext.Provider value={{ isAdmin, setIsAdmin }}>
            {children}
        </AdminContext.Provider>
    );
};

// Export a hook to use the context
export const useAdminContext = () => useContext(AdminContext);
