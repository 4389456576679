// Create a context to manage state for CAI Admin (god mode)
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useOrganizationContext } from './OrganizationContext';

interface CAIAdminContextType {
    isCAIAdmin: boolean;
    setIsCAIAdmin: React.Dispatch<React.SetStateAction<boolean>>;
}

interface CAIAdminProviderProps {
    children: ReactNode
}

// Define a context with initial values matching CAIAdminContextType
const defaultCAIAdminContext: CAIAdminContextType = {
    isCAIAdmin: false,
    setIsCAIAdmin: () => {}
};

// Define a context
const CAIAdminContext = createContext<CAIAdminContextType>(defaultCAIAdminContext);

// Define a provider for the context
export const CAIAdminProvider = ({ children }: CAIAdminProviderProps) => {
    const [isCAIAdmin, setIsCAIAdmin] = useState(false); // Initialize isCAIAdmin state
    const { partnerId } = useOrganizationContext(); // Access partnerId from OrganizationContext

    useEffect(() => {
        // Update isCAIAdmin based on the partnerId
        if (partnerId === 1) {
            setIsCAIAdmin(true);
        } else {
            setIsCAIAdmin(false);
        }
    }, [partnerId]); // Re-run effect when partnerId changes

    return (
        <CAIAdminContext.Provider value={{ isCAIAdmin, setIsCAIAdmin }}>
            {children}
        </CAIAdminContext.Provider>
    );
};

// Export a hook to use the context
export const useCAIAdminContext = () => useContext(CAIAdminContext);