import { useLocation, useNavigate } from "react-router-dom";
import { ChangeEvent, forwardRef, useContext, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import leftArrow from '../../assets/left-arrow.svg';
import calendarIcon from '../../assets/calendar.svg';
import Select from 'react-select';
import { getTypeOptions } from "../../utils/formOptions";
import { customStyles } from "../../utils/customFormStyles";
import FormErrorModal from "../../components/FormErrorModal";
import validator from "validator";
import { Loader } from '@googlemaps/js-api-loader';
// import AdoDataContext from "../../context/AdoDataContext";
import TypesContext from "../../context/TypesContext";
import { useIframeContext } from "../../context/IframeContext";
import Expand from "../../components/Expand";
import { cleanStringValue, formatPhoneNumber, validateAndCleanCoordinate } from "../../utils/helpers";
import { UploadInteractionData } from "../../services/types";

declare global {
    interface Window {
        google: any;
    }
}

interface CustomCalendarProps {
    value?: string | null; // Assuming value is a string (formatted date) or null
    onClick?: () => void; // onClick handler for the button
}

type OptionType = {
    value: string | number;
    label: string;
};


function EditInteractionUpload() {
    // Stateful data
    const { isIframe } = useIframeContext();
    const { typesData } = useContext(TypesContext);

    const [interactions, setInteractions] = useState<UploadInteractionData[]>([]);
    const [initiative, setInitiative] = useState("");
    const [initiativeId, setInitiativeId] = useState<number | null>(null);
    const [staff, setStaff] = useState("");
    const [interactionType, setInteractionType] = useState("");
    const [assistanceType, setAssistanceType] = useState<string[]>([]);
    const [interactionDate, setInteractionDate] = useState<Date | null>(null);
    const [note, setNote] = useState("");
    const [business, setBusiness] = useState("");
    const [businessLegal, setBusinessLegal] = useState("");
    const [ubi, setUbi] = useState("");
    const [hasUBI, setHasUBI] = useState(true);
    const [busClass, setBusClass] = useState<string[]>([]);
    const [employee, setEmployee] = useState("");
    const [revenue, setRevenue] = useState("");
    const [address, setAddress] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [first, setFirst] = useState("");
    const [last, setLast] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [contactType, setContactType] = useState("");
    const [hasContact, setHasContact] = useState(true);
    const [isSubmitted, setIsSubmitted] = useState(true); // default to true to show invalid fields on load
    const [showModal, setShowModal] = useState(false);
    const [formErrorMsg, setFormErrorMsg] = useState('');

    const autocompleteRef = useRef<HTMLInputElement>(null);

    const location = useLocation();
    const navigate = useNavigate();
    const { file, status, id, initiative: initName, initiativeId: initId } = location.state;

    const isComplete = status === 'Complete';

    // Create select options for initiatives and industries
    const [interactionTypeOptions, setInteractionTypeOptions] = useState<OptionType[]>([]);
    const [contactTypeOptions, setContactTypeOptions] = useState<OptionType[]>([]);
    const [classificationTypeOptions, setClassificationTypeOptions] = useState<OptionType[]>([]);
    const [assistanceTypeOptions, setAssistanceTypeOptions] = useState<OptionType[]>([]);


    useEffect(() => {

        if (location.state && location.state.data) {
            
            const data: any = location.state.data[id];
            setInteractions(location.state.data);
            console.log('data:', data);

            // Parse and validate the date
            const tempDate = new Date(data.date);
            const parsedDate = !isNaN(tempDate.getTime()) ? tempDate : null;

            // Helper function to find the ID of a type by its name
            const findTypeIdByName = (typeArray: any[], typeName: string) => {
                const type = typeArray.find(
                    (item: { name: string }) => item.name?.trim().toLowerCase() === typeName?.trim().toLowerCase()
                );
                return type ? type.id.toString() : null;
            };

             // Map interaction type
            const interactionTypeId = findTypeIdByName(typesData.interaction_types, data.interaction_type);
            const updatedInteractionTypeOptions = getTypeOptions(typesData, 'interaction_types', interactionTypeId);


            // Map contact type
            const contactTypeId = findTypeIdByName(typesData.contact_types, data.contact_type);
            const updatedContactTypeOptions = getTypeOptions(typesData, 'contact_types', contactTypeId);


            // Process business classification
            const businessClassificationTypes = data.business_classification
            .replace(/{|}/g, "")
            .replace(/"/g, "")
            .split(",")
            .map((classification: any) => classification.trim().toLowerCase());

            const updatedClassificationTypeOptions = getTypeOptions(
                typesData,
                'classification_types',
                businessClassificationTypes.map((classification: string) => findTypeIdByName(typesData.classification_types, classification))
            );

            const mappedBusClass = businessClassificationTypes.map((classification: string) => {
                const classificationId = findTypeIdByName(typesData.classification_types, classification);
                return updatedClassificationTypeOptions.find((bc: any) => bc.value === Number(classificationId))?.value || classificationId;
            });
            

            // Process assistance types
            const assistanceTypes = data.assistance
            .replace(/{|}/g, "")
            .replace(/"/g, "")
            .split(",")
            .map((assistance: any) => assistance.trim().toLowerCase());

            const updatedAssistanceTypeOptions = getTypeOptions(
                typesData,
                'assistance_types',
                assistanceTypes.map((assistance: string) => findTypeIdByName(typesData.assistance_types, assistance))
            );

            const mappedAssistanceType = assistanceTypes.map((assistance: string) => {
                const assistanceId = findTypeIdByName(typesData.assistance_types, assistance);
                return updatedAssistanceTypeOptions.find((at: any) => at.value === Number(assistanceId))?.value || assistanceId;
            });

            // Update select options and ensure inactive/legacy types are added to options
            setInteractionTypeOptions(getTypeOptions(typesData, 'interaction_types', interactionTypeId));

            setContactTypeOptions(getTypeOptions(typesData, 'contact_types', contactTypeId));

            setClassificationTypeOptions(getTypeOptions(
                typesData,
                'classification_types',
                businessClassificationTypes.map((classification: string) => findTypeIdByName(typesData.classification_types, classification))
            ));
            
            setAssistanceTypeOptions(getTypeOptions(
                typesData,
                'assistance_types',
                assistanceTypes.map((assistance: string) => findTypeIdByName(typesData.assistance_types, assistance))
            ));


            // Process contact type and interaction type
            const mappedContactType = data.contact_type
                ? updatedContactTypeOptions.find((option: { value: number; label: string }) => option.label.trim().toLowerCase() === data.contact_type.trim().toLowerCase())?.value || ''
                : '';
            
            const mappedInteractionType = data.interaction_type
                ? updatedInteractionTypeOptions.find((option: { value: number; label: string }) => option.label.trim().toLowerCase() === data.interaction_type.trim().toLowerCase())?.value || ''
                : '';
            

            // Validate and clean latitude, longitude, people employed, and revenue values
            const latitude = validateAndCleanCoordinate(data.latitude, -90, 90);
            const longitude = validateAndCleanCoordinate(data.longitude, -180, 180);
            const employee = cleanStringValue(data.people_employed);
            const revenue = cleanStringValue(data.gross_revenue);


            // Update the state with the fetched data
            setInitiative(initName);
            setInitiativeId(initId);
            setStaff(data.staff_name || '');
            setInteractionType(mappedInteractionType || '');
            setAssistanceType(mappedAssistanceType || []);
            setInteractionDate(parsedDate);
            setNote(data.note || '');
            setBusiness(data.business_trade_name || '');
            setBusinessLegal(data.business_legal_name || '');
            setUbi(data.business_ubi || '');
            setBusClass(mappedBusClass || []);
            setEmployee(employee);
            setRevenue(revenue);
            setLatitude(latitude);
            setLongitude(longitude);
            setAddress(data.address || '');
            setFirst(data.first_name || '');
            setLast(data.last_name || '');
            setPhone(data.phone || '');
            setEmail(data.email || '');
            setContactType(mappedContactType || '');
            setHasContact(!!data.phone || !!data.email || !!data.first_name || !!data.last_name);
            setHasUBI(!!data.business_ubi);
        }

    }, [location.state]);

    // load script for google maps autocomplete widget and set address state to selected location
    useEffect(() => {
        
        const loader = new Loader({
            apiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
            version: 'weekly',
            libraries: ['places'], // Include 'places' library for geocoding and places autocomplete
        });

        loader.load().then(() => {
            if (autocompleteRef.current && window.google && window.google.maps) {
                const autocomplete = new window.google.maps.places.Autocomplete(autocompleteRef.current, {
                    types: ['geocode'], // Specify 'geocode' to get all possible addresses
                    componentRestrictions: { country: 'us' } // Restrict results to a specific country if needed
                });

                autocomplete.addListener('place_changed', () => {
                    const place = autocomplete.getPlace();
                    if (place.formatted_address) {
                        setAddress(place.formatted_address);

                        const lat = place.geometry.location.lat();
                        const lng = place.geometry.location.lng();

                        setLatitude(lat.toString());
                        setLongitude(lng.toString());

                        console.log('Latitude:', lat);
                        console.log('Longitude:', lng);
                    }
                });
            }
        });

    }, []);

    // Change handlers for form fields
    const handleInputChange = (setter: React.Dispatch<React.SetStateAction<string>>) => (event: ChangeEvent<HTMLInputElement>) => {  
        setter(event.target.value);
    };

    const handleUbiToggle = () => setHasUBI(prevState => !prevState);
    const handleContactToggle = () => setHasContact(prevState => !prevState);

    // Custom calendar reference component for React Datepicker see docs: (https://reactdatepicker.com/#example-custom-input)
    const CustomCalendar = forwardRef<HTMLButtonElement, CustomCalendarProps>(({ value, onClick }, ref) => (
        <button className={`w-40 pl-4 pr-4 py-2 h-10 text-left border border-solid ${!value ? 'border-red-500' : 'border-tableGray'} rounded-md focus:outline-none focus:border-blue-500 appearance-none`} onClick={onClick} ref={ref}>
        {value || 'Select Date'}
        </button>
    ));

    const handleInteractionDateChange = (date: Date | null) => {
        setInteractionDate(date);
    }

    const handleTextAreaChange = (setter: React.Dispatch<React.SetStateAction<string>>) => (event: ChangeEvent<HTMLTextAreaElement>) => {
        setter(event.target.value);
    };

    // Change handler factory for react-select components
    const createSelectChangeHandler = (setter: React.Dispatch<React.SetStateAction<string>>) => (selectedOption: any) => {
        setter(selectedOption ? selectedOption.value : '');
    };

    const createMultiSelectChangeHandler = (setter: React.Dispatch<React.SetStateAction<string[]>>) => (selectedOption: any) => {
        console.log('selected option:', selectedOption);
        const selection = selectedOption ? selectedOption.map((option: any) => option.value) : [];
        console.log('selection:', selection)
        setter(selection);
    };

    const validateForm = () => {
        
        const formattedPhone = formatPhoneNumber(phone);  // Format the phone number first

        const validations = [
            { 
                field: formattedPhone, 
                regex: /^\(\d{3}\) \d{3}-\d{4}$/, 
                message: 'Please enter a valid phone number.' 
            },
            { field: email, validator: validator.isEmail, message: 'Please enter a valid email address.' },
            { field: employee, validator: validator.isNumeric, message: 'Please enter a valid number for Number of Employees.' },
            { 
                field: ubi, 
                regex: /^[\d-]+$/,  // Supports numbers and dashes
                message: 'Please enter a valid UBI.' 
            },
            { field: revenue, validator: validator.isNumeric, message: 'Please enter a valid number for Gross Revenue.' },
            { field: latitude, validator: validator.isNumeric, message: 'Please enter a valid number for Latitude.' },
            { field: longitude, validator: validator.isNumeric, message: 'Please enter a valid number for Longitude.' },
        ];
    
        for (let { field, regex, validator, message } of validations) {
            if (field) {
                if (regex && typeof regex === 'object' && !regex.test(field)) {
                    setFormErrorMsg(message);
                    setShowModal(true);
                    return false;
                } else if (validator && typeof validator === 'function' && !validator(field.toString())) {
                    setFormErrorMsg(message);
                    setShowModal(true);
                    return false;
                }
            }
        }
        // form is valid
        return true; 
    };

    ///////////
    //// Event handlers for navigation

    const handleCancel = () => {
        console.log('cancelled event');
        navigateBack();
    }

    const handleSave = () => {
        if (!validateForm()) {
            return;
        }

        setIsSubmitted(true);
        
        // Format the date
        let formattedDate = '';
        if (interactionDate) {
            const date = new Date(interactionDate);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
            const day = String(date.getDate()).padStart(2, '0');
            formattedDate = `${year}-${month}-${day}`;
        }

        // Clean and format the number fields, only if the data is a string
        const cleanNumberField = (value: any): string => {
            if (typeof value === 'string') {
                return value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
            }
            return value; // Return as is if not a string
        };

        // Clean and format the number fields
        const cleanedPeopleEmployed = cleanNumberField(employee);
        const cleanedGrossRevenue = cleanNumberField(revenue);

        // Convert ID values back to the expected labels
        const formattedClassifications = busClass.map(id => {
            const matchedOption = classificationTypeOptions.find((option: any) => option.value === id);
            return matchedOption ? matchedOption.label : '';
        });

        const formattedAssistances = assistanceType.map(id => {
            const matchedOption = assistanceTypeOptions.find((option: any) => option.value === id);
            return matchedOption ? matchedOption.label : '';
        });

         // Format as JSON strings with curly braces and double quotes
        // Format as JSON strings with correct escaping
        const formattedClassificationsString = `{${formattedClassifications.map(label => `"${label}"`).join(',')}}`;
        const formattedAssistancesString = `{${formattedAssistances.map(label => `"${label}"`).join(',')}}`;




        // Reverse mapping for contact_type and interaction_type
        const mappedContactType = contactTypeOptions.find((option: any) => option.value === contactType)?.label || '';
        const mappedInteractionType = interactionTypeOptions.find((option: any) => option.value === interactionType)?.label || '';

        // Format the data for saving
        const updatedInteraction = {
            staff_name: staff || "",
            business_trade_name: business || "",
            business_legal_name: businessLegal || "",
            business_ubi: ubi || "",
            interaction_type: mappedInteractionType || "",
            people_employed: cleanedPeopleEmployed || "",
            gross_revenue: cleanedGrossRevenue || "",
            contact_type: mappedContactType || "",
            first_name: first || "",
            last_name: last || "",
            phone: phone || "",
            email: email || "",
            business_classification: formattedClassificationsString,
            assistance: formattedAssistancesString,
            date: formattedDate || "",
            note: note || "",
            longitude: longitude || "",
            latitude: latitude || "",
            address: address || ""
        };

        console.log('updated interaction:', updatedInteraction)

        // Update the interaction in the array
        const updatedInteractions = [...interactions];
        updatedInteractions[id] = updatedInteraction; 
        
        if (interactionType && assistanceType && interactionDate && business && (!hasUBI || (hasUBI && ubi))) {
            navigate('/upload-interactions/validate', { state: { data: updatedInteractions, initiativeId, initiative, file, id } });
        }
    };

    const navigateBack = () => {
        navigate(-1);
    };

    return ( 
    <div className="m-2 p-4">
        {/* Back to Initiatives button */}
        <div className='flex justify-between'>
            <button onClick={navigateBack} className="flex items-center mb-4 font-semibold text-sm">
                <img src={leftArrow} alt='left arrow' className='h-3 w-4 mr-3'></img>
                Back to Upload
            </button>
            {isIframe && 
                <div className="flex ml-auto mb-4">
                    <Expand/>
                </div>
            }
        </div>

        {/* Edit Interaction Details */}
        <div className="mb-8 border border-solid border-color-tableGray rounded-md">
            <div className='border-b border-solid border-color-tableGray mb-5'>
                <h2 className="text-sm font-semibold mt-3 mb-3 ml-7">{`${isComplete ? 'Interaction Details' : 'Edit Interaction Details'}`}</h2>
            </div>
            
            <div className='grid grid-cols-1 md:grid-cols-2 gap-1 ml-3'>             
                <div className="ff-container">
                    <label htmlFor="inputStaffName" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                        Staff Name:
                    </label>
                    <div className="flex flex-col w-full">
                        <input
                            id="inputStaffName"
                            type="text"
                            value={staff}
                            required
                            placeholder='Enter Staff Name'
                            onChange={handleInputChange(setStaff)}
                            className={`text-sm pl-4 w-full pr-4 py-2 border border-solid ${isSubmitted && !staff ? 'border-red-500' : 'border-tableGray'} rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none`}
                            disabled={isComplete}
                        />
                        <p className="text-red-500 text-sm">{`${isSubmitted && !staff ? 'Staff Name is required.' : ''}`}</p>
                    </div>
                </div>
                <br></br>
                <div className="ff-container">
                    <label htmlFor="selectInteractionType" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                        Interaction Type:
                    </label>
                    <div className="relative w-full">
                        <div className="flex flex-col w-full">
                                <Select
                                    id="selectType"
                                    value={interactionTypeOptions.find((option: any) => option.value === interactionType)}
                                    onChange={createSelectChangeHandler(setInteractionType)}
                                    options={interactionTypeOptions}
                                    placeholder="Select Type"
                                    styles={customStyles(isSubmitted, interactionType)}
                                    isClearable
                                    isDisabled={isComplete}
                                />
                            <p className="text-red-500 text-sm">{`${isSubmitted && !interactionType ? 'Interaction Type is required.' : ''}`}</p>
                        </div>
                    </div>
                </div>
                <div className="ff-container">
                    <label htmlFor="selectAssistanceType" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                        Assistance Type:
                    </label>
                    <div className="relative w-full">
                        <div className="flex flex-col w-full">
                                <Select
                                    id="selectType"
                                    value={assistanceTypeOptions.filter((option: any) => assistanceType.includes(option.value))}
                                    onChange={createMultiSelectChangeHandler(setAssistanceType)}
                                    options={assistanceTypeOptions}
                                    placeholder="Select Type"
                                    styles={customStyles(isSubmitted, assistanceType)}
                                    isClearable={false}
                                    isMulti
                                    isDisabled={isComplete}
                                />
                            <p className="text-red-500 text-sm">{`${isSubmitted && assistanceType.length === 0 ? 'Assistance Type is required.' : ''}`}</p>
                        </div>
                    </div>
                </div>
                <div className="ff-container">
                    <label htmlFor="inputInteractionDate" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                        Interaction Date:
                    </label>
                    <div className='flex flex-col w-full'>
                        <div className="relative w-full text-sm">
                            <DatePicker
                                selected={interactionDate}
                                placeholderText='Choose Date'
                                dateFormat='MM/dd/yyyy'
                                onChange={handleInteractionDateChange}
                                customInput={<CustomCalendar/>}
                                required
                                disabled={isComplete}
                            />
                            <img src={calendarIcon} alt="calendar icon" className="absolute left-32 bottom-3 h-4 w-4 pointer-events-none" />
                        </div>
                        <p className="text-red-500 text-sm">{`${isSubmitted && !interactionDate ? 'Interaction Date is required.' : ''}`}</p>
                    </div>
                </div>
                <div className="flex flex-col sm:flex-row items-start sm:items-start my-4 mx-4 sm:mr-20">
                    <label htmlFor="inputNote" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                        Note:
                    </label>
                    <div className='flex flex-col w-full'>
                        <textarea
                            id="inputNote"
                            value={note}
                            placeholder='Add Note'
                            onChange={handleTextAreaChange(setNote)}
                            className="text-sm pl-4 w-full pr-4 py-2 border border-solid border-tableGray rounded-[4px] focus:outline-none focus:border-blue-500 h-28 appearance-none"
                            disabled={isComplete}
                        />
                    </div>
                </div>
            </div>
        </div>

        {/* Edit Business Details */}
        <div className="mb-8 border border-solid border-color-tableGray rounded-[4px]">
            <div className='border-b border-solid border-color-tableGray mb-5'>
                <h2 className="text-sm font-semibold mt-3 mb-3 ml-7">{`${isComplete ? 'Business Details' : 'Edit Business Details'}`}</h2>
            </div>
            
            <div className='grid grid-cols-1 md:grid-cols-2 gap-1 ml-3'>
                
                {/* Business Name */}
                <div className="ff-container">
                    <label htmlFor="inputBusiness" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                        Business Name:
                    </label>
                    <div className='flex flex-col w-full'>
                        <input
                            id="inputBusiness"
                            type="text"
                            value={business}
                            placeholder='Enter Business Name'
                            onChange={handleInputChange(setBusiness)}
                            className={`text-sm pl-4 w-full pr-4 py-2 border border-solid ${isSubmitted && !business ? 'border-red-500' : 'border-tableGray'} rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none`}
                            disabled={isComplete}
                        />
                        <p className="text-red-500 text-sm">{`${isSubmitted && !business ? 'Business Name is required.' : ''}`}</p>
                    </div>
                </div>
                {/* Business Legal Name */}
                <div className="ff-container">
                    <label htmlFor="inputBusinessLegal" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold text-balance w-48">
                        Business Legal Name:
                    </label>
                    <input
                        id="inputBusinessLegal"
                        type="text"
                        value={businessLegal}
                        placeholder='Enter Business Legal Name'
                        onChange={handleInputChange(setBusinessLegal)}
                        className="text-sm pl-4 w-full pr-4 py-2 border border-solid border-tableGray rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none"
                        disabled={isComplete}
                    />
                </div>
                {/* No UBI available */}
                <div className="ff-container h-14">
                    <label htmlFor="inputHasUbi" className="text-sm mb-2 sm:mb-0 sm:mr-6 font-semibold w-36">
                        No UBI Available:
                    </label>
                    <button
                        id="inputHasUbi"
                        type="button"
                        onClick={handleUbiToggle}
                        className={`w-14 h-7 rounded-full border border-gray-300 focus:outline-none relative overflow-hidden transition-all duration-300 ${!hasUBI ? 'bg-blue-500' : 'bg-gray-300'} focus:ring-2 focus:ring-blue-500 focus:outline-none`}
                        disabled={isComplete}
                    >
                        <span
                            className={`absolute left-0 top-0 bg-white w-7 h-7 rounded-full transition-all duration-300 transform ${!hasUBI ? 'translate-x-7' : 'translate-x-0'}`}
                        />
                    </button>
                </div>
                {!hasUBI &&
                    <br></br>
                }
                {/* UBI */}
                {hasUBI && 
                <div className="ff-container">
                    <label htmlFor="inputUBI" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                        Business UBI:
                    </label>
                    <div className="flex flex-col w-full">
                        <p className="text-sm text-color-cai-dashboard">
                            Don't know the Business UBI? You can look it up manually <a href="https://secure.dor.wa.gov/gteunauth/_/" className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">here</a>.
                        </p>
                        <input
                            id="inputUBI"
                            type="text"
                            value={ubi}
                            placeholder='Enter Business UBI'
                            onChange={handleInputChange(setUbi)}
                            className={`text-sm pl-4 w-full pr-4 py-2 border border-solid  rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none ${isSubmitted && hasUBI && !ubi ? 'border-red-500': 'border-tableGray'}`}
                            disabled={isComplete}
                        />
                        <p className="text-red-500 text-sm">{`${isSubmitted && hasUBI && !ubi ? 'UBI is required. Please Enter Business UBI.' : ''}`}</p>
                    </div>
                </div>
                }
                <div className="ff-container">
                    <label htmlFor="selectBusinessClass" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                        Business Classification:
                    </label>
                    <div className="relative w-full">
                        <div className="flex flex-col w-full">
                            <Select
                                id="selectType"
                                value={classificationTypeOptions.filter((option: any) => busClass.includes(option.value))}
                                onChange={createMultiSelectChangeHandler(setBusClass)}
                                options={classificationTypeOptions}
                                placeholder="Select Type"
                                styles={customStyles()}
                                isClearable={false}
                                isMulti
                                isDisabled={isComplete}
                            />
                        </div>
                    </div>
                </div>
                <div className="ff-container">
                    <label htmlFor="inputEmployee" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                        No. of Employees:
                    </label>
                    <input
                        id="inputEmployee"
                        type="text"
                        value={employee}
                        placeholder='Enter No. of Employees'
                        onChange={handleInputChange(setEmployee)}
                        className="text-sm pl-4 w-full pr-4 py-2 border border-solid border-tableGray rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none"
                        disabled={isComplete}
                    />
                </div>
                <div className="ff-container">
                    <label htmlFor="inputRevenue" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                        Gross Revenue:
                    </label>
                    <input
                        id="inputRevenue"
                        type="text"
                        value={revenue}
                        placeholder='Enter Gross Revenue'
                        onChange={handleInputChange(setRevenue)}
                        className="text-sm pl-4 w-full pr-4 py-2 border border-solid border-tableGray rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none"
                        disabled={isComplete}
                    />
                </div>
                <br></br>
                <div className="ff-container">
                    <label htmlFor="inputAddress" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                        Address:
                    </label>
                    <div className='relative w-full flex flex-col'>
                        <input
                            id="inputAddress"
                            ref={autocompleteRef}
                            type="text"
                            value={address}
                            placeholder='Enter Address'
                            onChange={handleInputChange(setAddress)}
                            className="text-sm w-full pr-20 pl-2 py-2 border border-solid border-tableGray rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none truncate"
                            disabled={isComplete}
                        />
                    </div>
                </div>
                <br></br>
                <div className="ff-container">
                    <label htmlFor="inputLatitude" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                        Latitude:
                    </label>
                    <input
                        id="inputLatitude"
                        type="text"
                        value={latitude}
                        placeholder='Enter Latitude'
                        onChange={handleInputChange(setLatitude)}
                        className="text-sm pl-4 w-full pr-4 py-2 border border-solid border-tableGray rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none"
                        disabled={isComplete}
                    />
                </div>
                <div className="ff-container">
                    <label htmlFor="inputLongitude" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                        Longitude:
                    </label>
                    <input
                        id="inputLongitude"
                        type="text"
                        value={longitude}
                        placeholder='Enter Longitude'
                        onChange={handleInputChange(setLongitude)}
                        className="text-sm pl-4 w-full pr-4 py-2 border border-solid border-tableGray rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none"
                        disabled={isComplete}
                    />
                </div>
            </div>
        </div>

        {/* Edit Business Contact */}
        <div className="mb-8 border border-solid border-color-tableGray rounded-[4px]">
            <div className='border-b border-solid border-color-tableGray mb-5'>
                <h2 className="text-sm font-semibold mt-3 mb-3 ml-7">Edit Business Contact</h2>
            </div>
            
            <div className='grid grid-cols-1 md:grid-cols-2 gap-1 ml-3'>
                {/* No Contact available */}
                <div className="ff-container">
                    <label htmlFor="inputContact" className="text-sm mb-2 sm:mb-0 sm:mr-6 font-semibold w-36">
                        No Contact:
                    </label>
                    <button
                        id="inputContact"
                        type="button"
                        onClick={handleContactToggle}
                        className={`w-14 h-7 rounded-full border border-gray-300 focus:outline-none relative overflow-hidden transition-all duration-300 ${!hasContact ? 'bg-blue-500' : 'bg-gray-300'} focus:ring-2 focus:ring-blue-500 focus:outline-none`}
                        disabled={isComplete}
                    >
                        <span
                            className={`absolute left-0 top-0 bg-white w-7 h-7 rounded-full transition-all duration-300 transform ${!hasContact ? 'translate-x-7' : 'translate-x-0'}`}
                        />
                    </button>
                </div>
                <br></br>

                 {/* First Name */}
                {hasContact &&    
                    <div className="ff-container">
                        <label htmlFor="inputFirst" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                            First Name:
                        </label>
                        <input
                            id="inputFirst"
                            type="text"
                            value={first}
                            placeholder='Enter First Name'
                            onChange={handleInputChange(setFirst)}
                            className="text-sm pl-4 w-full pr-4 py-2 border border-solid border-tableGray rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none"
                            disabled={isComplete}
                        />
                    </div>
                }
                
                {/* Last Name */}
                {hasContact && 
                    <div className="ff-container">
                        <label htmlFor="inputLast" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                            Last Name:
                        </label>
                        <input
                            id="inputLast"
                            type="text"
                            value={last}
                            placeholder='Enter Last Name'
                            onChange={handleInputChange(setLast)}
                            className="text-sm pl-4 w-full pr-4 py-2 border border-solid border-tableGray rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none"
                            disabled={isComplete}
                        />
                    </div>
                }
                
                {/* Contact Phone */} 
                {hasContact &&
                    <div className="ff-container">
                        <label htmlFor="inputPhone" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                            Contact Phone:
                        </label>
                        <input
                            id="inputPhone"
                            type="text"
                            value={phone}
                            placeholder='Enter Contact Phone'
                            onChange={handleInputChange(setPhone)}
                            className="text-sm pl-4 w-full pr-4 py-2 border border-solid border-tableGray rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none"
                            disabled={isComplete}
                        />
                    </div>
                }
                {/* Contact Email */}
                {hasContact &&
                    <div className="ff-container">
                        <label htmlFor="inputEmail" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                            Contact Email:
                        </label>
                        <input
                            id="inputEmail"
                            type="text"
                            value={email}
                            placeholder='Enter Contact Email'
                            onChange={handleInputChange(setEmail)}
                            className="text-sm pl-4 w-full pr-4 py-2 border border-solid border-tableGray rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none"
                            disabled={isComplete}
                        />
                    </div>
                }
                {/* Contact Type */}
                {hasContact &&
                    <div className="ff-container">
                        <label htmlFor="selectContactType" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                            Contact Type:
                        </label>
                        <div className="relative w-full">
                            <Select
                                id="selectType"
                                value={contactTypeOptions.find((option: any) => option.value === contactType)}
                                onChange={createSelectChangeHandler(setContactType)}
                                options={contactTypeOptions}
                                placeholder="Select Type"
                                styles={customStyles()}
                                isClearable
                                isDisabled={isComplete}
                            />
                        </div>
                    </div>
                }
            </div>
            <FormErrorModal
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                message={formErrorMsg}
            />
            <div className="flex items-center border-solid border-t border-color-tableGray">
                <button
                    id="save-interaction"
                    onClick={handleSave}
                    className={`ml-7 mt-5 mb-3 w-36 h-8 text-center text-white bg-color-cai-dashboard border border-solid border-color-cai-dashboard rounded-[4px] focus:border-blue-500 focus:outline-none ${isComplete ? 'cursor-not-allowed opacity-50' : 'hover:bg-blue-400 hover:border-blue-600 cursor-pointer'}`}
                    disabled={isComplete}
                >
                    Save
                </button>
                <button
                    id="cancel-interaction"
                    onClick={handleCancel}
                    className="ml-7 mt-5 mb-3 w-36 h-8 text-center text-color-cai-dashboard bg-white border border-solid border-color-cai-dashboard rounded-[4px] focus:outline-none focus:border-red-400 hover:bg-red-400 hover:border-red-500 hover:text-white cursor-pointer"
                >
                    Cancel
                </button>
            </div>
        </div> 
    </div> 
    );
}

export default EditInteractionUpload;