import { ChangeEvent, useState } from 'react';
import searchIcon from '../assets/search-icon.svg';

interface SearchInputProps {
  onSearch: (searchTerm: string) => void;
}

function SearchInput({ onSearch }: SearchInputProps) {
  const [searchTerm, setSearchTerm] = useState('');

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    onSearch(searchTerm);
  };

  let inputClass = 'w-full text-sm pl-14 pr-4 py-2 border border-solid border-lightGray rounded-[4px] hover:border-hsl-gray focus:outline-none focus:border-blue-500 h-8';
  if (searchTerm !== '') {
    inputClass += ' bg-gray-100'; 
  }

  return (
    <div className="flex items-center w-5/6 lg:w-96 mr-5">
      <div className="relative w-full">
        <input
          type="text"
          value={searchTerm}
          onChange={handleChange}
          className={inputClass}
          placeholder="Search"
        />
        <span className="absolute inset-y-0 left-0 pl-3 pr-3 flex items-center text-gray-400 border-r border-solid border-lightGray">
          <img src={searchIcon} alt="Search Icon" className="h-5 w-5" />
        </span>
      </div>
    </div>
  );
}

export default SearchInput;