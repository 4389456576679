import { useLocation, useNavigate } from 'react-router-dom';
import successIcon from '../../assets/success.svg';
import InteractionDataContext from '../../context/InteractionDataContext';
import { useContext } from 'react';
import Expand from '../../components/Expand';
import { useIframeContext } from '../../context/IframeContext';

function DraftSuccess() {
    const { fetchInteractions } = useContext(InteractionDataContext);
    const { isIframe } = useIframeContext();
    const navigate = useNavigate();
    const location = useLocation();

    const { initiativeId } = location.state;

    const navigateBack = () => {
        fetchInteractions();
        navigate(`/commerce-initiatives/detail/${initiativeId}`);
    };

    console.log('initiative id:', initiativeId);

    return (
        <div className="p-4 m-5">
            <div className="mb-8 pb-2 border border-solid border-color-tableGray rounded-lg w-full">
                <div className="flex items-center my-3 pb-5 border-b border-solid border-color-tableGray">
                    <h2 className="font-semibold text-xl ml-7 w-1/3 text-color-cai-gray">Upload Interactions</h2>
                    {isIframe && 
                        <div className="flex ml-auto mr-4 mb-4">
                            <Expand/>
                        </div>
                    }
                </div>
                <div className='flex items-start justify-center mt-10'>
                    <img src={successIcon} alt='success icon' className='ml-10 my-3 mr-2'></img>
                    <h3 className='my-5 w-full font-semibold text-color-cai-gray'>Draft Saved Successfully</h3>
                </div>
                <div className='flex items-start justify-start ml-10 mb-10'>
                    <p className='text-color-cai-red text-sm'>Saved drafts can be viewed in the upload history page.</p>
                </div>
                <div className="flex items-center border-t border-solid border-color-tableGray">
                    <button
                        id="save-target"
                        onClick={navigateBack}
                        className='ml-7 mt-5 mb-3 w-36 h-8 text-center text-white bg-color-cai-dashboard border-color-cai-dashboard rounded-md focus:outline-none'
                    >
                        Continue
                    </button>
                </div>
            </div>
        </div>
    );
}

export default DraftSuccess;