import { useState } from 'react';
import editIcon from '/src/assets/edit.svg';
import deleteIcon from '/src/assets/delete.svg';
import { useNavigate } from 'react-router-dom';
import { TargetsData } from '../../services/types';
import { generatePageNumbers } from '../../utils/helpers.ts';

interface TargetsTableProps {
    data: TargetsData[];
    ado: string;
    year: string;
    searchTerm: string;
    sortType: string;
    // fetchData: () => void;
}

function TargetsTable({ data, ado, year, searchTerm, sortType }: TargetsTableProps) {
    // const { fetchTargets } = useContext(TargetsDataContext);
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10;


    const handleEditClick = (id: number) => {
        const filterState = {
            ado,
            year,
            searchTerm,
            sortType,
        };
        localStorage.setItem('targetFilters', JSON.stringify(filterState));

        const target = data.find(item => item.id === id);
        console.log('target:', target);
        navigate(`/commerce-targets/edit/${id}`, {state: {target}});
    };

    const handleDeleteClick = async (id: number) => {
        const target = data.find(item => item.id === id);
        const partnerId = target?.partner_id;

        navigate(`/commerce-targets/delete/${id}`, { state: {id, partnerId } });
    };

    const handlePageClick = (page: number) => {
        setCurrentPage(page);
    };

    // Pagination Logic
    const totalPages = Math.ceil(data.length / rowsPerPage);
    const pageNumbersToShow = generatePageNumbers(totalPages, currentPage);

    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = Math.min(startIndex + rowsPerPage, data.length);
    const paginatedData = data.slice(startIndex, endIndex);

    return (
        <div>
            <div className="overflow-x-auto border border-solid border-color-tableGray rounded-lg">
                <table className="min-w-full">
                    <thead className="text-color-cai-main bg-color-cai-lightGray rounded-t-lg">
                        <tr>
                            <th className="border-b border-color-tableGray px-4 py-2 text-left text-xs font-bold min-w-72">ADO</th>
                            <th className="border-b border-color-tableGray px-4 py-2 text-left text-xs font-bold w-28">Year</th>
                            <th className="border-b border-color-tableGray px-4 py-2 text-end text-xs font-bold w-28">BRE</th>
                            <th className="border-b border-color-tableGray px-4 py-2 text-end text-xs text-nowrap font-bold">Startup Support</th>
                            <th className="border-b border-color-tableGray px-4 py-2 text-end text-xs font-bold">Training</th>
                            <th className="border-b border-color-tableGray px-4 py-2 text-end text-xs font-bold">Recruitment</th>
                            <th className="border-b border-color-tableGray px-4 py-2 text-end text-xs text-nowrap font-bold">Export Assistance</th>
                            <th className="border-b border-color-tableGray px-4 py-2 text-end text-xs text-nowrap font-bold">Marketing Plan</th>
                            <th className="border-b border-color-tableGray px-4 py-2 text-center text-xs font-bold">Manage</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedData.map((row) => (
                            <tr key={row.id} className="border-b border-solid border-color-tableGray last:border-none h-10">
                                <td className="px-4 py-2 text-color-cai-gray text-sm text-left min-w-72">{row.partner_name}</td>
                                <td className="px-4 py-2 text-color-cai-gray text-sm text-left">{row.year}</td>
                                <td className="px-4 py-2 text-color-cai-gray text-sm text-end">{row.bre}</td>
                                <td className="px-4 py-2 text-color-cai-gray text-sm text-end">{row.startup}</td>
                                <td className="px-4 py-2 text-color-cai-gray text-sm text-end">{row.training}</td>
                                <td className="px-4 py-2 text-color-cai-gray text-sm text-end">{row.recruitment}</td>
                                <td className="px-4 py-2 text-color-cai-gray text-sm text-end">{row.export}</td>
                                <td className="px-4 py-2 text-color-cai-gray text-sm text-end">{row.marketing_plan_required ? 'required' : 'optional'}</td>
                                <td className="flex py-2 text-color-cai-gray text-sm text-end items-center justify-center">
                                    <button onClick={(e) => { e.stopPropagation(); handleEditClick(row.id) }} className="mr-3 flex items-center">
                                        <img className="border border-solid border-tableGray h-6 w-6 p-1 rounded-md" src={editIcon} alt="Edit" />
                                    </button>
                                    <button onClick={(e) => { e.stopPropagation(); handleDeleteClick(row.id) }}
                                        className="flex items-center">
                                        <img className="border border-solid border-tableGray h-6 w-6 p-1 rounded-md" src={deleteIcon} alt="Delete" />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {pageNumbersToShow.length > 1 && 
            <div className="flex justify-center mt-3">
                <div className="border border-solid border-tableGray rounded-md flex">
                    <button
                        className="text-color-cai-gray text-sm border-r border-solid border-tableGray px-2 py-1"
                        onClick={() => handlePageClick(Math.max(currentPage - 1, 1))}
                        disabled={currentPage === 1}
                    >
                        « Previous
                    </button>
                    {pageNumbersToShow.map((pageNumber, index) => (
                        <button
                            key={index}
                            className={`text-color-cai-gray text-sm border-r border-solid border-tableGray px-3 py-1 ${pageNumber === currentPage ? 'bg-gray-300' : ''}`}
                            onClick={() => handlePageClick(typeof pageNumber === 'number' ? pageNumber : currentPage)}
                        >
                            {pageNumber}
                        </button>
                    ))}
                    <button
                        className="text-color-cai-gray text-sm px-2 py-1"
                        onClick={() => handlePageClick(Math.min(currentPage + 1, totalPages))}
                        disabled={currentPage === totalPages}
                    >
                        Next »
                    </button>
                </div>
            </div>
            }
            
        </div>
    );
}

export default TargetsTable;