import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import Expand from '../../components/Expand';
import { useContext, useState } from 'react';
import deleteSvg from '../../assets/delete-white.svg';
import { useIframeContext } from '../../context/IframeContext';
import AdoDataContext from '../../context/AdoDataContext';
import FormErrorModal from '../../components/FormErrorModal';


function DeleteInitiative() {
    const { isIframe } = useIframeContext();
    const { partnerData } = useContext(AdoDataContext);
    const [showConfirmation, setShowConfirmation] = useState(true);
    const [deleteStatus, setDeleteStatus] = useState('');
    const [showImage, setShowImage] = useState(false);
    const [showContinueButton, setShowContinueButton] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [formErrorMsg, setFormErrorMsg] = useState('');

    const navigate = useNavigate();
    const { id } = useParams();
    const location = useLocation();
    const { rowData } = location.state;
    const keyMetrics = rowData.total_outcomes > 0 || rowData.total_interactions > 0;

    // Find the partner data matching the selected ADO name
    const partner = partnerData.find(partner => partner.name.trim().toLowerCase() === rowData.partner_name.trim().toLowerCase());

    // Handler for navigating back to Initiatives page
    const navigateBack = () => {
        navigate(-1);
    };

    const handleContinue = () => {
        navigate('/commerce-initiatives');
    }

    const handleNext = async (id: number | string | undefined) => {
        console.log('key metrics present:', 'outcomes -', rowData.total_outcomes, 'interactions -', rowData.total_interactions);

        if (keyMetrics) {
            console.log('Cannot delete initiative that contains key metrics.')
            setShowConfirmation(false);
            setDeleteStatus('To delete this initiative, you must first move or remove any associated interactions and/or outcomes.');
            return;
        }

        if (!id) {
            console.error('No ID provided for deletion');
            return;
        }

        if (!partner) {
            setFormErrorMsg(`Partner ID for ${rowData.partner_name} not found.`);
            setShowModal(true);
            return;
        }

        const partnerId = partner.id;
        console.log('partnerid:', partnerId)

        try {
            const response = await fetch(`https://backend-cai-commerce.vercel.app/api/deleteinitiative?partnerId=${partnerId}&initiativeId=${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
    
            if (!response.ok) {
                const responseData = await response.json();
                throw new Error(responseData.error || 'Failed to delete initiative');
            }
            setDeleteStatus('Initiative deleted successfully');
            setShowImage(true);
            setShowConfirmation(false);
            setShowContinueButton(true);
            console.log(`Initiative with ID ${id} successfully deleted`);
        } catch (error) {
            // Log the caught error to Sentry
            Sentry.captureException(error);
            console.error('Error sending data to backend:', error);
        }
    };
    

    return (
        <div className="m-2 p-4">
            {/* Expand button */}
            {isIframe && 
                <div className='flex justify-end'>
                    <div className="flex ml-auto mb-4">
                        <Expand/>
                    </div>
                </div>
            }
            

            {/* Delete Initiative */}
            <div className="mb-8 border border-solid border-color-tableGray rounded-md">
                <div className='border-b border-solid border-color-tableGray mb-5'>
                    <h2 className="font-semibold mt-3 mb-3 ml-7">Delete Initiative</h2>
                </div>
                <div className='flex ml-4 sm:ml-8 h-20 items-center'>
                    {showImage && 
                    <img src={deleteSvg} alt="Deleted Successfully" className="p-2 mr-4 h-9 w-10 border border-red-500 bg-red-500 rounded-full" />}
                    <p className='font-semibold'>{showConfirmation ? 'Are you sure that you want to permanently delete this initiative?' : deleteStatus}</p>
                </div>
                <div className='border-t border-solid border-color-tableGray mt-5 mb-2'>
                    {!showImage && showConfirmation && (
                        <button
                            id="next"
                            onClick={() => handleNext(id)}
                            className="ml-7 mt-5 mb-3 w-36 h-9 text-center text-white bg-color-cai-dashboard border border-solid border-color-cai-dashboard rounded-md focus:outline-none focus:border-blue-400  hover:bg-blue-400 hover:border-blue-500"
                        > Next
                        </button>
                    )}
                    {!showImage && (
                        <button
                            id="cancel"
                            onClick={navigateBack}
                            className="ml-7 mt-5 mb-3 w-36 h-9 text-center text-color-cai-dashboard bg-white border border-solid border-color-cai-dashboard rounded-md focus:outline-none focus:border-red-500  hover:bg-red-400 hover:border-red-500 hover:text-white"
                        > Cancel
                        </button>
                    )}
                    {showContinueButton && (
                        <button
                            id="continue"
                            onClick={handleContinue}
                            className="ml-7 mt-5 mb-3 w-36 h-9 text-center text-white bg-color-cai-dashboard border border-solid border-color-cai-dashboard rounded-md focus:outline-none focus:border-blue-400  hover:bg-blue-400 hover:border-blue-500"
                        > Continue
                        </button>
                    )}
                </div>
                <FormErrorModal
                    isOpen={showModal}
                    onClose={() => setShowModal(false)}
                    message={formErrorMsg}
                />
            </div>
        </div>
    );
};

export default DeleteInitiative;