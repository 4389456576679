import { useState, useEffect } from 'react';
import Select from 'react-select';
import { InitiativeData } from '../../services/types';
import { useOrganizationContext } from '../../context/OrganizationContext';
import { sanitizeString } from '../../utils/helpers';
import { useCAIAdminContext } from '../../context/CAIAdminContext';

interface InitiativeSortProps {
    filterByInitiative: (initiative: string) => void;
    initiatives: InitiativeData[];
    currInitiative: string;
}

function InitiativeSort({ filterByInitiative, initiatives, currInitiative }: InitiativeSortProps) {
    const { partnerName } = useOrganizationContext();
    const { isCAIAdmin } = useCAIAdminContext();
    const ado = partnerName;

    const getInitiativeLabel = (initiativeId: string) => {
        const foundInitiative = initiatives.find(init => init.id === Number(initiativeId));
        return foundInitiative ? foundInitiative.name : '';
    };
    
    const currentInitiatives = isCAIAdmin
    ? initiatives // Show all initiatives if isCAIAdmin is true
    : initiatives.filter(initiative => sanitizeString(initiative.partner_name) === sanitizeString(ado));

    const [initiative, setInitiative] = useState<{ value: string; label: string } | null>(
        currInitiative ? { value: currInitiative, label: getInitiativeLabel(currInitiative) } : null
    );

    useEffect(() => {
        setInitiative(currInitiative ? { value: currInitiative, label: getInitiativeLabel(currInitiative) } : null);
    }, [currInitiative]);

    const handleSelectChange = (selectedOption: { value: string; label: string } | null) => {
        const selectedInitiative = selectedOption ? selectedOption.value : '';
        setInitiative(selectedOption);
        filterByInitiative(selectedInitiative);
    };


    return (
        <div className="mt-2 sm:mt-0">
            <Select
                options={currentInitiatives.map(init => ({ value: init.id.toString(), label: init.name }))}
                value={initiative}
                onChange={handleSelectChange}
                placeholder="Initiative"
                isClearable={true}
                styles={{
                    control: (base) => ({
                        ...base,
                        borderColor: '#E5E7EB',
                        boxShadow: 'none',
                        height: '2rem',
                        minHeight: '2rem',
                        width: '10rem',
                        borderRadius: '4px',
                        marginRight: '1rem',
                    }),
                    valueContainer: (base) => ({
                        ...base,
                        fontSize: '0.875rem',
                        padding: '0 0.5rem',
                        minHeight: '2rem',
                        height: '2rem',
                    }),
                    placeholder: (base) => ({
                        ...base,
                        color: '#3A3C41',
                        fontSize: '0.875rem',
                        fontWeight: 'bold',
                    }),
                    singleValue: (base) => ({
                        ...base,
                        color: '#3A3C41',
                        fontSize: '0.875rem',
                        fontWeight: 'bold'
                    }),
                    dropdownIndicator: (base) => ({
                        ...base,
                        padding: '0',
                    }),
                    clearIndicator: (base) => ({
                        ...base,
                        padding: '0',
                    }),
                    indicatorsContainer: (base) => ({
                        ...base,
                        padding: '0 0.25rem',
                    }),
                    option: (base, state) => ({
                        ...base,
                        fontSize: '0.875rem',
                        backgroundColor: state.isSelected ? '#626262' : state.isFocused ? '#CCCCCC' : 'white',
                        borderRadius: '4px',
                        borderBottom: '1px solid #E2E8F0',
                        color: state.isSelected ? 'white' : '#3A3C41',
                        '&:hover': {
                            backgroundColor: '#CCCCCC',
                        },
                    }),
                    menu: (base) => ({
                        ...base,
                        width: '10rem',
                        position: 'absolute',
                        left: '0',
                        top: '1.5rem',
                        zIndex: 9999,
                        boxShadow: '0 8px 16px 0 rgba(0, 0, 0, 0.15)',
                    }),
                    menuList: (base) => ({
                        ...base,
                        padding: '4px',
                        maxHeight: '200px', // Adjust height as needed
                        overflowY: 'auto',
                        '::-webkit-scrollbar': {
                            width: '8px',
                            scrollBehavior: 'smooth'
                        },
                        '::-webkit-scrollbar-track': {
                            background: '#ffffff',
                            borderRadius: '4px',
                            scrollBehavior: 'smooth'
                        },
                        '::-webkit-scrollbar-thumb': {
                            background: '#E8E8E8',
                            borderRadius: '4px',
                        },
                    }),
                }}
            />
        </div>
    );
}

export default InitiativeSort;