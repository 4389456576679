import { useState } from 'react';
import Select from 'react-select';
// import uploadSvg from '../../assets/upload.svg';
// import historySvg from '../../assets/view-history.svg';
import { useNavigate } from 'react-router-dom';

interface UploadInteractionsBtnProps {
    id?: string | undefined;
    initiative: string;
    initiativeData?: any
}

function UploadInteractionsBtn({ id, initiative, initiativeData }: UploadInteractionsBtnProps) {
    const [upload, setUpload] = useState('');
    const navigate = useNavigate();

    const handleSelectChange = (selectedOption: { value: string; label: string } | null) => {
        if (selectedOption) {
            const selectedType = selectedOption.value;
            console.log('selected upload type:', selectedType);

            if (selectedType === "new") {
                console.log('initiative and id:', initiative, id);
                navigate('/upload-interactions', { state: { initiativeId: id, initiative, initiativeData } }); // Navigate to move page
            } else if (selectedType === "history") {
                navigate('/upload-history', { state: { initiativeId: id }}); // Navigate to delete page
            }
            setUpload(selectedType);
        }
    };

    const options = [
        { value: 'new', label: 'New Upload' },
        { value: 'history', label: 'View Upload History' },
    ];

    const customStyles = {
        control: (base: any) => ({
            ...base,
            background: '#626262',
            borderColor: 'lightGray',
            boxShadow: 'none',
            height: '2rem',
            minHeight: '2rem',
            width: '7rem',
            borderRadius: '4px',
            marginRight: '1rem',
            cursor: 'pointer'
        }),
        valueContainer: (base: any) => ({
            ...base,
            padding: '0 0.5rem',
            minHeight: '2rem',
            height: '2rem',
        }),
        placeholder: (base: any) => ({
            ...base,
            color: '#FFFFFF',
            fontSize: '0.875rem',
            fontWeight: 'bold',
        }),
        singleValue: (base: any) => ({
            ...base,
            color: '#3A3C41',
            fontSize: '0.875rem',
        }),
        dropdownIndicator: (base: any) => ({
            ...base,
            padding: '0',
            color: '#F0F0F0'
        }),
        indicatorsContainer: (base: any) => ({
            ...base,
            padding: '0 0.25rem',
        }),
        option: (base: any, state: any) => ({
            ...base,
            fontSize: '0.875rem',
            backgroundColor: state.isSelected ? '#626262' : state.isFocused ? '#CCCCCC' : 'white',
            borderRadius: '4px',
            borderBottom: '1px solid #E2E8F0',
            color: state.isSelected ? 'white' : '#3A3C41',
            '&:hover': {
                backgroundColor: '#CCCCCC',
            },
        }),
        menu: (base: any) => ({
            ...base,
            width: '7rem',
            position: 'absolute',
            left: '0',
            top: '1.5rem',
            zIndex: 9999,
            boxShadow: '0 8px 16px 0 rgba(0, 0, 0, 0.15)',
        }),
        menuList: (base: any) => ({
            ...base,
            padding: '4px',
            '::-webkit-scrollbar': {
                width: '0.5rem',
            },
            '::-webkit-scrollbar-track': {
                borderRadius: '0.5rem',
            },
            '::-webkit-scrollbar-thumb': {
                background: '#E8E8E8',
                borderRadius: '0.5rem',
                borderWidth: '4px',
                borderColor: 'transparent',
                backgroundClip: 'padding-box',
                height: '3rem',
                minHeight: '3rem',
            },
        }),
    }

    return (
        <div className='mt-2 sm:mt-0'>
            <Select
                options={options}
                value={options.find(option => option.value === upload)}
                onChange={handleSelectChange}
                placeholder="Upload"
                isSearchable={false}
                styles={customStyles}
            /> 
        </div>
    );
}

export default UploadInteractionsBtn;