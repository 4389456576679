import { useEffect, useRef, useState } from 'react';
import downloadSvg from '../../assets/download-file-icon.svg';
import { InitiativeData, InteractionData, OutcomeData } from "../../services/types";
import { addCommas, getCurrentDate } from "../../utils/helpers";
import { CSVLink } from 'react-csv';
import { useAdminContext } from '../../context/AdminContext';

interface DownloadButtonProps {
    outcomeData: OutcomeData[];
    initiativeData: InitiativeData[];
    interactionData: InteractionData[];
}

interface SummaryData {
    'County': string;
    'Associate Development Organization': string | undefined;
    'New Jobs': number;
    'Jobs Retained': number;
    'Private Investment': number;
    'Public Investment': number;
    'Startups': number;
}

function DownloadButton({ outcomeData, initiativeData, interactionData }: DownloadButtonProps) {
    const { isAdmin } = useAdminContext();
    const [openDropdown, setOpenDropdown] = useState<boolean>(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const currentDate = getCurrentDate();

    const handleDropdownToggle = () => {
        setOpenDropdown(prevState => !prevState);
    }

    // Close dropdown when clicking outside of it
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setOpenDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };

        
    }, []);


    ////////////
    // Relevant summary data below:
    ////////////
    const summaryData: SummaryData[] = outcomeData.map((row) => { 
        const initiative = initiativeData.find(init => init.id.toString() === row.initiative_id);
        // const publicInvest = `$${addCommas(Number(row.public_investment_dollars).toFixed(2))}`;
        // const privateInvest = `$${addCommas(Number(row.private_investment_dollars).toFixed(2))}`;

        return {
            'County': 'placeholder',
            'Associate Development Organization': initiative?.partner_id,
            'New Jobs': Number(row.jobs_created),
            'Jobs Retained': Number(row.jobs_retained),
            'Private Investment': Number(row.public_investment_dollars),
            'Public Investment': Number(row.private_investment_dollars),
            'Startups': Number(row.startups_supported),
        };
    });

    // Aggregating summaryData based on Associate Development Organization
    const aggregatedDataMap = summaryData.reduce((map, curr) => {
        const adoName = curr['Associate Development Organization'];
    
        if (adoName !== undefined) {
            if (map.has(adoName)) {
                const item = map.get(adoName)!;
                item['New Jobs'] += curr['New Jobs'];
                item['Jobs Retained'] += curr['Jobs Retained'];
                item['Private Investment'] += curr['Private Investment'];
                item['Public Investment'] += curr['Public Investment'];
                item['Startups'] += curr['Startups'];
            } else {
                map.set(adoName, { ...curr });
            }
        }
    
        return map;
    }, new Map<string, SummaryData>());

    // Include all ADOs in aggregatedData, even with zero values
    initiativeData.forEach(initiative => {
        const adoName = initiative.partner_id;
        if (!aggregatedDataMap.has(adoName)) {
            aggregatedDataMap.set(adoName, {
                'County': 'placeholder',
                'Associate Development Organization': adoName,
                'New Jobs': 0,
                'Jobs Retained': 0,
                'Private Investment': 0,
                'Public Investment': 0,
                'Startups': 0,
            });
        }
    });
    
    const aggregatedData = Array.from(aggregatedDataMap.values()).map(row => ({
        ...row,
        'Private Investment': `$${addCommas(Number(row['Private Investment'].toFixed(2)))}`,
        'Public Investment': `$${addCommas(Number(row['Public Investment'].toFixed(2)))}`
    }));

        // Calculate grand totals for all metrics
    const grandTotal: SummaryData = Array.from(aggregatedDataMap.values()).reduce((total, item) => {
        total['New Jobs'] += item['New Jobs'];
        total['Jobs Retained'] += item['Jobs Retained'];
        total['Private Investment'] += item['Private Investment'];
        total['Public Investment'] += item['Public Investment'];
        total['Startups'] += item['Startups'];
        return total;
    }, {
        'County': '',
        'Associate Development Organization': 'Grand Total',
        'New Jobs': 0,
        'Jobs Retained': 0,
        'Private Investment': 0,
        'Public Investment': 0,
        'Startups': 0,
    });

    // Format grand totals
    const formattedGrandTotal: SummaryData = {
        ...grandTotal,
        // @ts-ignore
        'Private Investment': `$${addCommas(Number(grandTotal['Private Investment'].toFixed(2)))}`,
        // @ts-ignore
        'Public Investment': `$${addCommas(Number(grandTotal['Public Investment'].toFixed(2)))}`
    };

    // Add grand total
    // @ts-ignore
    aggregatedData.push(formattedGrandTotal);

    // Prepare Summary CSV data
    const summaryHeaders = [
        { label: 'County', key: 'County' },
        { label: 'Associate Development Organization', key: 'Associate Development Organization' },
        { label: 'New Jobs', key: 'New Jobs' },
        { label: 'Jobs Retained', key: 'Jobs Retained' },
        { label: 'Private Investment', key: 'Private Investment' },
        { label: 'Public Investment', key: 'Public Investment' },
        { label: 'Startups', key: 'Startups' },
    ];

    const csvSummaryData = aggregatedData.map(item => ({
        County: item.County,
        'Associate Development Organization': item['Associate Development Organization'],
        'New Jobs': item['New Jobs'],
        'Jobs Retained': item['Jobs Retained'],
        'Private Investment': item['Private Investment'],
        'Public Investment': item['Public Investment'],
        'Startups': item['Startups'],
    }));

    ////////////
    // Relevant initiative detail data below:
    ////////////

    const mergeInitiativeData = () => {
        const mergedData = initiativeData.map(initiative => {
            const interactions = interactionData.filter(interaction => interaction.initiative_id === initiative.id);
            const outcomes = outcomeData.filter(outcome => outcome.initiative_id === initiative.id.toString());

            return interactions.map(interaction => {
                const relatedOutcome = outcomes.find(outcome => outcome.initiative_id === interaction.initiative_id.toString());

                return {
                    ...initiative,
                    ...interaction,
                    ...relatedOutcome
                };
            });
        }).flat();

        return mergedData;
    };

    const detailData = mergeInitiativeData();

    const detailHeaders = [
        { label: 'Initiative ID', key: 'id' },
        { label: 'Initiative Name', key: 'name' },
        { label: 'Year', key: 'year' },
        { label: 'Status', key: 'status' },
        { label: 'Partner ID', key: 'partner_id' },
        { label: 'Description', key: 'description' },
        { label: 'External ID', key: 'external_id' },
        { label: 'Priorities of Government Initiative', key: 'pog_initiative' },
        { label: 'Initiative Type', key: 'initiative_type' },
        { label: 'Priorities of Government Business UBI', key: 'pog_business_ubi' },
        { label: 'Priorities of Government Business Name', key: 'pog_business_name' },
        { label: 'Priorities of Government In Business 12 Mo', key: 'pog_in_business_12_mo' },
        { label: 'Interaction Date', key: 'date' },
        { label: 'Interaction Note', key: 'note' },
        { label: 'Interaction Email', key: 'email' },
        { label: 'Interaction Phone', key: 'phone' },
        { label: 'Interaction Latitude', key: 'latitude' },
        { label: 'Interaction Longitude', key: 'longitude' },
        { label: 'Interaction Assistance', key: 'assistance' },
        { label: 'Interaction First Name', key: 'first_name' },
        { label: 'Interaction Last Name', key: 'last_name' },
        { label: 'Interaction Staff Name', key: 'staff_name' },
        { label: 'Interaction Business UBI', key: 'business_ubi' },
        { label: 'Interaction Contact Type', key: 'contact_type' },
        { label: 'Interaction Gross Revenue', key: 'gross_revenue' },
        { label: 'Interaction People Employed', key: 'people_employed' },
        { label: 'Interaction Type', key: 'interaction_type' },
        { label: 'Interaction Business Legal Name', key: 'business_legal_name' },
        { label: 'Interaction Business Trade Name', key: 'business_trade_name' },
        { label: 'Interaction Business Classification', key: 'business_classification' },
        { label: 'Outcome Year', key: 'year' },
        { label: 'Outcome Quarter', key: 'quarter' },
        { label: 'Outcome Narrative', key: 'narrative' },
        { label: 'Outcome Jobs Created', key: 'jobs_created' },
        { label: 'Outcome Jobs Retained', key: 'jobs_retained' },
        { label: 'Outcome Startups Supported', key: 'startups_supported' },
        { label: 'Outcome Public Investment Dollars', key: 'public_investment_dollars' },
        { label: 'Outcome Private Investment Dollars', key: 'private_investment_dollars' },
        { label: 'Outcome Products Exported Dollars Year', key: 'products_exported_dollars_year' },
        { label: 'Outcome Direct Foreign Investment Dollars', key: 'direct_foreign_investment_dollars' },
        { label: 'Outcome Jobs Created Retained with Above Avg Wages', key: 'jobs_created_retained_with_above_avg_wages' }
    ];

    return ( 
        <span className="flex relative">
            <button
                id="new-target"
                onClick={handleDropdownToggle}
                className="text-center w-36 mx-4 px-4 text-white font-bold text-sm bg-color-cai-dashboard border border-solid border-color-cai-dashboard rounded-md focus:outline-none focus:border-blue-500 h-8 appearance-none"
            > 
                <p className="ml-1">Download</p>
                <img src={downloadSvg} alt="download icon" className="absolute top-[9px]" />
            </button>
            {openDropdown && (
                <div ref={dropdownRef} className="absolute mt-9 w-36 left-4 bg-white rounded-md border border-color-cai-lightGray shadow-lg z-10">
                    <div className="py-1">
                        {isAdmin && 
                            <CSVLink data={csvSummaryData} headers={summaryHeaders} filename={`summary_export_${currentDate}.csv`}>
                                <button onClick={() => { setOpenDropdown(false); }} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left">
                                    Summary
                                </button>
                            </CSVLink>
                        }
                        
                        <CSVLink data={detailData} headers={detailHeaders} filename={`detail_export_${currentDate}.csv`}>
                            <button onClick={() => { setOpenDropdown(false); }} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left">
                                Initiative Details
                            </button>
                        </CSVLink>
                    </div>
                </div>
            )}
        </span>
    );
}

export default DownloadButton;