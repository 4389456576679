import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import leftArrow from '../../assets/left-arrow.svg';
import editIcon from '../../assets/edit.svg';
import deleteIcon from '../../assets/delete.svg';
import OutcomesTable from "../../components/Outcomes/OutcomesTable";
import InitiativeInteractionTable from "../../components/Initiatives/InitiativeInteractionTable";
import BreSort from "../../components/BreSort";
import FiscalYear from "../../components/FiscalYear";
import InteractionType from "../../components/Interactions/InteractionType";
import AssistanceType from "../../components/Interactions/AssistanceType";
import BulkActionBtn from "../../components/Interactions/BulkActionBtn";
import UploadInteractionsBtn from "../../components/Interactions/UploadInteractionsBtn";
import AddInteraction from "../../components/Interactions/AddInteractionButton";
import NewOutcomeButton from "../../components/Outcomes/NewOutcomeButton";
import Expand from "../../components/Expand";
import { InitiativeData, InteractionData, OutcomeData } from "../../services/types";
import { calculateAvailableYears } from "../../utils/helpers";
import { useAdminContext } from "../../context/AdminContext";
import { useIframeContext } from "../../context/IframeContext";
import InteractionDataContext from "../../context/InteractionDataContext";
import useDeepCompareEffect from "../../hooks/useDeepCompareEffect";
import useFilteredData from "../../hooks/useFilteredData";
import InitiativeDataContext from "../../context/InitiativeDataContext";
import { useCAIAdminContext } from "../../context/CAIAdminContext";


function InitiativeDetail() {
    // Context and hook usage
    const { isCAIAdmin } = useCAIAdminContext();
    const { isAdmin } = useAdminContext();
    const { isIframe } = useIframeContext();
    const { initiatives } = useContext(InitiativeDataContext);
    const { interactions } = useContext(InteractionDataContext);

    // State variables
    const [initiative, setInitiative] = useState<string>('');
    const [initiativeData, setInitiativeData] = useState<InitiativeData>();
    const [data, setData] = useState<InteractionData[]>([]);
    const [outcomeData, setOutcomeData] = useState<OutcomeData[]>([])
    const [selectedRows, setSelectedRows] = useState<number[]>([]);
    const [year, setYear] = useState<string>('');
    const [sortType, setSortType] = useState<string>('');
    const [interactionType, setInteractionType] = useState<string>('');
    const [assistanceType, setAssistanceType] = useState<string>('');

    // Calculate available years for the fiscal year filter
    const availableYears = calculateAvailableYears();

    // Filtered interactions based on various criteria
    const { filteredInteractions } = useFilteredData(
        undefined, undefined, undefined, interactions,
        undefined, sortType, year, undefined, interactionType,
        undefined, assistanceType
    );

    // Route and state management
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    // Fetch and set data when filteredInteractions or id changes
    useDeepCompareEffect(() => {
        setData(filteredInteractions);
    }, [interactions, filteredInteractions, id]);

    // Set the initiative state based on rowData.Initiative when the component mounts
    useEffect(() => {

        const currentInitiative = initiatives.find(initiative => initiative.id === Number(id));

        if (currentInitiative) {
            setInitiativeData(currentInitiative);
            setInitiative(currentInitiative.name)
            setOutcomeData(currentInitiative.outcomes);
        }

        // if (initiativeData) {
        //     setInitiative(initiativeData.name);
        // }
    }, [initiatives, id]);

    // Handle sorting, filtering, and navigation
    const handleSort = (type: string) => setSortType(type);
    const filterByYear = (year: string) => setYear(year);
    const filterByInteractionType = (interactionType: string) => setInteractionType(interactionType);
    const filterByAssistanceType = (assistanceType: string) => setAssistanceType(assistanceType);

    // Handler for navigating back to Initiatives page
    const navigateBack = () => navigate('/commerce-initiatives');

    // Handler for edit click
    const handleEditClick = (id: string | undefined) => navigate(`/commerce-initiatives/edit/${id}`, { state: { initiativeData }});

    // Handler for delete click
    const handleDeleteClick = async () => {
        try {
            navigate(`/commerce-initiatives/delete/${initiativeData?.id}`, { state: { rowData: initiativeData } });
        } catch (error) {
            console.error('Error handling delete click:', error);
        }
    };

    // Handler for bulk actions
    const handleBulkAction = () => {
        console.log("Performing bulk action on selected rows:", selectedRows);
    };

    return (
        <div className="p-4 m-2">
            <div className="flex justify-between">
                {/* Back to Initiatives button */}
                <button onClick={navigateBack} className="text-sm flex items-center mb-4 font-semibold">
                    <img src={leftArrow} alt='left arrow' className='h-3 w-4 mr-3'></img>
                    Back to Initiatives
                </button>
                {isIframe && 
                    <div className="flex ml-auto mb-4">
                        <Expand/>
                    </div>
                }
            </div>
            <div className="mb-8 pb-4 border border-solid border-color-tableGray rounded-md w-full">
                <div className='relative flex items-center border-b border-solid border-color-tableGray mb-4'>
                    <h2 className="text-sm font-semibold mt-3 mb-3 ml-7">{initiativeData?.name}</h2>
                    {isAdmin || isCAIAdmin || (!isAdmin && !initiativeData?.admin_managed) ? (
                    <div className="absolute right-5 flex items-center">
                        <button onClick={() => handleEditClick(id)} className="mr-1 flex items-center">
                            <img className="border border-solid border-tableGray h-6 w-6 p-1 rounded-md" src={editIcon} alt="Edit" />
                        </button>
                        <button onClick={handleDeleteClick} className="flex items-center">
                            <img className="border border-solid border-tableGray h-6 w-6 p-1 rounded-md" src={deleteIcon} alt="Delete" />
                        </button>
                    </div>) : null
                    }
                </div>
                <div className='flex flex-wrap sm:flex-nowrap ml-3 w-full'>
                    {/* Initiative description */}
                    <div className="flex flex-col items-start mx-4 my-2">
                        <div className="text-sm font-semibold">
                            Description:
                        </div>
                        <div className="text-sm sm:text-wrap max-w-2xl mr-2">
                            {initiativeData?.description}
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap sm:flex-nowrap ml-3">
                    {/* Initiative Type */}
                    <div className="flex flex-wrap items-start sm:items-center mx-4 my-2 w-1/2">
                        <div className="text-sm font-semibold">
                            Initiative Type:
                        </div>
                        <div className="text-sm flex items-center w-full">
                            {initiativeData?.initiative_type?.name}
                        </div>
                    </div>
                    {/* Initiative Status */}
                    <div className="flex flex-wrap items-start sm:items-center mx-4 my-2 w-1/2">
                        <div className="text-sm font-semibold">
                            Status:
                        </div>
                        <div className="text-sm flex items-center w-full">
                            {initiativeData && initiativeData.status.charAt(0).toUpperCase() + initiativeData.status.slice(1).toLowerCase()}
                        </div>
                    </div>
                </div>

                <div className="flex flex-wrap sm:flex-nowrap ml-3">
                    {/* Total Interactions */}
                    <div className="flex flex-wrap items-start sm:items-center mx-4 my-2 w-1/2">
                        <div className="text-sm font-semibold">
                            Total Interactions:
                        </div>
                        <div className="text-sm flex items-center w-full">
                            {initiativeData?.total_interactions}
                        </div>
                    </div>
                    {/* Total Interactions */}
                    <div className="flex flex-wrap items-start sm:items-center mx-4 my-2 w-1/2">
                        <div className="text-sm font-semibold">
                            Year:
                        </div>
                        <div className="text-sm flex items-center w-full">
                            {initiativeData?.year}
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap sm:flex-nowrap ml-3">
                    {/* Industry */}
                    <div className="flex flex-wrap items-start sm:items-center mx-4 my-2 w-1/2">
                        <div className="text-sm font-semibold">
                            Industry:
                        </div>
                        <div className="text-sm flex items-center w-full">
                            {initiativeData?.industry_detail?.industry?.name || 'All Industries'}
                        </div>
                    </div>
                    {/* Sub-Industry */}
                    {initiativeData?.industry_detail?.industry?.name && 
                    <div className="flex flex-wrap items-start sm:items-center mx-4 my-2 w-1/2">
                        <div className="text-sm font-semibold">
                        Sub-Industry:
                        </div>
                        <div className="text-sm flex items-center w-full">
                        {initiativeData?.industry_detail?.sub_industry?.name || 'N/A'}
                        </div>
                    </div>   
                    }
                </div>
                <div className="flex flex-wrap sm:flex-nowrap ml-3">
                    {/* Service Area */}
                    <div className="flex flex-wrap items-start sm:items-center mx-4 my-2 w-1/2">
                        <div className="text-sm font-semibold">
                            Service Area:
                        </div>
                        <div className="text-sm flex items-center w-full">
                            {initiativeData?.all_area ? 'Full ADO Area' : 'Selected Area within ADO'}
                        </div>
                    </div>
                    {/* Area within ADO */}
                    {!initiativeData?.all_area &&
                        <div className="flex flex-wrap items-start sm:items-center mx-4 my-2 w-1/2">
                            <div className="text-sm font-semibold">
                                Area within ADO:
                            </div>
                            <div className="text-sm flex items-center w-full">
                                {!initiativeData?.all_area && initiativeData?.locations && initiativeData?.locations.length > 0 && (
                                    initiativeData?.locations.map((location: any) => location.location).join(', ')
                                )}
                            </div>
                        </div>
                    }
                </div>
                
                {/* Business Name and UBI */}
                <div className="flex flex-wrap sm:flex-nowrap ml-3">
                    {/* Priorities of Government Initiative Status */}
                    <div className="flex flex-wrap items-start sm:items-center mx-4 my-2 w-1/2">
                        <div className="text-sm font-semibold">
                            Priorities of Government Initiative:
                        </div>
                        <div className="text-sm flex items-center w-full">
                            {/* This may change to some other field */}
                            {initiativeData?.pog_initiative ? 'Yes' : 'No'}
                        </div>
                    </div>
                    {/* Business Name */}
                    {initiativeData?.pog_initiative && <div className="flex flex-wrap items-start sm:items-center mx-4 my-2 w-1/2">
                        <div className="text-sm font-semibold">
                            Business Name:
                        </div>
                        <div className="text-sm flex items-center w-full">
                            {initiativeData?.pog_business_name}
                        </div>
                    </div>}
                </div>
                {/* Operation last 12 months */}
                {initiativeData?.pog_initiative && <div className="flex flex-wrap sm:flex-nowrap ml-3">
                    <div className="flex flex-wrap items-start sm:items-center mx-4 my-2 w-1/2">
                        <div className="text-sm font-semibold">
                            In operation for last 12 months:
                        </div>
                        <div className="text-sm flex items-center w-full">
                            {initiativeData?.pog_in_business_12_mo ? 'Yes' : 'No'}
                        </div>
                    </div>
                    {/* UBI */}
                    <div className="flex flex-wrap items-start sm:items-center mx-4 my-2 w-1/2">
                        <div className="text-sm font-semibold">
                            Business UBI:
                        </div>
                        <div className="text-sm flex items-center w-full">
                            {/* This may change to some other field */}
                            {initiativeData?.pog_business_ubi}
                        </div>
                    </div>
                </div>}
            </div>

            <div className="mb-8 pb-10 border border-solid border-color-tableGray rounded-md w-full">
                <div className="flex items-center my-3">
                    <h2 className="text-sm font-semibold ml-7 w-full">Outcomes</h2>
                    <NewOutcomeButton id={id}/>
                </div>
                
                <OutcomesTable outcomeData={outcomeData} partnerId={initiativeData?.partner_id} initiativeId={initiativeData?.id}/>
                
            </div>
            {/* Only show Interactions for ADO view */}
            {!isAdmin && (
            <div className="mb-8 pb-10 border border-solid border-color-tableGray rounded-md w-full">
                <div className="flex flex-wrap items-center my-3 px-2">
                    <h2 className="text-sm font-semibold ml-7 w-56">Interactions</h2>
                    <div className="ml-auto flex flex-wrap items-center">
                        <BreSort handleSort={handleSort} currSortType={sortType} column="Business Name" dateType="(Descending: Newest First)"/>
                        <FiscalYear filterByYear={filterByYear} availableYears={availableYears} currYear={year}/>
                        <InteractionType filterByInteractionType={filterByInteractionType} currInteractionType={interactionType}/>
                        <AssistanceType filterByAssistanceType={filterByAssistanceType} currAssistanceType={assistanceType}/>
                        <BulkActionBtn disabled={selectedRows.length === 0} handleBulkAction={handleBulkAction} selectedRows={selectedRows}/>
                        <UploadInteractionsBtn id={id} initiative={initiative} initiativeData={initiativeData}/>
                        <AddInteraction id={id}/>
                    </div>
                </div>
                
                <InitiativeInteractionTable data={data} initiativeId={id} handleSelectRows={setSelectedRows}/>
            </div>
            )}
        </div>
    );
}

export default InitiativeDetail;