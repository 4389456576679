import { useState } from 'react';
import { addCommas, generatePageNumbers } from '../../utils/helpers.ts';
import { InitiativeData, OutcomeData } from '../../services/types.js';
import useDeepCompareEffect from '../../hooks/useDeepCompareEffect.js';
import { useAdminContext } from '../../context/AdminContext.tsx';

interface SummaryOutcomesTableProps {
    initiativeData: InitiativeData[];
    outcomeData: OutcomeData[]; // Define props interface
}

// function SummaryOutcomesTable({ data }: SummaryOutcomesTableProps) {
function SummaryOutcomesTable({ initiativeData, outcomeData }: SummaryOutcomesTableProps) {
    const { isAdmin } = useAdminContext();
    const [data, setData] = useState<OutcomeData[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10;

    useDeepCompareEffect(() => {
        if (Array.isArray(outcomeData)) {
            setData(outcomeData);
        } else {
            console.error('outcomeData is not an array:', outcomeData);
        }
    }, [outcomeData]);

    const handlePageClick = (page: number) => {
        setCurrentPage(page);
    };

    // Pagination logic
    const totalPages = Math.ceil(data.length / rowsPerPage);
    const pageNumbersToShow = generatePageNumbers(totalPages, currentPage);

    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = Math.min(startIndex + rowsPerPage, data.length);
    const paginatedData = data.slice(startIndex, endIndex);

    return (
        <div>
            <div className="overflow-x-auto border-b border-solid border-color-tableGray">
                <table className="min-w-full">
                    <thead className="bg-color-cai-lightGray text-color-cai-main">
                        <tr>
                            <th className="border-b border-color-tableGray text-xs font-bold px-4 py-2 text-left">Initiative</th>
                            {isAdmin && <th className="border-b border-color-tableGray text-xs font-bold px-4 py-2 text-left">ADO</th>}
                            <th className="border-b border-color-tableGray text-xs font-bold px-4 py-2 text-end text-nowrap">Jobs created</th>
                            <th className="border-b border-color-tableGray text-xs font-bold px-4 py-2 text-end text-nowrap">Jobs retained</th>
                            <th className="border-b border-color-tableGray text-xs font-bold px-4 py-2 text-end text-nowrap">Jobs above avg wages</th>
                            <th className="border-b border-color-tableGray text-xs font-bold px-4 py-2 text-end text-nowrap">Startup businesses</th>
                            <th className="border-b border-color-tableGray text-xs font-bold px-4 py-2 text-end text-nowrap">{'Exports($/year)'}</th>
                            <th className="border-b border-color-tableGray text-xs font-bold px-4 py-2 text-end text-nowrap">Public investments</th>
                            <th className="border-b border-color-tableGray text-xs font-bold px-4 py-2 text-end text-nowrap">Private investments</th>
                            <th className="border-b border-color-tableGray text-xs font-bold px-4 py-2 text-end text-nowrap">Direct foreign investments</th>
                            <th className="border-b border-color-tableGray text-xs font-bold px-4 py-2 text-end"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedData.map((row) => {
                            const initiative = initiativeData.find(init => init.id === Number(row.initiative_id));
                            const productsExported = `$${addCommas(Number(row.products_exported_dollars_year).toFixed(2))}`;
                            const publicInvest = `$${addCommas(Number(row.public_investment_dollars).toFixed(2))}`;
                            const privateInvest = `$${addCommas(Number(row.private_investment_dollars).toFixed(2))}`;
                            const foreignInvest = `$${addCommas(Number(row.products_exported_dollars_year).toFixed(2))}`;

                            return (
                                <tr key={row.id} className="border-b border-solid border-color-tableGray last:border-none h-10">
                                    <td className="px-4 py-2 min-w-40 text-color-cai-gray text-sm text-left font-medium">{initiative?.name}</td>
                                    {isAdmin && <td className="px-4 py-2 min-w-40 text-color-cai-gray text-sm text-left font-medium">{initiative?.partner_name}</td>}
                                    <td className="px-4 py-2 min-w-24 text-color-cai-gray text-sm text-end font-medium">{row.jobs_created}</td>
                                    <td className="px-4 py-2 min-w-24 text-color-cai-gray text-sm text-end font-medium">{row.jobs_retained}</td>
                                    <td className="px-4 py-2 min-w-24 text-color-cai-gray text-sm text-end font-medium">{row.jobs_created_retained_with_above_avg_wages}</td>
                                    <td className="px-4 py-2 min-w-24 text-color-cai-gray text-sm text-end font-medium">{row.startups_supported}</td>
                                    <td className="px-4 py-2 min-w-24  text-color-cai-gray text-sm text-end font-medium">{row.products_exported_dollars_year ? productsExported : `$0`}</td>
                                    <td className="px-4 py-2 min-w-24  text-color-cai-gray text-sm text-end font-medium">{row.public_investment_dollars ? publicInvest : `$0`}</td>
                                    <td className="px-4 py-2 min-w-24  text-color-cai-gray text-sm text-end font-medium">{row.private_investment_dollars ? privateInvest : `$0`}</td>
                                    <td className="px-4 py-2 min-w-24  text-color-cai-gray text-sm text-end font-medium">{row.direct_foreign_investment_dollars ? foreignInvest : `$0`}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            {pageNumbersToShow.length > 1 && 
            <div className="flex justify-center mt-4">
                <div className="border border-solid border-tableGray rounded-md flex">
                    <button
                        className="text-color-cai-gray text-sm border-r border-solid border-tableGray px-2 py-1"
                        onClick={() => handlePageClick(Math.max(currentPage - 1, 1))}
                        disabled={currentPage === 1}
                    >
                        « Previous
                    </button>
                    {pageNumbersToShow.map((pageNumber, index) => (
                        <button
                            key={index}
                            className={`text-color-cai-gray text-sm border-r border-solid border-tableGray px-3 py-1 ${pageNumber === currentPage ? 'bg-gray-300' : ''}`}
                            onClick={() => handlePageClick(typeof pageNumber === 'number' ? pageNumber : currentPage)}
                        >
                            {pageNumber}
                        </button>
                    ))}
                    <button
                        className="text-color-cai-gray text-sm px-2 py-1"
                        onClick={() => handlePageClick(Math.min(currentPage + 1, totalPages))}
                        disabled={currentPage === totalPages}
                    >
                        Next »
                    </button>
                </div>
            </div>
            }
        </div>
    );
}

export default SummaryOutcomesTable;