import { useNavigate } from "react-router-dom";

interface AddInteractionButtonProps {
    id?: string;
}

function AddInteractionButton({ id }: AddInteractionButtonProps) {
    const navigate = useNavigate();
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (id) {
            navigate('/commerce-interactions/addinteraction', {state: {id}});
        } else navigate('/commerce-interactions/addinteraction');
        console.log('button clicked:', event);
    };

    return ( 
        <span className="flex items-center mt-2 sm:mt-0">
        <button
            id="add-interaction"
            onClick={handleClick}
            className="w-40 px-3 mr-4 text-white font-bold text-sm bg-color-cai-dashboard border border-solid border-color-cai-dashboard rounded-[4px] focus:outline-none focus:border-blue-500 h-8 appearance-none text-center"
        > + Add Interaction
        </button>
        </span>
    );
}

export default AddInteractionButton;